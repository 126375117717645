@import 'src/styles/theme/config';

.report-setting-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 15px;
  background-color: var(--background-color-dark);
  border: 1px solid var(--border-color);
  margin: 15px 25px;
  border-radius: 15px;
  position: relative;
  z-index: 101;

  &-group {
    display: flex;
    flex-wrap: wrap;

    &-section {
      display: flex;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;

    & > svg {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 3px;
    }
  }

  &-divider {
    width: 1px;
    background-color: var(--border-color);
    margin: 5px 15px;
    align-self: stretch;

    &:last-child {
      display: none;
    }
  }

  &-br {
    min-width: 100%;
    height: 1px;
    margin: 5px 0;
    background-color: var(--border-color);
  }

  &-period-selector {
    display: flex;
    align-items: center;

    .styled-select__menu-list {
      max-height: 360px;
    }
  }
}

.theme-dark {
  .report-setting-bar {
    background-color: var(--background-color-darker);
  }
}
