@import 'src/styles/theme/config';

.setup-modal-item {
  $item: &;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
  border-radius: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: 0.15s;
  margin-bottom: 15px;
  position: relative;

  &.vertical {
    flex-direction: column;
    padding: 30px;
    text-align: center;
    height: 100%;
    border-radius: 30px;

    #{$item}-icon {
      margin: 30px 0;
    }

    #{$item}-title {
      line-height: 1.3;
      margin-bottom: 5px;
    }
  }

  &.disabled {
    color: var(--text-color-light);
    background-color: #f5f5f5;
    cursor: default;

    &:hover {
      border: 1px solid var(--border-color);
    }

    #{$item}-icon {
      opacity: 0.6;
    }

    #{$item}-status {
      &.inactive {
        color: #f5f5f5;
      }
    }
  }

  &:hover {
    border: 1px solid var(--text-color);

    &:not(.disabled) #{$item}-status {
      &.inactive {
        color: var(--text-color);
        border-color: var(--text-color);
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-icon {
    display: flex;
    margin-right: 20px;

    img {
      max-width: 24px;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.7;
  }

  &-subtitle {
    font-size: 13px;
    color: var(--text-color-light);
  }

  &-action {
    display: flex;
    margin-right: 10px;
    margin-left: 30px;
  }

  &-status {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 34px;
    height: 34px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s;

    &.horizontal {
      width: 16px;
      height: 16px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &.success {
      background-color: var(--success-color);
      color: #fff;
    }

    &.inactive {
      border: 1px solid var(--border-color);
    }

    &.error {
      background-color: var(--danger-color);
      color: #fff;
    }
  }
}

.theme-dark {
  .setup-modal-item {
    background-color: var(--background-color-darker);

    &.disabled {
      background-color: var(--background-color-darker);
      opacity: 0.5;

      .setup-modal-item-status {
        &.inactive {
          color: var(--background-color-darker);
        }
      }
    }
  }
}
