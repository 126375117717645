@import 'src/styles/theme/config';

.segment-usage {
  position: relative;
  margin-top: 30px;

  &-content {
    position: absolute;
    display: flex;
    right: 15px;
  }
}
