@import 'src/styles/theme/config';

.partner-dashboard {
  &-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &-title {
    font-family: $serif-font;
    font-weight: 700;
    font-size: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}
