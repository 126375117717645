@import 'src/styles/theme/config';

.internal-widget {
  $w: &;
  padding: 0 15px 30px;

  &-25 {
    width: 25%;
  }

  &-33 {
    width: 33.3333%;
  }

  &-50 {
    width: 50%;
  }

  &-100 {
    width: 100%;
  }

  &-inner {
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: 20px;
    display: flex;
    height: 100%;

    &-item {
      flex: 1;
      padding: 25px;
      color: var(--text-color);
      display: flex;
      flex-direction: column;
      min-height: 100px;
    }
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.3px;
  }

  &-main-value {
    color: var(--text-color);
    font-size: 24px;
    line-height: 1.2;
    margin-top: 15px;

    &-muted {
      color: var(--text-color-lighter);
    }

    small {
      font-size: 17px;
    }
  }

  &-secondary-value {
    color: var(--text-color-lighter);
    font-size: 17px;
    line-height: 1;
    margin-top: 15px;
  }

  &-chart {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-progress-bar {
    border-radius: 10px;
    height: 20px;
    padding: 4px;
    background-color: var(--border-color);
    width: 100%;
    margin-top: 15px;

    &-inner {
      display: flex;
      height: 100%;

      &:hover {
        .widget-progress {
          opacity: 0.3;
        }
      }
    }
  }

  &-progress {
    min-width: 10px;
    height: 100%;
    transition: 0.2s;

    &:hover {
      opacity: 1 !important;
    }

    &:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    &-primary {
      background-color: var(--primary-color-500);
    }

    &-secondary {
      background-color: var(--secondary-color-500);
    }

    &-success {
      background-color: var(--success-color-500);
    }

    &-warning {
      background-color: var(--warning-color-500);
    }

    &-warning-dark {
      background-color: var(--warning-color-600);
    }

    &-warning-darker {
      background-color: var(--warning-color-700);
    }

    &-work-in-progress {
      background-color: #e38d0d;
    }

    &-work-in-progress-dark {
      background-color: #e38219;
    }

    &-work-in-progress-darker {
      background-color: #e3702b;
    }

    &-work-in-progress-darkest {
      background-color: #e35f3c;
    }

    &-danger {
      background-color: var(--danger-color-500);
    }

    &-danger-dark {
      background-color: var(--danger-color-600);
    }

    &-info {
      background-color: $brand-secondary-info;
    }

    &-muted {
      background-color: #ccc;
    }

    &-muted-dark {
      background-color: darken(#ccc, 10%);
    }
  }

  &-celebration {
    position: absolute;
    width: 120px;
    top: -30px;
    right: -30px;
  }
}
