@import 'src/styles/theme/config';

.tooltip-trigger {
  position: relative;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}

.tooltip-default {
  font-size: 11px;
  color: var(--text-color-inverted);
  background-color: var(--background-color-inverted);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px 15px;
  border-radius: 15px;
}

.tooltip {
  @extend .tooltip-default;
  z-index: $zindex-tooltip;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s, opacity 0.1s;

  $tooltip-offset: 10px;

  &.top,
  &.bottom {
    left: 50%;
    transform: translate(-50%, 0);

    &.right-edge {
      left: auto;
      transform: none;
      right: 0;
    }

    &.left-edge {
      left: 0;
      transform: none;
      right: auto;
    }
  }

  &.top {
    bottom: calc(100% + #{$tooltip-offset});
  }

  &.bottom {
    top: calc(100% + #{$tooltip-offset});
  }

  &.left,
  &.right {
    top: 50%;
    transform: translate(0, -50%);
  }

  &.left {
    right: calc(100% + #{$tooltip-offset});
  }

  &.right {
    left: calc(100% + #{$tooltip-offset});
  }

  &-content {
    white-space: nowrap;
  }

  a {
    color: var(--text-color-inverted);
  }
}
