@import 'src/styles/theme/config';

.optimization-statistics {
  margin-bottom: 45px;

  &-body {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}
