.report-chart-stats {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px 0 30px;

  .report-stat {
    margin: 15px 30px 15px 0;

    &-title {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 11px;
    }

    &-value {
      font-weight: 700;
      font-size: 17px;
      line-height: 1;
    }

    &-info {
      margin-left: 5px;
      font-size: 11px;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 1500px) {
  .report-chart-stats-widget {
    .report-stat {
      margin-right: 18px;
    }

    .report-stat-title {
      font-size: 10px;
    }

    .report-stat-value {
      font-size: 13px;
    }
  }

  .widget-report.editing {
    .report-chart-stats-widget {
      .report-stat {
        margin-right: 8px;
      }

      .report-stat-value {
        font-size: 13px;
      }
    }
  }
}
