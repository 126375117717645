@import 'src/styles/theme/config';

.rfm-side-stats {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    height: 50px;

    &-icon {
      display: flex;
      color: var(--text-color-lighter);

      &-other {
        width: 16px;
        height: 16px;
        border-radius: 10px;
        background-color: var(--text-color-lighter);
      }
    }

    &-title {
      font-weight: 700;
      margin-left: 10px;
    }

    &-description {
      position: relative;

      &-tooltip {
        padding: 10px 15px;
        background-color: var(--background-color);
        border: 1px solid var(--border-color);
        box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }

      &-icon {
        width: 8px;
        height: 8px;
        position: absolute;
        top: -8px;
      }
    }

    &-value {
      font-weight: 700;
    }

    &-subvalue {
      font-size: 11px;
      font-weight: 700;
      color: var(--text-color-lighter);
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.theme-dark {
  .rfm-side-stats {
    &-item {
      &-description {
        &-tooltip {
          background-color: var(--background-color-darker);
        }
      }
    }
  }
}
