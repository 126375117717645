@import 'src/styles/theme/config';

.merchandise-settings {
  display: flex;
  flex-direction: column;

  &-item {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid var(--border-color);
    opacity: 0.6;

    &-active {
      opacity: 1;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-label {
        font-weight: 700;
        margin-left: 10px;
      }

      .checkbox {
        margin: 0;
      }
    }

    &-field {
      display: flex;
      margin-top: 10px;
      margin-left: 26px;

      &-input {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;

        &-disabled {
          pointer-events: none;
        }
      }
    }
  }

  &-percentage-input {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &-form-label {
    display: flex;
    font-weight: 700;
    color: var(--text-color-light);
    margin-bottom: 10px;
    margin-top: 20px;

    img {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &-error {
    display: flex;

    &-message {
      color: var(--danger-color);
      margin-top: 15px;
      margin-left: 26px;
    }

    &-dot {
      margin-right: 8px;
      color: var(--danger-color);
    }

    a {
      text-decoration: underline;
    }
  }
}

.theme-dark {
  .merchandise-settings {
    &-item {
      background-color: var(--background-color-darker);
    }
  }
}
