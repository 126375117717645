.nps-feedback-form {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .form-group {
    margin-bottom: 0;
  }

  &-group {
    width: 100%;
  }

  &-textarea {
    height: 40px !important;
    resize: none;
    padding: 10px 15px !important;
  }
}
