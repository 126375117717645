@import 'src/styles/theme/config';

.achievement-progress-section {
  $section: &;
  flex: 1;
  position: relative;

  &:first-child {
    #{$section}-bar {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-left: 2px;

      &-inner {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }

  &:last-child {
    #{$section}-bar {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-right: 2px;
    }
  }

  &-bar {
    $bar: &;
    width: 100%;
    height: 12px;
    background-color: var(--background-color-darker);
    min-width: 15px;
    padding: 2px 0;

    &-inner {
      height: 100%;
      min-width: 8px;
      max-width: 100%;
      background-color: var(--text-color);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.success {
      #{$bar}-inner {
        background-color: var(--success-color);
      }
    }

    &.warning {
      #{$bar}-inner {
        background-color: var(--warning-color);
      }
    }

    &.danger {
      #{$bar}-inner {
        background-color: var(--danger-color);
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(50%, -50%);
    white-space: nowrap;
    font-size: 11px;
    z-index: 1;

    &:hover {
      z-index: 100;

      .progress-bubble {
        opacity: 1;
        visibility: visible;
      }
    }

    .progress-bubble {
      opacity: 0;
      visibility: hidden;
      transition: 0.15s;
    }
  }

  &-info-value {
    color: var(--text-color-light);
    position: absolute;
    left: 50%;
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-align: center;
    line-height: 1.3;
    background: var(--background-color);
    background: linear-gradient(
      90deg,
      transparent 0%,
      var(--background-color) 10%,
      var(--background-color) 90%,
      transparent 100%
    );
    padding: 0 10px;
    cursor: default;
  }

  &-pace {
    position: absolute;
    top: 50%;
    width: 3px;
    height: 28px;
    border-radius: 2px;
    transform: translate(-50%, -50%);
    background-color: var(--background-color);
    z-index: 20;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1px;
      width: 0;
      border-right: 1px dashed var(--text-color);
    }
  }
}

.theme-dark {
  .achievement-progress-section {
    &-bar {
      background-color: var(--background-color-darkest);
    }

    &-info-value {
      background: var(--background-color-darker);
      background: linear-gradient(
        90deg,
        transparent 0%,
        var(--background-color-darker) 10%,
        var(--background-color-darker) 90%,
        transparent 100%
      );
    }

    &-pace {
      background-color: var(--background-color-darker);

      &:after {
        border-right: 1px dashed var(--text-color);
      }
    }
  }
}
