@import 'src/styles/theme/config';

.modal-content {
  background-color: var(--background-color);

  .modal-inner {
    color: var(--text-color);

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px;

      &:before,
      &:after {
        display: none;
      }
    }

    .modal-title {
      font-family: $serif-font;
      font-size: 20px;
      font-weight: 700;

      &-xl {
        font-size: 34px;
      }
    }

    .modal-subtitle {
      color: var(--text-color-light);
      margin-top: 5px;
      font-size: 13px;
    }

    .modal-close {
      cursor: pointer;

      svg {
        transition: 0.2s;
      }

      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .modal-body {
      padding: 0 40px;
    }

    .modal-footer {
      padding: 40px;
    }
  }
}

.theme-dark {
  .modal-backdrop {
    opacity: 0.7;
  }

  .modal-content {
    background-color: var(--background-color-dark);
  }
}
