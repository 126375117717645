@import 'src/styles/theme/config';

.optimization-collection-statistics {
  margin-bottom: 45px;
  margin-top: 30px;

  &-body {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}
