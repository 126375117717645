@import 'src/styles/theme/config';

.main-content-navbar {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;

  &-items {
    display: flex;
    margin: 0 -15px;
  }

  &-item {
    color: var(--text-color-lighter);
    position: relative;
    padding: 10px 15px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s;

    &:after {
      content: '';
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 3px;
      border-radius: 3px;
      background-color: var(--primary-color-500);
      transition: 0.2s;
      transform: scale(0);
    }

    &:hover,
    &.active {
      color: var(--text-color);

      &:after {
        transform: scale(1);
      }
    }
  }

  &-actions {
    display: flex;
  }
}

.main-content {
  &-section {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 30px;
    margin-bottom: 30px;
    box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);

    &-title {
      font-family: $serif-font;
      font-size: 17px;
      font-weight: 700;
    }

    &-subtitle {
      font-size: 13px;
      color: var(--text-color-light);
      margin-top: 5px;
    }
  }
}
