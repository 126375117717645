@import 'src/styles/theme/config';

.sync-status {
  &-import-container {
    display: flex;
    min-height: 60px;
  }

  &-report-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;
  }

  &-report {
    padding: 5px 15px;
    flex: 0 0 calc(25% - 4px);
    text-transform: capitalize;
    font-weight: 700;
    margin: 2px;
    color: var(--text-color-inverted);

    &.ready {
      background-color: var(--success-color-600);
    }

    &.processing {
      background-color: var(--warning-color-600);
    }

    &.insufficient-data {
      background-color: var(--danger-color-600);
    }
  }
}
