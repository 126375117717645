@import 'src/styles/theme/config';

.access-requests {
  &-section {
    &-title {
      font-size: 17px;
      font-weight: 700;
      font-family: $serif-font;
      color: var(--text-color);
      margin-bottom: 10px;
    }

    &-content {
      background-color: #fff;
      border: 1px solid var(--border-color);
      border-radius: 20px;
      height: calc(100vh - 240px);
      overflow: auto;
    }
  }
}
