@import 'src/styles/theme/config';

.videos-modal {
  width: 80%;
  min-width: 990px;

  .modal-content {
    display: flex;
    border-radius: 0;
    color: var(--text-color);
  }

  &-video {
    flex-grow: 1;
    overflow: hidden;

    &-container {
      position: relative;
      width: 100%;
      padding-bottom: 57.45%; /* 16:9 aspect ratio */

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    width: 380px;
    padding: 30px;
    max-height: 350px;
    overflow-y: scroll;

    &-title {
      font-family: $serif-font;
      font-size: 17px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;
    }

    &-item {
      padding: 15px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      border-radius: 15px;
      border: 1px solid var(--border-color);

      &.active {
        border: 1px solid var(--text-color);
      }

      &:hover {
        background-color: var(--background-color-dark);
      }

      &-thumbnail {
        height: 40px;
        width: 54px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.theme-dark {
  .videos-modal {
    &-list {
      &-item {
        &:hover {
          background-color: var(--background-color-darker);
        }
      }
    }
  }
}
