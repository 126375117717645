@import 'src/styles/theme/config';

.setup-modal {
  $setup-modal: &;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  transition-delay: 0.6s;
  color: var(--text-color);

  &.active {
    transition-delay: 0s;

    #{$setup-modal}-overlay {
      transition-delay: 0s;
      background-color: rgba(0, 0, 0, 0.6);
    }

    #{$setup-modal}-container {
      transform: translate(-50%, -50%);
    }

    &.background {
      #{$setup-modal}-container {
        transform: translate(-50%, calc(-50% + 100px)) scale(0.75);
        opacity: 0;
      }
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s;
    transition-delay: 0.4s;
  }

  &-container {
    display: flex;
    border-radius: 20px;
    background-color: var(--background-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100vh);
    width: 570px;
    min-height: 350px;
    transition: 0.5s $smooth-easing;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 45px;
    border-radius: 20px;
    background-color: var(--background-color-dark);
    position: relative;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-logo-wrapper {
    padding: 5px 0;
    display: inline-block;
  }

  &-logo {
    width: 50%;
  }

  &-privacy-notice {
    padding: 0 60px 20px;
    color: var(--text-color-light);
    font-size: 11px;
    text-align: center;
  }

  &-connections {
    background-color: var(--background-color);
    border-radius: 20px;
  }

  &-connection {
    padding: 20px 30px;
    border-bottom: 1px solid var(--border-color);
    padding: 20px 30px;

    &:last-child {
      border-bottom: none;
    }

    &-append {
      border-top: 1px solid var(--border-color);
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: 15px;
      font-weight: 700;
    }

    &-subtitle {
      font-size: 13px;
      color: var(--text-color-light);
      margin-top: 5px;

      &-wrapped {
        max-width: 320px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &-form {
      padding-top: 15px;
      display: flex;
    }
  }

  &-configuration {
    padding: 20px 25px;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }

    &-title {
      font-size: 15px;
      font-weight: 700;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--text-color-light);
    position: relative;
    padding: 10px 20px;

    &-subtitle {
      margin-top: 10px;
    }
  }

  &-form-list-item {
    border-radius: 20px;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;

    &-name {
      font-size: 13px;
      font-weight: 700;
      color: var(--text-color);
      display: flex;
      align-items: center;
      min-height: 34px;
      word-break: break-all;
      margin-right: 20px;

      small.title {
        line-height: 1;
      }
    }

    &-number {
      font-size: 20px;
      margin: 0 15px 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.theme-dark {
  .setup-modal {
    &-container {
      display: flex;
      border-radius: 20px;
      background-color: var(--background-color-dark);
    }

    &-content {
      hr {
        background-color: var(--border-color);
        border-color: var(--border-color);
      }
    }

    &-connections {
      background-color: var(--background-color-dark);
      border-radius: 20px;
    }
  }
}
