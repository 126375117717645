@import 'src/styles/theme/config';

.insight-notification {
  &-impact {
    &-label {
      font-size: 12px;
      margin-top: 5px;
      color: var(--text-color-light);
      line-height: 1.2;
    }

    &-value {
      color: var(--text-color);
    }
  }
}
