@import 'src/styles/theme/config';

.cogs-vendor-form {
  &-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-label {
      margin-left: 10px;
    }
  }

  &-input {
    margin: 15px 0 0 25px;
  }

  .checkbox {
    display: none;
  }
}
