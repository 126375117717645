@import 'src/styles/theme/config';

.importer-upload-status {
  font-size: 11px;
  max-width: 50%;

  &-progress {
    display: flex;
    margin-right: 10px;
    height: 10px;
    min-width: 100px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--background-color-darker);

    &-inner {
      background-color: var(--success-color);
      border-radius: inherit;
      min-width: 8px;
      height: 6px;
      margin: 2px;
    }
  }

  &-cancel {
    font-size: 18px;
    cursor: pointer;
  }

  &-message {
    line-height: 1.4;
    display: flex;
    align-items: center;

    .icon {
      font-size: 15px;
    }
  }
}
