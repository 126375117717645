@import 'src/styles/theme/config';

.sidebar {
  $sidebar-drawer-width: 350px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: $sidebar-width;
  z-index: 1015;

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    border-right: 1px solid var(--border-color);
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 0.08);
  }

  &-logo {
    display: flex;
    justify-content: center;
    padding: 25px 0;

    img {
      width: 38px;
    }
  }

  &-main-nav {
    padding: 20px 0;

    &-divider {
      height: 1px;
      background-color: var(--border-color);
      margin: 10px 15px;
    }

    &-item {
      $sidebar-main-nav-item: &;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      position: relative;
      cursor: pointer;
      z-index: 10010;

      &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transform: scale(0);
        transition: transform 0.1s;
        background-color: var(--background-color-darker);
        border-radius: 20px;
        z-index: -1;
      }

      &.disabled {
        cursor: default;

        #{$sidebar-main-nav-item}-title {
          display: none;
        }
      }

      &.active,
      &:hover {
        &:after {
          transform: scale(1);
        }

        #{$sidebar-main-nav-item}-notification-icon {
          color: #fff;
          max-width: 50px;
          max-height: 20px;
          padding: 0 7px;
          top: 4px;
          right: 14px;
        }
      }

      &-notification-icon {
        position: absolute;
        border-radius: 20px;
        background-color: #ff0000;
        font-size: 11px;
        padding: 0;
        top: 16px;
        right: 28px;
        line-height: 20px;
        z-index: 1;
        color: #ff0000;
        transition: 0.15s;
        overflow: hidden;
        max-width: 5px;
        max-height: 5px;
      }

      &:hover {
        #{$sidebar-main-nav-item}-title {
          visibility: visible;
          opacity: 1;
        }
      }

      &-restricted {
        position: relative;

        &-icon {
          display: flex;
          padding: 2px;
          position: absolute;
          transform: translate(10px, -10px);
          color: #ccc;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      &-title {
        position: absolute;
        left: calc(100% - 10px);
        color: var(--text-color-inverted);
        background-color: var(--background-color-inverted);
        padding: 5px 15px;
        border-radius: 20px;
        white-space: nowrap;
        top: 50%;
        transform: translate(0, -50%);
        visibility: hidden;
        opacity: 0;
        transition: 0.1s;
        z-index: 10001;
      }

      &-new {
        position: absolute;
        border-radius: 20px;
        background-color: var(--background-color-inverted);
        z-index: 1;
        color: var(--text-color-inverted);
        max-width: 50px;
        max-height: 20px;
        padding: 2px 4px;
        font-size: 9px;
        font-weight: 700;
        line-height: 10.8px;
        bottom: 2px;
      }
    }
  }

  &-context-selector {
    &-trigger {
      padding: 20px 0;
      display: flex;
      justify-content: center;
      position: relative;
      transition: 0.1s;
      z-index: 10;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }

  &-drawer,
  &-drawer-second-level {
    position: fixed;
    top: 0;
    bottom: 0;
    left: $sidebar-width;
    width: $sidebar-drawer-width;
    background-color: var(--background-color-dark);
    border-right: 1px solid var(--border-color);
    overflow: auto;
    z-index: 10000;
  }

  &-drawer-second-level {
    left: calc(#{$sidebar-drawer-width + $sidebar-width});
  }

  &-drawer-pillbar {
    display: flex;
    justify-content: center;
    padding: 30px 15px 15px;
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: $sidebar-width;
    transition: 0.2s;
  }
}
