.progress-bubble {
  position: absolute;
  background-color: var(--background-color);
  text-align: center;
  min-width: 40px;
  padding: 3px 10px;
  border-radius: 15px;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-size: 11px;
  font-weight: 700;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    z-index: 1;
    bottom: -4px;
    left: 50%;
    margin-left: -4px;
    transform: rotate(45deg);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    z-index: -1;
    background-color: var(--background-color);
  }
}

.theme-dark {
  .progress-bubble {
    background-color: var(--background-color-darkest);

    &:after {
      background-color: var(--background-color-darkest);
    }
  }
}
