@import 'src/styles/theme/config';

.page-internal-app {
  .search-results {
    position: absolute;
    display: flex;
    flex-direction: column;

    width: 400px;
    min-height: 200px;
    max-height: 600px;
    overflow: auto;
    padding: 0 0 10px 0;
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid var(--border-color);
    box-shadow: 0px 2px 4px var(--border-color);
    z-index: 110;

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-section {
      &-title {
        font-size: 11px;
        padding: 10px 20px;
        color: var(--text-color-light);
        letter-spacing: 0.5px;
        font-weight: 700;
        text-transform: uppercase;
        position: sticky;
        top: 0;
        background-color: #f5f7f9;
      }

      &-item {
        padding: 10px 20px;

        &-clickable {
          cursor: pointer;
        }

        &:hover {
          background-color: var(--background-color-dark);
        }

        &-title {
          font-weight: 700;
        }

        &-subtitle {
          font-size: 11px;
          color: var(--text-color-light);
          margin-bottom: 10px;
          display: flex;
        }

        &-info {
          color: var(--text-color-light);
          font-size: 11px;

          &-link {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
