@import 'src/styles/theme/config';

.banner {
  &-dialog {
    width: 460px !important;
  }

  &-body {
    padding: 50px 40px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    color: var(--text-color);

    &-close {
      cursor: pointer;

      transition: 0.2s;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  &-logo {
    height: 30px;
  }

  &-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &-info-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 75px;
    height: 75px;
    border-radius: 20px;
    margin-right: 20px;

    &-img {
      max-width: 45px;
      max-height: 45px;
    }
  }

  &-title {
    font-family: $serif-font;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: var(--text-color);
  }

  &-description {
    margin-bottom: 60px;
    font-size: 15px;
    line-height: 1.5;
    color: var(--text-color-light);

    strong {
      color: var(--text-color);
    }
  }

  &-action {
    margin-bottom: 15px;
  }
}
