@import 'src/styles/theme/themes';

@mixin btn-variant($color, $shadow-color, $text-color: '') {
  @if $text-color != '' {
    color: $text-color;
  } @else {
    color: $color;
  }
  border-color: currentColor;

  &:hover:not([disabled], .disabled),
  &.hover:not([disabled], .disabled) {
    background-color: $color;
    border-color: $color;
    color: var(--grayscale-100);
    box-shadow: 0 0 0 3px $shadow-color;
  }
}

@mixin btn-fill-variant($color, $shadow-color, $highlight-color) {
  color: var(--grayscale-100);
  background-color: $color;
  border-color: $color;

  &:hover:not([disabled], .disabled),
  &.hover:not([disabled], .disabled) {
    background-color: $highlight-color;
    border-color: $highlight-color;
    box-shadow: 0 0 0 3px $shadow-color;
  }
}

.rx-btn {
  // Reset
  -webkit-appearance: none;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;

  // General
  transition: 0.2s;
  display: inline-flex;
  text-align: center;
  align-items: center;

  // Default variant
  background-color: var(--grayscale-100);
  border: 1px solid;
  border-color: var(--border-color);
  color: var(--grayscale-600);

  &:hover:not([disabled], .disabled),
  &.hover:not([disabled], .disabled) {
    color: var(--grayscale-900);
    background-color: var(--grayscale-200);
    box-shadow: 0 0 0 3px var(--border-color);
  }

  &[disabled],
  &.disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
  }

  &:focus {
    outline: none;
  }

  img,
  svg {
    width: 14px;
    height: 14px;
  }

  &.rx-btn-transparent {
    border-color: transparent;
    background-color: transparent;
  }

  // Variants
  &.rx-btn-primary {
    @include btn-variant(var(--primary-color-500), var(--primary-color-200));
  }

  &.rx-btn-secondary {
    @include btn-variant(
      var(--secondary-color-500),
      var(--secondary-color-200),
      var(--secondary-color-600)
    );
  }

  &.rx-btn-partners-primary {
    @include btn-variant(
      var(--partners-primary-color-500),
      var(--partners-primary-color-200),
      var(--partners-primary-color-600)
    );
  }

  &.rx-btn-success {
    @include btn-variant(
      var(--success-color-500),
      var(--success-color-200),
      var(--success-color-600)
    );
  }

  &.rx-btn-warning {
    @include btn-variant(
      var(--warning-color-500),
      var(--warning-color-200),
      var(--warning-color-600)
    );
  }

  &.rx-btn-danger {
    @include btn-variant(
      var(--danger-color-500),
      var(--danger-color-200),
      var(--danger-color-600)
    );
  }

  // Variants fill
  &.rx-btn-primary-fill {
    @include btn-fill-variant(
      var(--primary-color-500),
      var(--primary-color-200),
      var(--primary-color-600)
    );
  }

  &.rx-btn-secondary-fill {
    @include btn-fill-variant(
      var(--secondary-color-500),
      var(--secondary-color-200),
      var(--secondary-color-600)
    );
  }

  &.rx-btn-partners-primary-fill {
    @include btn-fill-variant(
      var(--partners-primary-color-500),
      var(--partners-primary-color-200),
      var(--partners-primary-color-600)
    );
  }

  &.rx-btn-success-fill {
    @include btn-fill-variant(
      var(--success-color-500),
      var(--success-color-200),
      var(--success-color-600)
    );
  }

  &.rx-btn-warning-fill {
    @include btn-fill-variant(
      var(--warning-color-500),
      var(--warning-color-200),
      var(--warning-color-600)
    );
  }

  &.rx-btn-danger-fill {
    @include btn-fill-variant(
      var(--danger-color-500),
      var(--danger-color-200),
      var(--danger-color-600)
    );
  }

  // Sizing
  height: 40px;
  padding: 3px 20px;
  font-size: 13px;
  border-radius: 10px;

  &.rx-btn-xs {
    height: 30px;
    padding: 3px 12px;
    font-size: 11px;
    border-radius: 6px;

    img,
    svg {
      width: 12px;
      height: 12px;
    }
  }

  &.rx-btn-sm {
    height: 36px;
    padding: 3px 15px;
    border-radius: 8px;
  }

  &.rx-btn-md {
    height: 40px;
    padding: 3px 18px;
    border-radius: 10px;
  }

  &.rx-btn-lg {
    height: 50px;
    padding: 3px 25px;
    border-radius: 12px;
    text-transform: uppercase;
    letter-spacing: 0.4px;

    &.rx-btn-with-icon {
      img,
      svg {
        margin-left: -3px;
        margin-right: 12px;
      }
    }

    &.rx-btn-with-icon-right {
      img,
      svg {
        margin-left: 12px;
        margin-right: -3px;
      }
    }

    img,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.rx-btn-block {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &.rx-btn-rounded {
    border-radius: 25px;
  }

  // Icons
  &.rx-btn-with-icon {
    img,
    svg {
      margin-left: -3px;
      margin-right: 8px;
    }
  }

  &.rx-btn-with-icon-right {
    img,
    svg {
      margin-left: 8px;
      margin-right: -3px;
    }
  }

  &.rx-btn-icon-only {
    img,
    svg {
      margin: 0;
    }
  }
}

.theme-dark {
  .rx-btn {
    color: var(--grayscale-300);
    background-color: var(--grayscale-800);

    &:hover:not([disabled], .disabled),
    &.hover:not([disabled], .disabled) {
      color: var(--grayscale-200);
      background-color: var(--grayscale-700);
      box-shadow: 0 0 0 3px var(--border-color);
    }

    &.rx-btn-transparent {
      border-color: transparent;
      background-color: transparent;
    }

    // Variants
    &.rx-btn-primary {
      @include btn-variant(var(--grayscale-100), var(--grayscale-500));

      &:hover:not([disabled], .disabled),
      &.hover:not([disabled], .disabled) {
        color: var(--text-color-inverted);
      }
    }

    &.rx-btn-secondary {
      @include btn-variant(
        var(--secondary-color-500),
        var(--secondary-color-300),
        var(--secondary-color-600)
      );
    }

    &.rx-btn-partners-primary {
      @include btn-variant(
        var(--partners-primary-color-500),
        var(--partners-primary-color-300),
        var(--partners-primary-color-600)
      );
    }

    &.rx-btn-success {
      @include btn-variant(
        var(--success-color-500),
        var(--success-color-300),
        var(--success-color-600)
      );
    }

    &.rx-btn-warning {
      @include btn-variant(
        var(--warning-color-500),
        var(--warning-color-300),
        var(--warning-color-600)
      );
    }

    &.rx-btn-danger {
      @include btn-variant(
        var(--danger-color-500),
        var(--danger-color-300),
        var(--danger-color-600)
      );
    }

    // Variants fill
    &.rx-btn-primary-fill {
      @include btn-fill-variant(
        var(--grayscale-100),
        var(--grayscale-500),
        var(--grayscale-100)
      );

      color: var(--text-color-inverted);
    }

    &.rx-btn-secondary-fill {
      @include btn-fill-variant(
        var(--secondary-color-500),
        var(--secondary-color-300),
        var(--secondary-color-500)
      );
    }

    &.rx-btn-partners-primary-fill {
      @include btn-fill-variant(
        var(--partners-primary-color-500),
        var(--partners-primary-color-300),
        var(--partners-primary-color-500)
      );
    }

    &.rx-btn-success-fill {
      @include btn-fill-variant(
        var(--success-color-500),
        var(--success-color-300),
        var(--success-color-500)
      );
    }

    &.rx-btn-warning-fill {
      @include btn-fill-variant(
        var(--warning-color-500),
        var(--warning-color-300),
        var(--warning-color-500)
      );
    }

    &.rx-btn-danger-fill {
      @include btn-fill-variant(
        var(--danger-color-500),
        var(--danger-color-300),
        var(--danger-color-500)
      );
    }
  }
}
