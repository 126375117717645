@import 'src/styles/theme/config';

.feature-request-step {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 60px;
  margin: 45px;
  border-radius: 20px;
  width: 100%;

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;
  }

  &-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66%;
    padding: 0 0 0 45px;
  }

  .wizard-progress {
    padding-left: 0;
  }
}

.theme-dark {
  .feature-request-step {
    background-color: var(--background-color-darker);
  }
}
