@import 'src/styles/theme/config';

.segment-processing {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-progress-bar {
    width: 400px;
    height: 5px;
    background-color: var(--border-color);
    border-radius: 5px;

    &-content {
      width: 5px;
      height: 100%;
      border-radius: 5px;
      background-color: var(--text-color-light);
    }
  }

  &-title {
    font-size: 17px;
    margin-bottom: 15px;
  }

  &-subtitle {
    margin: 20px 0 30px;
  }

  &-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.items-large {
      .segment-processing-item {
        width: 224px;
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 160px;

    &-info {
      margin-left: 10px;
    }

    &-no-progress {
      width: 20px;
      height: 20px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
    }
  }
}
