@import 'src/styles/theme/config';

.data-mapping {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 15px;
      font-weight: 700;
    }

    &-info-link {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-wrapper {
    margin-bottom: 45px;
    padding: 30px;
    border-radius: 20px;
    background-color: var(--background-color-darker);
    border: 1px solid var(--border-color);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-list {
    border: 1px solid var(--border-color);
    border-radius: 15px;
    background-color: var(--background-color);

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom: none;
      }

      &-title {
        display: flex;
        align-items: center;
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;

        &-custom {
          display: flex;
          flex-direction: column;
          margin-left: 26px;
        }

        &-icon {
          display: flex;
          align-items: center;
          width: 16px;
          margin-right: 10px;

          img {
            width: 16px;
          }
        }

        &-info {
          color: var(--text-color-light);
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
        }
      }

      &-actions {
        display: flex;

        &-item {
          margin-right: 5px;
        }
      }
    }
  }
}

.theme-dark {
  .data-mapping {
    &-list {
      background-color: var(--background-color-darkest);
    }
  }
}
