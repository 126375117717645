@import 'src/styles/theme/config';

.partner-profile {
  display: flex;
  gap: 60px;

  &-side {
    flex: 1 1 50%;
    padding: 45px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    margin-bottom: 30px;

    &-title {
      font-family: $serif-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23.14px;
      margin-bottom: 10px;
    }

    &-subtitle {
      font-size: 13px;
      font-weight: 400;
      line-height: 15.6px;
      color: var(--text-color-light);
      margin-bottom: 30px;
    }
  }
}
