@import 'src/styles/theme/config';

.partner-stats {
  display: flex;

  &-payout-request-toast-text {
    font-weight: 400;
  }

  &-profile {
    min-width: 220px;
    height: 220px;
    border-radius: 20px;
    background-color: #fff;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 25px 25px;
    text-align: center;

    &-image {
      margin-bottom: 10px;
    }

    &-name {
      font-size: 17px;
    }

    &-partner {
      font-size: 11px;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: 0.5px;
      color: var(--text-color-lighter);
      text-transform: uppercase;
    }
  }

  &-balance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 220px;
    height: 220px;
    border-radius: 20px;
    background-color: $partners-primary-color;
    color: #fff;
    margin-right: 30px;
    padding: 20px 25px 25px;

    &-title {
      font-weight: 700;
    }

    &-value {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }
  }

  &-pending {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 220px;
    height: 220px;
    border-radius: 20px;
    background-color: $partners-primary-color;
    color: #fff;
    margin-right: 30px;
    padding: 20px 25px 25px;

    &-title {
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    &-value {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }
  }

  &-affiliate {
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-info {
      &-icon {
        width: 8px;
        height: 8px;
        position: relative;
        top: -5px;
        right: -3px;

        &-description {
          width: 300px;
          padding: 10px 15px;
          background-color: #fff;
          border: 1px solid #f2f2f4;
          box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-weight: 700;
      }
    }

    &-kpis {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--border-color);
      padding: 20px 10px 0;

      &-item {
        flex: 1;

        &-title {
          font-weight: 700;
          line-height: 15.6px;
        }

        &-value {
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
        }
      }
    }
  }
}
