@import 'src/styles/theme/config';

.dashboard-list {
  padding: 15px 0;

  &-title {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color-light);
    padding: 15px 30px;
    font-size: 11px;
    letter-spacing: 0.5px;
  }

  &-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    margin: 0 15px 15px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);

    &-header {
      padding: 10px;
      display: flex;
      align-items: center;
      font-weight: 700;
      width: 100%;
    }

    &-content {
      width: 100%;
      margin-bottom: 10px;
    }

    &-item {
      $item: &;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      cursor: pointer;
      width: 100%;
      position: relative;

      &.active,
      &:hover {
        background-color: var(--background-color-dark);
      }

      &.active {
        font-weight: 700;
      }

      &:hover {
        #{$item}-arrow {
          transform: translateX(5px);
          opacity: 1;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;

        span {
          color: var(--text-color-light);
          font-size: 11px;
        }
      }

      &-star {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -10px;
        width: 20px;
        height: 20px;
        background-color: var(--background-color);
        border-radius: 10px;
        border: 1px solid var(--border-color);
      }

      &-arrow {
        transition: 0.15s;
        opacity: 0;
        display: flex;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      cursor: pointer;
      width: 100%;
      border-top: 1px solid var(--border-color);
      border-radius: 0 0 19px 19px;

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }
}

.theme-dark {
  .dashboard-list {
    &-group {
      background-color: var(--background-color-darker);

      &-item {
        &.active,
        &:hover {
          background-color: var(--background-color-darkest);
        }

        &-star {
          background-color: var(--background-color-darker);
        }
      }

      &-footer {
        &:hover {
          background-color: var(--background-color-darkest);
        }
      }
    }
  }
}
