@import 'src/styles/theme/config';

.shop-item {
  $item: &;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 25px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.7;
  }

  &-subtitle {
    display: flex;
    max-width: 340px;
    font-size: 13px;
    color: var(--text-color-light);
  }

  &-action {
    display: flex;
  }
}

.theme-dark {
  .shop-item {
    background-color: var(--background-color-darker);
  }
}
