@import 'src/styles/theme/config';

/* Special hack for portal datepickers */
body > div > .SingleDatePicker_picker {
  position: fixed;
  z-index: 105;
  border-radius: 10px;
}

.SingleDatePicker_picker {
  border-radius: 10px;
}

.DayPicker_transitionContainer {
  height: 320px !important;
}

.SingleDatePicker {
  .SingleDatePickerInput {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  .DateInput {
    border-radius: 10px;
    background-color: var(--background-color);

    input {
      padding: 12px 15px;
      border-radius: 10px;
      background-color: var(--background-color);
      color: var(--text-color);
      border-color: var(--border-color);
      font-size: 13px !important;
    }
  }
}

.DateRangePicker_picker {
  z-index: 100;
  border-radius: 10px;
  background-color: var(--background-color);

  .DateRangePicker_options {
    display: flex;
    flex-direction: column;
    padding: 18px 9px 18px 18px;
    min-width: 150px;

    .predefined-interval {
      line-height: 30px;
      padding: 0 10px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 8px;
      white-space: nowrap;
      text-align: left;
      border: 1px solid transparent;

      &.active,
      &:hover {
        background-color: var(--background-color-dark);
        border-color: var(--border-color);
      }
    }
  }
}

.DateRangePickerInput {
  display: flex;
  align-items: center;

  &.DateRangePickerInput__withBorder {
    background-color: transparent;
    border: none;
  }

  .DateRangePickerInput_arrow {
    display: flex;
    margin: 0 10px;
  }

  .DateInput {
    border-radius: 10px;
    background-color: transparent;

    &__small {
      width: 110px;
    }
  }

  .DateInput_input {
    border-radius: 10px;
    font-size: 13px;
    line-height: 28px;
    font-weight: 500;
    padding: 7px 12px;
    color: var(--text-color);
    border-bottom: 0;
    background-color: transparent;
  }

  .DateInput_input__focused {
    border-bottom: none;
  }
}

.CalendarDay {
  border: none !important;
  font-weight: 500;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: var(--secondary-color-500);
}

.CalendarDay__selected_start {
  border-radius: 20px 0 0 20px;
}

.CalendarDay__selected_end {
  border-radius: 0 20px 20px 0;
}

.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 8px;
}

.CalendarDay__selected_span {
  background-color: var(--secondary-color-100);
  color: var(--secondary-color-500);

  &:hover {
    background-color: var(--secondary-color-500);
    color: var(--text-color-inverted);
  }
}

.CalendarDay__hovered_span {
  background-color: $body-background;
  color: $brand-secondary;

  &:hover {
    background-color: $brand-secondary;
    color: #fff;
  }
}

.CalendarDay {
  &__default {
    &:hover {
      background-color: var(--secondary-color-500);
      color: var(--text-color-inverted);
      border-radius: 8px;
    }
  }

  &__selected_span {
    &:hover {
      border-radius: 0;
    }
  }

  &__selected_start {
    border-radius: 8px 0 0 8px;

    &:hover {
      border-radius: 8px 0 0 8px;
    }
  }

  &__selected_end {
    border-radius: 0 8px 8px 0;

    &:hover {
      border-radius: 0 8px 8px 0;
    }
  }

  &__blocked_out_of_range {
    background-color: var(--background-color);
    color: var(--text-color-lightest);

    &:hover {
      background-color: transparent;
      color: var(--text-color-lightest);
    }
  }
}

.CalendarMonth {
  background-color: var(--background-color);
}

.CalendarMonthGrid {
  background-color: var(--background-color);
}

.DayPicker {
  background-color: var(--background-color);
}

.DayPicker_transitionContainer {
  border-radius: 10px;
}

.DayPickerNavigation_button__default {
  background-color: var(--background-color);
  border-radius: 5px;
  border-color: transparent;

  &:hover {
    background-color: var(--background-color-dark);
    border-color: transparent;
  }
}

.CalendarMonth_caption {
  color: var(--text-color);

  strong {
    font-weight: 400;
  }
}

.DateInput_fang {
  height: 6px;
  transform: translate(0, 4px);

  &Stroke {
    stroke: var(--border-color);
  }

  &Shape {
    fill: var(--background-color);
  }
}

.DayPicker__withBorder {
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px var(--border-color);
}

.DayPickerKeyboardShortcuts_show {
  display: none;
}

.form-group {
  .SingleDatePicker {
    width: 100%;

    .SingleDatePickerInput {
      width: 100%;

      .DateInput {
        width: 100%;

        .DateInput_input {
          font-weight: 400;
          font-size: 15px;
          border-bottom: none !important;
        }
      }
    }
  }

  .size-sm {
    .DateInput_input {
      padding: 7px 12px !important;
      font-size: 13px !important;
    }
  }

  .input-round {
    .SingleDatePicker {
      .DateInput,
      .DateInput_input,
      .SingleDatePickerInput {
        border-radius: 20px;
      }
    }
  }
}

.theme-dark {
  .SingleDatePicker {
    .SingleDatePickerInput {
      background-color: var(--background-color-darkest);
    }

    .DateInput {
      background-color: var(--background-color-darkest);

      input {
        background-color: var(--background-color-darkest);
      }
    }
  }

  .CalendarMonth {
    background-color: var(--background-color-darker);
  }

  .CalendarMonthGrid {
    background-color: var(--background-color-darker);
  }

  .CalendarDay {
    &__default {
      background-color: var(--background-color-darker);
      color: var(--text-color);

      &:hover {
        background-color: var(--secondary-color-500);
        border-radius: 8px;
      }
    }

    &__selected_start {
      border-radius: 8px 0 0 8px;

      &:hover {
        border-radius: 8px 0 0 8px;
      }
    }

    &__selected_end {
      border-radius: 0 8px 8px 0;

      &:hover {
        border-radius: 0 8px 8px 0;
      }
    }

    &__selected,
    &__selected:active,
    &__selected:hover {
      background-color: var(--secondary-color-500);
    }

    &__selected_span {
      background-color: var(--background-color-darkest);
      color: var(--secondary-color-500);

      &:hover {
        background-color: var(--secondary-color-500);
        color: var(--text-color);
        border-radius: 0;
      }
    }

    &__blocked_out_of_range {
      background-color: var(--background-color-darker);
      color: var(--text-color-lightest);

      &:hover {
        background-color: transparent;
        color: var(--text-color-lightest);
      }
    }
  }

  .DayPicker {
    background-color: var(--background-color-darker);
  }

  .DayPicker__withBorder {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px var(--grayscale-550);
  }

  .DayPickerNavigation_button__default {
    background-color: var(--background-color-darker);

    &:hover {
      background-color: var(--background-color-darkest);
    }
  }

  .DateInput_fang {
    &Stroke {
      stroke: var(--grayscale-550);
    }

    &Shape {
      fill: var(--background-color-darker);
    }
  }
}
