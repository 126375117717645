@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.company-selector {
  min-width: 300px;
  min-height: 50px;
  padding: 10px;

  &-item {
    padding: 10px 15px 10px 10px;
    cursor: pointer;
    border-radius: 10px;
    line-height: 1;

    &:hover {
      background-color: $body-background;
    }

    &.active {
      background-color: rgba($brand-secondary, 0.03);
      font-weight: 500;
      color: $brand-secondary;

      .company-logo-spacer {
        border-color: rgba($brand-secondary, 0.1);
      }
    }

    &-title {
      margin-left: 10px;
      vertical-align: middle;
    }

    .icon {
      font-size: 24px;
      margin: 0 5px;
      vertical-align: middle;
    }
  }
}
