@import 'src/styles/theme/config';

.optimization-setup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 15px 30px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);

  &-text {
    font-size: 15px;
    color: var(--text-color-light);
    line-height: 1.7;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.theme-dark {
  .optimization-setup {
    background-color: var(--background-color-darker);
  }
}
