@import 'src/styles/theme/config';

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  min-height: 720px;

  &-item {
    position: relative;
    width: 160px;
    height: 220px;
    background-color: var(--background-color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--border-color);

    &-image-wrapper {
      flex: 1;
      width: 100%;
      display: flex;
      padding: 10px 10px 35px;
      position: relative;
    }

    &-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      flex: 1;
    }

    &-score {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;

      &-progress-bar {
        padding: 2px;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        margin-right: 5px;
        flex: 1;
        display: flex;
        border: 1px solid var(--border-color);

        &-inner {
          background-color: var(--tertiary-color-500);
          height: 8px;
          min-width: 8px;
          border-radius: 10px;
        }
      }

      &-value {
        color: var(--tertiary-color-500);
        font-size: 11px;
        font-weight: 700;
        width: 32px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &-info {
      border-top: 1px solid var(--border-color);
      display: flex;
      width: 100%;

      &-cell {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7px 10px;
        border-right: 1px solid var(--border-color);

        &:last-child {
          border-right: none;
        }

        &-title {
          text-transform: uppercase;
          color: var(--text-color-lighter);
          letter-spacing: 1px;
          font-size: 9px;
          font-weight: 700;
        }

        &-value {
          font-size: 13px;
          font-weight: 700;
          line-height: 1.3;
        }
      }
    }
  }
}

.theme-dark {
  .product-grid {
    &-item {
      background-color: var(--background-color-darkest);

      &-score {
        &-progress-bar {
          background-color: var(--background-color-darkest);
        }
      }
    }
  }
}
