@import 'src/styles/theme/config';

.access-request-list-item {
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
  display: flex;

  &-details {
    flex: 1;
  }

  &-company {
    font-weight: 700;
    margin-bottom: 5px;

    &-platform {
      font-size: 11px;
      margin-bottom: 5px;
    }
  }

  &-request {
    display: flex;
    font-size: 11px;
    color: var(--text-color-light);
  }

  &-actions {
    display: flex;
    align-items: center;
  }

  &-action {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: var(--background-color-dark);
    border: 1px solid var(--border-color);
    cursor: pointer;
  }
}
