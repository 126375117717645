@import 'src/styles/theme/config';

.segment-details-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 220px);
  margin-top: 20px;

  .segment-details-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      line-height: 32px;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid transparent;
      transition: 0.2s;
      margin-right: 10px;
      font-size: 11px;

      &.active {
        background-color: rgba($brand-secondary, 0.03);
        border-color: rgba($brand-secondary, 0.03);
        color: $brand-secondary;

        svg * {
          stroke: $brand-secondary;
        }
      }

      svg * {
        transition: 0.2s;
        stroke: var(--primary-color-500);
      }
    }
  }

  .segment-details-content {
    display: flex;
    flex: 1;
    width: 100%;

    .content-container {
      width: 100%;
      margin-bottom: 0;
      padding: 40px;
    }

    .segment-details {
      width: 100%;
    }
  }

  .segment-details-header {
    margin-bottom: 30px;
  }

  .segment-details-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .segment-details-subtitle {
    font-size: 13px;
    line-height: 1.6;
    color: var(--text-color-light);
  }
}
