@import 'src/styles/theme/config';

.merchandise-child-store-select {
  display: flex;
  align-items: center;
  position: relative;

  .styled-select__single-value {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
  }
}
