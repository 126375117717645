@import 'src/styles/theme/config';

.page-app-container {
  .search {
    &-bar {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        transform: translate(15px, -50%);
      }

      input {
        height: 40px;
        width: 100px;
        outline: none;
        border-radius: 20px;
        padding: 10px 10px 10px 45px;
        transition: 0.5s $smooth-easing;
        border: none;
        background-color: transparent;

        &:hover {
          background-color: var(--background-color-darker);
        }

        &.active {
          width: 350px;
          background-color: var(--background-color-darker);
          transition: 0.2s $smooth-easing;
        }
      }
    }

    &-results {
      display: flex;
      flex-direction: column;
      transition: 0.2s $smooth-easing;
      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: calc(100% + 30px);
      right: 0;
      width: 350px;
      box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      background-color: var(--background-color);
      border: 1px solid var(--border-color);
      z-index: 110;
      min-height: 100px;
      padding: 30px;

      &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        transition-delay: 0.1s;
      }
    }

    &-result-section {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--text-color-lighter);
        margin-bottom: 10px;
      }

      &-item {
        display: block;
        padding: 10px 15px;
        color: var(--text-color-light);
        background-color: var(--background-color-dark);

        strong {
          color: var(--text-color);
        }

        &:hover {
          background-color: var(--background-color-darker);
        }

        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}

.theme-dark {
  .page-app-container {
    .search {
      &-results {
        background-color: var(--background-color-dark);
      }

      &-result-section {
        &-title {
          color: var(--text-color-light);
        }

        &-item {
          background-color: var(--background-color-darker);

          &:hover {
            background-color: var(--background-color-darkest);
          }
        }
      }
    }
  }
}
