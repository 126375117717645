@import 'src/styles/theme/config';

.page-email-report {
  .page-app-content {
    display: flex;
    flex-direction: column;
  }

  .email-report-form {
    display: flex;
    flex-direction: column;
    flex: 1;

    &-content {
      display: flex;
      min-height: 100%;
      flex: 1;

      &-left,
      &-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 45px;
        flex: 0 0 50%;
      }

      &-left {
        border-right: 1px solid var(--border-color);
        background-color: var(--background-color);
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
      }

      &-title {
        font-size: 20px;
        font-family: $serif-font;
        font-weight: 700;
      }

      &-subtitle {
        margin-top: 10px;
        color: var(--text-color-light);
      }
    }

    &-footer {
      border-top: 1px solid var(--border-color);
      padding: 15px 45px;
      position: sticky;
      bottom: 0;
      background-color: var(--background-color);
    }
  }

  .email-report-layout {
    height: 100%;
    padding-bottom: 100px;

    &-content {
      &-grid {
        width: 100%;
        display: inline-grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media (max-width: 1450px) {
  .page-email-report {
    .email-report-layout {
      &-content {
        &-grid {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.theme-dark {
  .page-email-report {
    .email-report-form {
      &-content {
        &-left,
        &-right {
          background-color: var(--background-color-dark);
        }
      }

      &-footer {
        background-color: var(--background-color-dark);
      }
    }
  }
}
