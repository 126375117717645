@import 'src/styles/theme/config';

.page-company-settings {
  .blocklist-company-container {
    .blocklist-company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px 30px;
      border: 1px solid var(--border-color);
      border-radius: 20px;
      margin-bottom: 20px;

      &-title {
        font-size: 17px;
        font-weight: 700;
      }

      &-subtitle {
        font-size: 13px;
      }
    }
  }
}
