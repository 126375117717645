@import 'src/styles/theme/config';

.statistic-item {
  &:hover {
    .statistic-expand {
      visibility: visible;
      opacity: 1;
    }
  }
}

.statistic-expand {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: 0.15s;
  z-index: 100;

  &-link {
    transition: 0.15s;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--border-color);
    }

    svg {
      transition: 0.1s;
    }
  }
}
