@import 'src/styles/theme/config';

.account {
  &-content {
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 20px;

    &-divider {
      width: 1px;
      background-color: var(--border-color);
    }
  }

  &-section {
    padding: 30px;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }

    &-title {
      font-family: $serif-font;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &-gmail-active-icon {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}
