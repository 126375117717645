@import 'src/styles/theme/config';

.data-table-row:hover {
  .product-position {
    &-index {
      &-pin {
        display: block;
      }
    }
  }
}

.product-position {
  display: flex;
  align-items: center;

  &-index {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: bold;

    &-pinned {
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    &-pinned-interactive {
      padding: 4px 8px;
      border-radius: 20px;
      cursor: pointer;
      margin-right: -8px;

      &:hover {
        background-color: var(--border-color);
      }

      svg {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    &-pin {
      display: none;
      padding: 8px;
      border-radius: 20px;
      margin-right: 5px;
      cursor: pointer;

      &:hover {
        background-color: var(--border-color);
      }

      svg {
        display: flex;
        width: 12px;
        height: 12px;
      }
    }
  }

  &-progression {
    margin-left: 5px;
    width: 20px;
    display: flex;
    justify-content: center;
  }
}
