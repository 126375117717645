@use 'sass:color';

@function hex2rgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

// Brand colors
$brand-primary: #1a172b;
$brand-secondary: #7f96ff;
$brand-tertiary: #1f0a9b;
$brand-partners-primary: #007bff;

// Grayscale
$grayscale-100: #ffffff;
$grayscale-200: #f7f7f9;
$grayscale-300: #f0f0f2;
$grayscale-400: #e8e8eb;
$grayscale-500: #b3b1b7;
$grayscale-550: #424246;
$grayscale-600: #35353b;
$grayscale-700: #2c2c32;
$grayscale-800: #232328;
$grayscale-900: #1f1f26;

// Placeholder color
$placeholder-color: #cccccc;

:root {
  --grayscale-100: #{$grayscale-100};
  --grayscale-200: #{$grayscale-200};
  --grayscale-300: #{$grayscale-300};
  --grayscale-400: #{$grayscale-400};
  --grayscale-500: #{$grayscale-500};
  --grayscale-550: #{$grayscale-550};
  --grayscale-600: #{$grayscale-600};
  --grayscale-700: #{$grayscale-700};
  --grayscale-800: #{$grayscale-800};
  --grayscale-900: #{$grayscale-900};

  --primary-color-100: #dcdae2;
  --primary-color-200: #b9b8c3;
  --primary-color-300: #858294;
  --primary-color-400: #3e3b4d;
  --primary-color-500: #{$brand-primary};
  --primary-color-600: #{darken($brand-primary, 5%)};
  --primary-color-700: #{darken($brand-primary, 10%)};
  --primary-color-800: #{darken($brand-primary, 15%)};
  --primary-color-900: #{darken($brand-primary, 20%)};

  --primary-color-rgb: #{hex2rgb($brand-primary)};

  --secondary-color-100: #{lighten($brand-secondary, 20%)};
  --secondary-color-200: #{lighten($brand-secondary, 15%)};
  --secondary-color-300: #{lighten($brand-secondary, 10%)};
  --secondary-color-400: #{lighten($brand-secondary, 5%)};
  --secondary-color-500: #{$brand-secondary};
  --secondary-color-600: #{darken($brand-secondary, 5%)};
  --secondary-color-700: #{darken($brand-secondary, 10%)};
  --secondary-color-800: #{darken($brand-secondary, 15%)};
  --secondary-color-900: #{darken($brand-secondary, 20%)};

  --secondary-color-rgb: #{hex2rgb($brand-secondary)};

  --tertiary-color-100: #{lighten($brand-tertiary, 20%)};
  --tertiary-color-200: #{lighten($brand-tertiary, 15%)};
  --tertiary-color-300: #{lighten($brand-tertiary, 10%)};
  --tertiary-color-400: #{lighten($brand-tertiary, 5%)};
  --tertiary-color-500: #{$brand-tertiary};
  --tertiary-color-600: #{darken($brand-tertiary, 5%)};
  --tertiary-color-700: #{darken($brand-tertiary, 10%)};
  --tertiary-color-800: #{darken($brand-tertiary, 15%)};
  --tertiary-color-900: #{darken($brand-tertiary, 20%)};

  --tertiary-color-rgb: #{hex2rgb($brand-tertiary)};

  --partners-primary-color-100: #{lighten($brand-partners-primary, 40%)};
  --partners-primary-color-200: #{lighten($brand-partners-primary, 30%)};
  --partners-primary-color-300: #{lighten($brand-partners-primary, 20%)};
  --partners-primary-color-400: #{lighten($brand-partners-primary, 10%)};
  --partners-primary-color-500: #{$brand-partners-primary};
  --partners-primary-color-600: #{darken($brand-partners-primary, 5%)};
  --partners-primary-color-700: #{darken($brand-partners-primary, 10%)};
  --partners-primary-color-800: #{darken($brand-partners-primary, 20%)};
  --partners-primary-color-900: #{darken($brand-partners-primary, 25%)};

  --partners-primary-color-rgb: #{hex2rgb($brand-partners-primary)};
}

.theme-light {
  // State colors
  $success-color: #abc73a;
  $warning-color: #f9be56;
  $danger-color: #ea573e;
  $highlight-color: rgb(242, 245, 255);
  $highlight-color-light: rgb(249, 250, 255);
  $highlight-gray: #fcfcfc;

  --background-color: #{$grayscale-100};
  --background-color-dark: #{$grayscale-200};
  --background-color-darker: #{$grayscale-300};
  --background-color-darkest: #{$grayscale-400};
  --background-color-inverted: #{$grayscale-900};

  --text-color: #{$brand-primary};
  --text-color-light: #{rgba($brand-primary, 0.6)};
  --text-color-lighter: #{rgba($brand-primary, 0.4)};
  --text-color-lightest: #{rgba($brand-primary, 0.2)};
  --text-color-inverted: #{$grayscale-100};

  // Placeholder color
  --placeholder-color: #{$placeholder-color};

  // Border colors
  $border-color: #{rgba($brand-primary, 0.08)};
  $border-color-solid: #f4f4f5;
  $border-color-inverted: #{$brand-primary};

  --border-color: #{$border-color};
  --border-color-solid: #{$border-color-solid};
  --border-color-inverted: #{$border-color-inverted};

  // Success
  --success-color: #{$success-color};
  --success-color-100: #{lighten($success-color, 40%)};
  --success-color-200: #{lighten($success-color, 30%)};
  --success-color-300: #{lighten($success-color, 20%)};
  --success-color-400: #{lighten($success-color, 10%)};
  --success-color-500: #{$success-color};
  --success-color-600: #{darken($success-color, 5%)};
  --success-color-700: #{darken($success-color, 10%)};
  --success-color-800: #{darken($success-color, 20%)};
  --success-color-900: #{darken($success-color, 35%)};

  // Warning
  --warning-color: #{$warning-color};
  --warning-color-100: #{lighten($warning-color, 25%)};
  --warning-color-200: #{lighten($warning-color, 20%)};
  --warning-color-300: #{lighten($warning-color, 10%)};
  --warning-color-400: #{lighten($warning-color, 5%)};
  --warning-color-500: #{$warning-color};
  --warning-color-600: #{darken($warning-color, 5%)};
  --warning-color-700: #{darken($warning-color, 10%)};
  --warning-color-800: #{darken($warning-color, 20%)};
  --warning-color-900: #{darken($warning-color, 35%)};

  // Danger
  --danger-color: #{$danger-color};
  --danger-color-100: #{lighten($danger-color, 35%)};
  --danger-color-200: #{lighten($danger-color, 20%)};
  --danger-color-300: #{lighten($danger-color, 10%)};
  --danger-color-400: #{lighten($danger-color, 5%)};
  --danger-color-500: #{$danger-color};
  --danger-color-600: #{darken($danger-color, 5%)};
  --danger-color-700: #{darken($danger-color, 10%)};
  --danger-color-800: #{darken($danger-color, 20%)};
  --danger-color-900: #{darken($danger-color, 35%)};

  // Highlight
  --highlight-color: #{$highlight-color};
  --highlight-color-light: #{$highlight-color-light};
  --highlight-gray: #{$highlight-gray};

  background-color: var(--background-color-dark);

  body {
    color: var(--text-color);
    background-color: var(--background-color-dark);
  }

  .visible-light {
    display: block;
  }

  .hidden-light {
    display: none;
  }
}

.theme-dark {
  // State colors
  $success-color: #b0d700;
  $warning-color: #f59e0b;
  $danger-color: #ee3030;

  $brand-secondary: #a5afd9;
  $brand-tertiary: #8a7fcd;

  // Background colors
  --background-color: #{$grayscale-900};
  --background-color-dark: #{$grayscale-800};
  --background-color-darker: #{$grayscale-700};
  --background-color-darkest: #{$grayscale-600};
  --background-color-inverted: #{$grayscale-100};

  $placeholder-color: #cccccc;

  // Text colors
  --text-color: #{$grayscale-100};
  --text-color-light: #{rgba($grayscale-100, 0.6)};
  --text-color-lighter: #{rgba($grayscale-100, 0.4)};
  --text-color-lightest: #{rgba($grayscale-100, 0.2)};
  --text-color-inverted: #{$grayscale-900};

  // Placeholder color
  --placeholder-color: #{$placeholder-color};

  // Border colors
  $border-color-solid: #303035;

  --border-color: #{rgba($grayscale-100, 0.1)};
  --border-color-solid: #{$border-color-solid};
  --border-color-inverted: #{$grayscale-100};

  // Brand colors
  --secondary-color-100: #{lighten($brand-secondary, 20%)};
  --secondary-color-200: #{lighten($brand-secondary, 15%)};
  --secondary-color-300: #{lighten($brand-secondary, 10%)};
  --secondary-color-400: #{lighten($brand-secondary, 5%)};
  --secondary-color-500: #{$brand-secondary};
  --secondary-color-600: #{darken($brand-secondary, 5%)};
  --secondary-color-700: #{darken($brand-secondary, 10%)};
  --secondary-color-800: #{darken($brand-secondary, 15%)};
  --secondary-color-900: #{darken($brand-secondary, 20%)};

  --secondary-color-rgb: #{hex2rgb($brand-secondary)};

  --tertiary-color-100: #{lighten($brand-tertiary, 20%)};
  --tertiary-color-200: #{lighten($brand-tertiary, 15%)};
  --tertiary-color-300: #{lighten($brand-tertiary, 10%)};
  --tertiary-color-400: #{lighten($brand-tertiary, 5%)};
  --tertiary-color-500: #{$brand-tertiary};
  --tertiary-color-600: #{darken($brand-tertiary, 5%)};
  --tertiary-color-700: #{darken($brand-tertiary, 10%)};
  --tertiary-color-800: #{darken($brand-tertiary, 15%)};
  --tertiary-color-900: #{darken($brand-tertiary, 20%)};

  --tertiary-color-rgb: #{hex2rgb($brand-tertiary)};

  // Success
  --success-color: #{$success-color};
  --success-color-100: #{lighten($success-color, 40%)};
  --success-color-200: #{lighten($success-color, 30%)};
  --success-color-300: #{lighten($success-color, 20%)};
  --success-color-400: #{lighten($success-color, 10%)};
  --success-color-500: #{$success-color};
  --success-color-600: #{darken($success-color, 5%)};
  --success-color-700: #{darken($success-color, 10%)};
  --success-color-800: #{darken($success-color, 20%)};
  --success-color-900: #{darken($success-color, 30%)};

  // Warning
  --warning-color: #{$warning-color};
  --warning-color-100: #{lighten($warning-color, 25%)};
  --warning-color-200: #{lighten($warning-color, 20%)};
  --warning-color-300: #{lighten($warning-color, 10%)};
  --warning-color-400: #{lighten($warning-color, 5%)};
  --warning-color-500: #{$warning-color};
  --warning-color-600: #{darken($warning-color, 5%)};
  --warning-color-700: #{darken($warning-color, 10%)};
  --warning-color-800: #{darken($warning-color, 20%)};
  --warning-color-900: #{darken($warning-color, 30%)};

  // Danger
  --danger-color: #{$danger-color};
  --danger-color-100: #{lighten($danger-color, 35%)};
  --danger-color-200: #{lighten($danger-color, 20%)};
  --danger-color-300: #{lighten($danger-color, 10%)};
  --danger-color-400: #{lighten($danger-color, 5%)};
  --danger-color-500: #{$danger-color};
  --danger-color-600: #{darken($danger-color, 5%)};
  --danger-color-700: #{darken($danger-color, 10%)};
  --danger-color-800: #{darken($danger-color, 20%)};
  --danger-color-900: #{darken($danger-color, 30%)};

  // Highlight
  --highlight-color: #{$grayscale-700};
  --highlight-color-light: #{$grayscale-600};
  --highlight-gray: #{$grayscale-800};

  background-color: var(--background-color-dark);

  body {
    color: var(--text-color);
    background-color: var(--background-color-dark);
  }

  .form-control {
    background-color: var(--background-color-darkest);
    color: var(--text-color);
    border-color: var(--border-color);

    &:focus {
      border-color: var(--border-color-inverted);
    }

    &[disabled] {
      background-color: var(--background-color-darkest);
      opacity: 0.5;
    }
  }

  .visible-dark {
    display: block;
  }

  .hidden-dark {
    display: none;
  }
}
