.multi-value {
  &-item {
    min-width: 0;
  }

  &-tooltip {
    overflow: auto;
    max-height: 315px;
    max-width: 250px;
    min-width: 200px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: default;
    word-break: break-all;
  }
}
