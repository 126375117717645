@import 'src/styles/theme/config';

.disable-gender-detection {
  &-dialog {
    width: 460px !important;
    margin-top: 30vh;
  }

  &-body {
    padding: 50px 40px;
  }

  &-title {
    font-family: $serif-font;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  &-actions {
    display: flex;
    justify-content: center;
  }
}
