@import 'src/styles/theme/config';

.page-merchandise {
  .collection-products {
    &-table {
      background: var(--background-color);
      border: 1px solid var(--border-color);
      border-radius: 20px;

      &-cell {
        &-subtitle {
          font-weight: 700;
          font-size: 11px;
          color: var(--text-color-lighter);
        }
      }
    }
  }
}
