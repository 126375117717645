@import 'src/styles/theme/config';

.profile-picture {
  $pp: &;
  display: inline-block;
  vertical-align: middle;

  &-spacer {
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }

  &-img {
    border-radius: 10px;
    overflow: hidden;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 3px solid #fff;
  }

  &-letters {
    border-radius: 9px;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border: 2px solid #fff;
    color: var(--text-color);
    text-align: center;
    line-height: 33px;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--border-color);
  }

  &.size-xs {
    #{$pp}-spacer {
      border: 1px solid var(--border-color);
    }

    #{$pp}-img,
    #{$pp}-letters {
      width: 25px;
      height: 25px;
      line-height: 23px;
      font-size: 11px;
      border: 1px solid #fff;
    }
  }

  &.size-xlg {
    #{$pp}-spacer {
      border: 5px solid var(--border-color);
      border-radius: 30px;
    }

    #{$pp}-img,
    #{$pp}-letters {
      width: 100px;
      height: 100px;
      border-radius: 25px;
      line-height: 90px;
      font-size: 35px;
      border: 5px solid #fff;
    }
  }
}
