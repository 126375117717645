@import 'src/styles/theme/config';

@mixin dot-variant($color, $shadow-color) {
  color: $color;
  border-color: $color;

  &.checked {
    background-color: $color;
    color: var(--grayscale-100);
  }

  &.unchecked {
    opacity: 0.4;
  }

  &:hover {
    box-shadow: 0 0 0 3px $shadow-color;
    opacity: 1;
  }
}

.nps-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;

  .nps-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation-delay: 0.8s;
  }

  .nps-banner {
    position: relative;
    padding: 30px;
    border-radius: 20px;
    background-color: var(--background-color);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--border-color);
    animation-delay: 1s;

    &-close {
      position: absolute;
      top: 35px;
      right: 35px;
      display: flex;
      cursor: pointer;
      transition: 0.1s;

      &:hover,
      &.selected {
        opacity: 0.5;
      }
    }

    &-question {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    &-score {
      display: flex;
      justify-content: space-between;
      margin: 0 -5px;

      .dot {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;

        height: 40px;
        width: 40px;

        border-radius: 50%;
        border: 1px solid transparent;
        transition: 0.1s;
        cursor: pointer;
        font-size: 15px;

        &-detractors {
          @include dot-variant(
            var(--danger-color-500),
            var(--danger-color-200)
          );
        }

        &-passives {
          @include dot-variant(
            var(--warning-color-500),
            var(--warning-color-200)
          );
        }

        &-promoters {
          @include dot-variant(
            var(--success-color-500),
            var(--success-color-200)
          );
          font-weight: 700;
        }
      }
    }

    &-description {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: var(--text-color-light);
      margin-top: 15px;
    }

    &-feedback {
      margin-top: 24px;

      &-title {
        font-size: 15px;
        font-weight: bold;
        margin: 18px 0;
      }
    }
  }
}
