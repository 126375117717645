@import 'src/styles/theme/config';

.data-table-controls-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  position: sticky;
  bottom: 0;
  z-index: 100;
  border-top: 1px solid var(--border-color);

  &-rounded {
    border-radius: 0 0 20px 20px;
  }
}

.data-table-controls {
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
  }

  &-right {
    display: flex;
    align-items: center;
  }

  &-pagination {
    display: flex;
    align-items: center;
    user-select: none;

    &-limit {
      display: flex;
      align-items: center;
      margin-right: 30px;
      border-right: 1px solid var(--border-color);
      padding: 0 15px;
    }

    &-info {
      height: 32px;
      padding: 0 15px;
      display: flex;
      align-items: center;
    }

    &-control {
      width: 36px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 8px;
      transition: 0.1s;

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }
}
