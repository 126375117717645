@import 'src/styles/theme/config';

.setup-modal-submodal {
  $submodal: &;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9990;
  transition-delay: 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &.active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;

    #{$submodal}-container-wrapper {
      transition-delay: 0.08s;
      transform: translate(0, 0);
    }
  }

  &.background {
    #{$submodal}-container-wrapper {
      transform: translate(0, 100px) scale(0.75);
      opacity: 0;
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    position: absolute;
    top: 0;
    width: 100%;
    transform: translate(0, 100vh);
    min-height: 100vh;
    transition: opacity 0.6s cubic-bezier(0.85, 0, 0.15, 1),
      transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  }

  &-container {
    display: flex;
    width: 620px;
    border-radius: 20px;
    background-color: var(--background-color);
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    background-color: var(--background-color-dark);
    padding: 45px;
    border-radius: 20px;
  }

  &-title {
    font-size: 20px;
    font-family: $serif-font;
    font-weight: 700;
  }

  &-subtitle {
    margin-top: 10px;
    margin-bottom: 0;
    color: var(--text-color-light);
  }

  &-section-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--text-color-light);
    font-weight: 700;
    display: flex;
    margin-bottom: 10px;
  }

  &-body {
    margin: 45px 0;
    border-radius: 20px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .connection-form-item {
    padding: 20px 30px;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }
  }
}

.theme-dark {
  .setup-modal-submodal {
    &-container {
      background-color: var(--background-color-dark);
    }

    &-body {
      background-color: var(--background-color-darker);
    }
  }
}
