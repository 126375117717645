@import 'src/styles/theme/config';

.internal-access-requests-logs-list-item {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  color: var(--text-color);
  padding: 0 15px;

  &:hover {
    background-color: var(--background-color-dark);
  }

  &-title {
    font-weight: 700;
  }

  &-subtitle {
    font-size: 11px;
    color: var(--text-color-light);
  }

  &-column {
    padding: 10px 15px;
  }
}
