@import 'src/styles/theme/config';

.pill-radio-input {
  $pillbar: &;

  display: flex;
  border-radius: 10px;
  background-color: var(--background-color-dark);
  border: 1px solid var(--border-color);
  position: relative;

  &.no-background {
    background-color: transparent;
    border-color: transparent;

    #{$pillbar}-indicator {
      top: 0;
      bottom: 0;
    }

    .pill {
      margin: 1px;
      padding: 9px 12px;
    }
  }

  .pill {
    padding: 7px 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    margin: 3px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    color: var(--text-color-light);

    &:hover {
      color: var(--text-color);

      .tooltip {
        opacity: 1;
        visibility: visible;
      }

      svg * {
        stroke: var(--primary-color-500);
      }
    }

    &.active {
      transition-delay: 0.2s;
      background-color: #fff;
      color: var(--text-color);
      border: 1px solid var(--border-color);
      border-radius: 8px;

      svg * {
        stroke: var(--primary-color-500);
      }
    }

    &.disabled {
      color: #ccc !important;
      background-color: transparent !important;
    }

    &-content {
      font-size: $font-size-small;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 16px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      display: none;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: -9999px;
    }
  }
}
