@import 'src/styles/theme/config';

.setup-modal-loader {
  $il: &;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1000;

    #{$il}-overlay {
      transform: translate(0, 0);
    }

    #{$il}-image {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &.loaded {
    #{$il}-overlay {
      opacity: 0;
      transition-delay: 0.15s;
    }

    #{$il}-image {
      transform: translate(0, -10px);
      opacity: 0;
      transition: 0.2s;
      transition-delay: 0s;
    }
  }

  &-overlay {
    position: absolute;
    background-color: var(--background-color);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    transition: 0.4s;
  }

  &-image {
    max-height: 50px;
    transition: 0.5s;
    transition-delay: 0.2s;
    transform: translate(0, 200px);

    opacity: 0;
  }
}

.theme-dark {
  .setup-modal-loader {
    &-overlay {
      background-color: var(--background-color-dark);
    }
  }
}
