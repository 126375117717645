@import 'src/styles/theme/config';

.support-banner-wrapper {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100000;

  .support-banner {
    position: relative;
    padding: 20px;
    padding-right: 60px;
    border-radius: 20px;
    background-color: var(--background-color);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--border-color);

    &-close {
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      cursor: pointer;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      line-height: 1.4;
      font-weight: 700;
      color: var(--text-color);
      font-size: 15px;

      &-image {
        margin: 0 20px 0 0;
      }
    }
  }
}

.theme-dark {
  .support-banner-wrapper {
    .support-banner {
      background-color: var(--background-color-darker);
    }
  }
}
