@import 'src/styles/theme/config';

.collection-status {
  display: flex;
  align-items: center;
  cursor: pointer;

  &-dropdown {
    $ho: &;
    position: absolute;
    top: 100%;
    left: 0;
    top: 10px;
    min-width: 200px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-weight: 400;
    text-align: left;
    overflow: hidden;

    &-item {
      padding: 15px 20px;
      display: block;
      transition: 0.1s;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--text-color-light);

      &:hover {
        background-color: var(--background-color-dark);
        color: var(--text-color);
      }

      svg {
        margin-right: 15px;
      }
    }
  }

  &-tooltip {
    left: calc(100% + 8px) !important;
  }
}

.theme-dark {
  .collection-status {
    &-dropdown {
      background-color: var(--background-color-dark);

      &-item {
        &:hover {
          background-color: var(--background-color-darker);
        }
      }
    }
  }
}
