// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color, $focusColor) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: $focusColor;
  }
}
