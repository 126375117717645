@import 'src/styles/theme/config';
@import '../../mixins.scss';

.segment-rule-wrapper {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.segment-rule {
  $sr: &;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  position: relative;
  border-radius: 15px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  transition: 0.1s;

  &.with-error {
    border-color: var(--danger-color);
  }

  &-remove {
    @include remove-btn();
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: var(--background-color);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
    margin: 10px;
    border-radius: 10px;
    min-height: 40px;

    &:first-child {
      border-left: none;
    }

    &-error {
      position: absolute;
      top: 0;
      left: 10px;
      color: var(--danger-color);
      background-color: var(--background-color);
      font-size: 11px;
      padding: 2px 8px;
      transform: translateY(calc(-100% - 2px));
      border-radius: 10px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

      &:after {
        content: '';
        content: '';
        position: absolute;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        transform: rotate(45deg);
        bottom: -4px;
        left: 15px;
        border-width: 4px;
        border-style: solid;
        border-color: transparent var(--background-color)
          var(--background-color) transparent;
      }
    }
  }

  .rule-input {
    border-color: transparent;
    padding: 10px 5px;
  }

  .rule-datepicker {
    .SingleDatePickerInput {
      border: none;

      .DateInput_input {
        border: none;
        padding: 10px 5px;
      }
    }
  }

  &-filters {
    flex: 1 1 100%;
  }

  &-filter {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;

    &-remove {
      @include remove-btn();
    }
  }
}

.segment-rule-add {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  transition: 0.1s;
  width: 62px;
  height: 62px;
}

.theme-dark {
  .segment-rule {
    background-color: var(--background-color-darker);

    &-item {
      background-color: var(--background-color-darkest);

      &-error {
        background-color: var(--background-color-darkest);

        &:after {
          border-color: transparent var(--background-color-darkest)
            var(--background-color-darkest) transparent;
        }
      }
    }

    &-remove {
      @include remove-btn(true);
    }

    &-filter {
      &-remove {
        @include remove-btn(true);
      }
    }

    .form-control {
      background-color: var(--background-color-darkest);
    }
  }

  .segment-rule-add {
    background-color: var(--background-color-darker);
  }

  #segmentTitle {
    background-color: var(--background-color-darker);
  }
}
