/*------------------------------------------------------------------
[Fonts - Serif]
*/

@font-face {
  font-family: 'GT Super Display';
  src: url('#{$serif-font-path}/GT-Super-Display-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Super Display';
  src: url('#{$serif-font-path}/GT-Super-Display-Bold.woff2') format('woff2'),
    url('#{$serif-font-path}/GT-Super-Display-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
