@import 'src/styles/theme/config';

.configuration {
  &-item {
    margin: 0 -30px;
    padding: 20px 30px;
    border-bottom: 1px solid var(--border-color);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &-title {
      font-size: 17px;
      font-weight: 700;
    }

    &-subtitle {
      margin-top: 5px;
      color: var(--text-color-light);
    }

    &-merged-company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--border-color);
      padding: 15px 0;

      &-title {
        font-size: 13px;
        font-weight: 700;
      }

      &-subtitle {
        font-size: 13px;
        color: var(--text-color-light);
      }
    }
  }
}
