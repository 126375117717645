@import 'src/styles/theme/config';

.with-loader {
  position: relative;

  &-active {
    .loader {
      transition: none;
      visibility: visible;
      opacity: 1;
    }
  }

  &-square {
    .loader {
      border-radius: 0;
    }
  }
}

.loader {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background-color);
  z-index: 1000;
  border-radius: 20px;
  transition: 0.6s;

  &.square {
    border-radius: 0;
  }

  &:after {
    content: '';
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
      75deg,
      var(--background-color) 33%,
      var(--background-color-inverted) 60%,
      var(--background-color) 66%
    );
    background-size: 300% 100%;
    opacity: 0.06;
    animation: slide 1.5s infinite;
  }

  @keyframes slide {
    0% {
      background-position: right;
    }
    100% {
      background-position: left;
    }
  }
}
