//Typography Mixins

@mixin font-size($v) {
  font-size: ceil($font-size-base + $v);
}

@mixin font-size-small($v) {
  font-size: ceil($font-size-small + $v);
}

@mixin font-size-large($v) {
  font-size: ceil($font-size-large + $v);
}
