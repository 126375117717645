.rfm-status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 11px;
  text-decoration: none !important;

  &-sm {
    font-size: 9px;
    padding: 4px 8px;

    svg,
    img {
      width: 10px;
      height: 10px;
    }

    &-text {
      margin-left: 4px;
    }
  }

  &-text {
    margin-left: 6px;
    white-space: nowrap;
  }

  svg,
  img {
    width: 12px;
    height: 12px;
  }
}
