@import 'src/styles/theme/config';

.merchandise-pixel-install {
  font-size: 13px;
  font-weight: 700;
  line-height: 15.6px;
  color: var(--text-color-light);

  strong {
    color: var(--text-color);
  }

  &-item {
    margin-bottom: 30px;
  }
}
