@import 'src/styles/theme/config';

.page-auth {
  &-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-panel {
    width: 100%;
    min-height: 300px;
    background-color: var(--background-color);
    max-width: 450px;
    padding: 50px 40px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);
    color: var(--text-color);

    &-header {
      margin-bottom: 90px;
    }

    &-logo {
      height: 30px;
    }

    &-title {
      font-family: $serif-font;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 15px;
    }

    &-description {
      margin-bottom: 30px;
      color: var(--text-color-light);
    }
  }
}

.theme-dark {
  .page-auth {
    &-panel {
      background-color: var(--background-color-darker);
    }
  }
}
