@import 'src/styles/theme/config';

.page-imports {
  .data-feed-container {
    background: $body-background;
    padding: 15px 20px;
    display: flex;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: stretch;
  }

  .import-info {
    font-weight: 500;

    &-title {
      font-size: 11px;
      font-weight: 700;
      color: var(--text-color-light);
      margin-bottom: 3px;
    }

    &-error-wrapper {
      position: relative;
      cursor: default;
      font-weight: 500;
      border-radius: 6px;
      background-color: var(--danger-color-200);
      color: var(--danger-color-500);
      padding: 3px 5px;
      font-size: 11px;
    }

    &-progress {
      padding: 2px;
      background-color: $body-background;
      border-radius: 6px;

      &-inner {
        height: 12px;
        background-color: var(--warning-color);
        border-radius: 4px;
        min-width: 8px;
      }
    }
  }

  .import-item {
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);

    &-cell {
      display: flex;
      align-items: center;
      padding: 15px 10px;
    }

    &-title {
      font-weight: 600;
    }

    &-subtitle {
      font-size: 11px;
      color: var(--text-color-light);
    }

    &-icon {
      display: flex;

      &-success {
        color: var(--success-color);
      }

      &-warning {
        color: var(--warning-color);
      }

      &-danger {
        color: var(--danger-color);
      }
    }
  }

  .hc-article-info {
    display: flex;
    margin-left: 5px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
