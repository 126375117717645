@import 'src/styles/theme/config';

.collection-pinned-position-form {
  &-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &-date {
    display: flex;
    align-items: center;

    &-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &-input {
    margin: 0 10px;
    cursor: pointer;
  }

  &-checkbox {
    display: flex;
    cursor: pointer;
  }

  label {
    margin: 0;
  }

  .checkbox {
    display: none;
  }

  &-label {
    display: flex;
    font-weight: 700;
    color: var(--text-color-light);
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
