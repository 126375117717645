@import 'src/styles/theme/config';

.internal-automations {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-select {
      display: flex;
      align-items: center;
      position: relative;

      .styled-select__single-value {
        font-weight: 700;
      }

      & > svg {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
      }
    }
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
  }

  &-config {
    margin-top: 30px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--border-color);
      padding: 15px 0;

      &-title {
        font-size: 13px;
        font-weight: 700;
      }

      &-subtitle {
        font-size: 11px;
        font-weight: 700;
        color: rgba($brand-primary, 0.6);
      }

      &-info {
        text-align: right;

        &-date {
          font-size: 13px;
          font-weight: 700;
        }

        &-user {
          font-size: 11px;
          font-weight: 700;
          text-align: right;
          color: var(--text-color-light);
        }
      }

      &-divider {
        margin: 0 15px;
        height: 35px;
      }
    }
  }

  &-journey-item {
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid var(--border-color);
    color: var(--text-color);

    &-details {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &-meta {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      color: var(--text-color-light);
    }

    &-company {
      display: flex;
      align-items: center;

      &-name {
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
      }
    }
  }
}
