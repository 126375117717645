@import 'src/styles/theme/config';

.setup-wizard {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--background-color);
  border-radius: 20px;
  margin: 45px;

  &-topbar {
    padding: 25px 30px;
    border-bottom: 1px solid var(--border-color);

    &-select {
      display: flex;
      align-items: center;
      position: relative;

      .styled-select__single-value {
        font-weight: 700;
      }

      & > svg {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
      }
    }
  }

  &-content {
    display: flex;
    flex: 1;

    &-sidebar {
      width: 300px;
      border-right: 1px solid var(--border-color);
    }

    &-main {
      width: 500px;
      padding: 45px;
    }
  }
}

.theme-dark {
  .setup-wizard {
    background-color: var(--background-color-darker);
  }
}
