@import 'src/styles/theme/config';

.one-time-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-content {
    min-width: 500px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &-title {
      font-size: 24px;
      font-family: $serif-font;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 5px;
    }

    &-subtitle {
      color: var(--text-color-light);
    }
  }

  .import-options-item {
    padding: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 20px;
    background: var(--background-color);
    box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);
    border: 1px solid transparent;
    font-size: 15px;
    transition: border-color 0.15s;
    flex: 1;
    width: 250px;
    min-height: 250px;
    cursor: pointer;

    &:hover {
      border-color: var(--background-color-inverted);
    }

    &-icon {
      margin-bottom: 60px;
    }
  }
}

.theme-dark {
  .one-time-import {
    .import-options-item {
      background-color: var(--background-color-darker);
    }
  }
}
