@import 'src/styles/theme/config';

.page-merchandise {
  .collection-list {
    &-table {
      background-color: var(--background-color);
      border: 1px solid var(--border-color);
      border-radius: 20px;

      .data-table-column-index-0 {
        z-index: 4;
      }
    }

    &-resync {
      display: flex;
      margin-right: 8px;

      svg {
        margin: 0;
      }
    }

    &-toast-text {
      font-weight: 400;
    }
  }
}
