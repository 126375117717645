@import 'src/styles/theme/config';

.journey-item {
  padding: 20px;
  width: 600px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid var(--border-color);
  position: relative;
  color: var(--text-color);

  &:last-child {
    &:before {
      display: none;
    }
  }

  &:before {
    content: ' ';
    width: 2px;
    background-color: var(--border-color);
    position: absolute;
    top: 50px;
    left: -31px;
    bottom: -30px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 20px;
    left: -40px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--primary-color-500);
    border: 5px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &-success {
    &:after {
      background-color: var(--success-color);
    }
  }

  &-danger {
    &:after {
      background-color: var(--danger-color);
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &-details {
    font-size: 15px;

    & > :first-child {
      margin-bottom: 30px;
    }
  }

  &-meta {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: var(--text-color-light);
  }

  &-company {
    display: flex;
    align-items: center;
  }

  &-action-button {
    margin-left: 8px;
  }
}
