@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.report {
  $report: &;

  &-widget {
    .report-container {
      min-height: auto;
      border-radius: 20px;
      background-color: transparent;
    }

    #{$report}-chart-container {
      min-height: 400px;
      padding-bottom: 0;
    }

    #{$report}-cohort-container {
      max-height: 480px;
    }
  }

  &-dummy {
    .report-container {
      min-height: auto;
      border-radius: 20px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.08);
      margin-bottom: 30px;
      padding-top: 10px;
    }
  }

  &-container {
    min-height: calc(100vh - $topbar-height);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
  }

  &-header {
    &-general {
      display: flex;
      border-bottom: 1px solid var(--border-color);
      padding: 10px 30px;
      background-color: var(--background-color-dark);
      position: relative;
      z-index: 100;
      align-items: center;
      min-height: 60px;
      z-index: 105;
    }

    &-divider {
      width: 1px;
      background-color: var(--border-color);
      margin: 0 15px;
      align-self: stretch;
    }

    &-left,
    &-right {
      display: flex;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin: 0 5px;
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        min-width: 38px;
        height: 38px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transform: scale(0);
        transition: transform 0.1s;
        background-color: var(--background-color-darker);
        border-radius: 10px;
        border: 1px solid var(--border-color);
        z-index: -1;
      }

      &-how-to-read {
        padding: 0;

        &:after {
          background-color: transparent;
          border: none;
        }
      }

      &:hover {
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  &-progress {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid $border-color;
    font-size: $font-size-small;
  }

  &-chart-tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-chart-container {
    padding: 0 25px 30px;
    min-height: 400px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    .echarts-for-react {
      width: auto;
      flex: 1;
    }

    .has-second-y-axis {
      .report-prediction-trigger-button {
        right: 62px;
      }
    }
  }

  &-side-statistics {
    width: 370px;
    min-width: 370px;
    padding-left: 30px;
    margin-left: 15px;
    border-left: 1px solid var(--border-color);
  }

  &-prediction-trigger {
    position: absolute;
    right: 25px;

    &.disabled {
      .report-prediction-trigger-button {
        &:hover {
          width: 48px;

          &:before {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
            position: absolute;
          }

          .tooltip {
            opacity: 1;
            visibility: visible;
          }
        }

        span {
          display: none;
        }

        .report-prediction-trigger-indicator {
          right: 4px;
        }

        .image-wrapper {
          img {
            animation: none;
          }
        }
      }
    }

    &.active {
      .report-prediction-trigger-button {
        &:hover {
          .report-prediction-trigger-close {
            opacity: 1;
          }

          img {
            opacity: 0;
          }
        }
      }

      .image-wrapper {
        img {
          animation: rotate 4s infinite linear;
        }
      }
    }

    &.loading {
      .image-wrapper {
        transform: rotate(-1080deg);
      }
    }

    &.cohort {
      right: 25px;
      bottom: -15px;
      top: auto;
      left: auto;
    }

    &-button {
      width: 48px;
      height: 48px;
      transition: 0.15s $smooth-easing;
      background-color: var(--background-color-dark);
      border: 1px solid var(--border-color);
      border-radius: 19px;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      font-size: 14px;
      position: relative;

      &:hover {
        width: 170px;

        span {
          opacity: 1;
          transition-delay: 0.1s;
          transform: scale(1);
        }

        .report-prediction-trigger-type-selector {
          transition-delay: 0.1s;
          opacity: 1;
          transform: scale(1);
        }

        .report-prediction-trigger-indicator {
          right: 44px;
        }
      }

      .image-wrapper {
        transition: 1.5s transform ease-out;
        width: 31px;
        margin-right: 8px;
        position: relative;

        img {
          max-width: 100%;
          animation: tada 4s infinite linear;
          transition: 0.2s;
        }
      }

      span {
        position: absolute;
        font-weight: 500;
        line-height: 1;
        opacity: 0;
        transition: 0.15s;
        width: 110px;
        right: 49px;
        transition-delay: 0;
        transform: scale(0.9);
      }
    }

    &-indicator {
      background-color: var(--background-color);
      border-radius: 15px;
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      right: 4px;
      box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
      z-index: -1;
      transition-delay: 0.05s;
      transition: 0.15s $smooth-easing;
    }

    &-type-selector {
      display: flex;
      position: absolute;
      opacity: 0;
      transition: 0.15s;
      width: 117px;
      right: 43px;
      transition-delay: 0;
      cursor: pointer;
      transform: scale(0.9);
    }

    &-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 25px;
      margin-right: 5px;
      position: relative;
      transition: 0.2s;

      &.active,
      &:hover {
        background-color: rgba(var(--tertiary-color-rgb), 0.1);
        color: var(--tertiary-color-500);

        svg {
          transform: rotate(15deg) scale(1);
        }
      }

      &:hover {
        .tooltip {
          opacity: 1;
          visibility: visible;
        }
      }

      svg {
        transform: rotate(0) scale(0.9);
        transition: 0.2s;
      }
    }

    &-close {
      border-radius: 20px;
      opacity: 0;
      position: absolute;
      transition: 0.2s;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 5px;
      right: 5px;
      z-index: 1;

      &:hover {
        svg path {
          opacity: 0.7;
        }
      }
    }

    .info-tooltip {
      right: calc(100% + 10px);
      min-width: 170px;
    }
  }

  &-cohort-container-scroll {
    flex: 1;
    overflow: auto;
    display: flex;
  }

  &-content {
    display: grid;
    grid-template-rows: max-content max-content 1fr max-content;
    grid-template-columns: minmax(0, 1fr);
    position: relative;
    flex: 1;

    &-data-table {
      grid-template-rows: max-content 1fr;

      .report-table-container {
        grid-row: span 2;
      }
    }

    &.compare-active {
      grid-template-columns: 50% 50%;

      .report-setting-bar-section {
        grid-row-start: 1;

        &-compare {
          grid-column-start: 2;
        }
      }

      .report-chart-tools {
        grid-row-start: 2;
      }

      .report-chart-container {
        grid-row-start: 3;
      }

      .report-table-container {
        grid-row-start: 4;
      }

      .report-iconographical-container {
        padding: 0 15px 30px;
      }

      @media (max-width: 1400px) {
        .report-iconographical-item {
          flex: 0 0 50% !important;
        }
      }
    }

    &-section {
      display: contents;
    }
  }

  .report-content-section.compare > div {
    border-left: 2px solid var(--border-color);
  }

  &-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-segment-data-unavailable-message {
    &-iconographical {
      height: 100vh;
    }

    &-cohort {
      height: 100vh;
    }
  }

  &-iconographical-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    .report-iconographical-item {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      margin: 15px 0;
      flex: 0 0 16.6666%;
      padding: 0 15px;
      font-weight: 500;

      &.not-available {
        .report-iconographical-item-shape {
          opacity: 0.6;
        }
      }

      &-shape {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px;
        box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);
        background-color: var(--background-color-dark);
        border: 1px solid var(--border-color);
      }

      &-icon {
        margin-bottom: 30px;

        svg {
          width: 45px;
          height: 45px;
        }
      }

      &-title {
        font-size: 15px;
        font-weight: 700;
      }

      &-value {
        margin-top: 5px;
        font-size: 20px;
        font-weight: 700;
        word-break: break-all;
      }

      &-percentage {
        font-size: 11px;
        margin-left: 5px;
      }
    }

    @media (max-width: 1500px) {
      .report-iconographical-item {
        flex: 0 0 33.3333% !important;
      }
    }
  }

  &-additional-data-chart {
    display: flex;

    &-item {
      margin-right: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-label {
      font-weight: 700;
      margin-bottom: 2px;
      font-size: 11px;
      color: var(--text-color-light);
    }

    &-value {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 0px;
      color: var(--text-color);
    }

    img {
      position: absolute;
      top: 15px;
      right: 12px;
      width: 32px;
      animation: rotate 4s infinite linear;
    }
  }
}

.report-cohort {
  $cohort: &;
  margin: 20px 0 0;
  position: relative;

  &-header {
    white-space: break-spaces;

    #{$cohort}-item {
      z-index: 1;
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid var(--border-color);
      font-weight: 700;

      &-fixed {
        z-index: 10;
      }

      &-hovered {
        &:after {
          opacity: 1 !important;
        }
      }

      &:after {
        content: ' ';
        height: 3px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: var(--background-color-inverted);
        opacity: 0;
      }
    }
  }

  &-body {
    position: relative;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  &-row {
    display: table-row;
    position: relative;
  }

  &-item {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 11px;
    color: var(--text-color);
    width: 115px;
    height: 50px;
    padding: 3px;
    background-color: var(--background-color);
    cursor: default;
    position: relative;

    &-overall {
      #{$cohort}-item-inner {
        color: var(--text-color) !important;
        background-color: var(--background-color-darker) !important;
      }
    }

    &-fixed {
      position: sticky !important;
      z-index: 1;

      &-first {
        left: 0;
      }

      &-second {
        left: 140px;
      }

      #{$cohort}-item-inner {
        background-color: transparent !important;
      }
    }

    &-hovered {
      background-color: var(--background-color-dark);

      &.report-cohort-item-fixed-first {
        &:after {
          content: ' ';
          width: 3px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background-color: var(--background-color-inverted);
          z-index: 10;
        }
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
      border-radius: 10px;
      transition: 0.1s;
    }

    &-info-icon {
      width: 8px;
      height: 8px;
      position: relative;
      top: -5px;
      right: -3px;
    }
  }
}

.report-export {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99998;
  background-color: var(--background-color);
  color: var(--brand-primary-500);
  overflow: hidden;
  transform: translateZ(0);

  &:after {
    background-color: var(--background-color);
    z-index: 10000;
  }

  & > .be-spinner {
    z-index: 10001;
  }

  &-container {
    width: calc(3508px / 3.5);
    height: calc(2480px / 3.5);
    background-color: var(--background-color);

    .report {
      &-container {
        border: none;
      }

      &-content {
        &.compare-active {
          .report-iconographical-item {
            flex: 0 0 33.3333% !important;

            &-icon {
              margin-bottom: 5px;

              svg {
                width: 30px;
                height: 30px;
              }
            }

            &-title {
              font-size: 9px;
            }

            &-value {
              font-size: 11px;
              margin-top: 2px;
            }

            &-shape {
              padding: 20px 5px;
            }
          }
        }
      }

      &-iconographical-container {
        padding: 0;

        .report-iconographical-item {
          margin: 10px 0;
          padding: 0 10px;

          &-icon {
            margin-bottom: 20px !important;

            svg {
              width: 32px;
              height: 32px;
            }
          }

          &-title,
          &-score {
            font-size: 11px;
          }

          &-value {
            font-size: 13px;
          }

          &-shape {
            padding: 20px;
            border-radius: 20px;
          }
        }
      }
    }
  }
}

.theme-dark {
  .report {
    &-header {
      &-general {
        background-color: var(--background-color);
      }
    }

    &-prediction-trigger {
      &-indicator {
        background-color: var(--background-color-darker);
      }
    }
  }

  .report-cohort {
    &-item {
      &-hovered {
        background-color: var(--background-color-darkest);
      }
    }
  }
}
