@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.company-selector {
  &-title {
    color: var(--text-color-light);
    line-height: 25px;
    font-weight: 700;
    font-size: 13px;
  }
}
