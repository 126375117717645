@import 'src/styles/theme/config';

.cohort-analysis-insight-kpi-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;

    &-title {
      font-size: 11px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &-growth {
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;

      &.success {
        color: var(--success-color);
      }

      &.danger {
        color: var(--danger-color);
      }

      &.neutral {
        color: var(--text-color-lighter);
      }
    }
  }

  &-subvalue {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.7;
    opacity: 25%;

    &-reference {
      color: var(--text-color-lighter);
    }
  }

  &:hover {
    .cohort-analysis-insight-kpi-item-subvalue {
      opacity: 100%;
    }
  }
}
