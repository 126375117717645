@import 'src/styles/theme/config';

.quantity-input {
  display: flex;
  align-items: center;

  &.bordered {
    background-color: var(--background-color);
    padding: 0px 3px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }

  input {
    border: none;
    width: 35px;
    padding: 5px;
    text-align: center;
    transition: 0.1s;
    -moz-appearance: textfield;
    font-weight: bold;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover {
      background-color: var(--background-color-dark);
    }

    &.ordinal {
      text-align: right;
    }
  }

  &-minus,
  &-plus {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color-darker);
    cursor: pointer;

    &:hover {
      background-color: var(--background-color-dark);
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.theme-dark {
  .quantity-input {
    &.bordered {
      background-color: var(--background-color-darkest);
    }

    input {
      &:hover {
        background-color: var(--background-color-darker);
      }
    }
  }
}
