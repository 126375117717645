@import 'src/styles/theme/config';

.mapping-item {
  $item: &;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 25px;
  border-bottom: 1px solid var(--border-color);

  &:last-child {
    border-bottom: none;
  }

  &-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.7;
    word-break: break-all;
    margin-right: 15px;
  }

  &-subtitle {
    font-size: 13px;
    color: var(--text-color-light);
  }

  &-input {
    display: flex;
    width: 50%;
  }
}
