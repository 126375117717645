@import 'src/styles/theme/config';

.marketing-banner {
  display: flex;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
  padding: 15px 30px;
  align-items: center;

  &-message-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  &-message-title {
    font-weight: 600;
  }
}
