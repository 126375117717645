@mixin dropdown($position, $edge, $dark: false) {
  position: absolute;
  z-index: 100;
  border-radius: 20px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);

  @if $position == bottom {
    top: calc(100% + 10px);
  }

  @if $position == top {
    bottom: calc(100% + 10px);
  }

  @if $position == right {
    left: calc(100% + 10px);
  }

  @if $position == left {
    right: calc(100% + 10px);
  }

  @if $edge == left {
    left: -8px;
  }

  @if $edge == right {
    right: -8px;
  }

  @if $edge == bottom {
    bottom: -8px;
  }

  @if $edge == top {
    top: -8px;
  }

  .dropdown-inner {
    @if $dark {
      background-color: var(--background-color-darker);
    } @else {
      background-color: var(--background-color);
    }
    border-radius: 19px;
    padding: 15px;
  }

  &:after,
  &:before {
    @if $position == bottom {
      top: -7px;
    }

    @if $position == top {
      bottom: -7px;
    }

    @if $position == right {
      left: -7px;
    }

    @if $position == left {
      right: -7px;
    }

    @if $edge == left {
      left: 20px;
    }

    @if $edge == right {
      right: 20px;
    }

    @if $edge == bottom {
      bottom: 20px;
    }

    @if $edge == top {
      top: 20px;
    }

    content: ' ';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    @if $dark {
      background-color: var(--background-color-darker);
    } @else {
      background-color: var(--background-color);
    }
    border: 1px solid var(--border-color);
    transform: rotate(45deg);
    z-index: -1;
  }
}

@mixin flipCard() {
  &-flip-card {
    $fc: &;
    background-color: transparent;
    perspective: 1000px;

    &.flipped {
      #{$fc}-inner {
        transform: translateX(-100%) rotateY(-180deg);
      }

      #{$fc}-front {
        visibility: hidden;
        opacity: 0;
      }

      #{$fc}-back {
        visibility: visible;
        opacity: 1;
      }
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.5s $smooth-easing;
      transform-origin: center right;
      transform-style: preserve-3d;
    }

    &-front,
    &-back {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &-front {
      transition: 0s;
      transition-delay: 0.25s;
      visibility: visible;
      opacity: 1;
    }

    &-back {
      transition: 0s;
      transition-delay: 0.25s;
      visibility: hidden;
      opacity: 0;
      transform: rotateY(180deg);
    }
  }
}
