.pricing-estimate {
  text-align: center;
  line-height: 1.2;

  &-value {
    font-size: 28px;
    font-weight: 700;
  }

  &-desc {
    font-size: 14px;
    font-weight: 500;
  }
}