@import 'src/styles/theme/config';

.page-goals {
  .goal-group {
    margin-bottom: 30px;

    &-title {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}
