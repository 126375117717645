@import 'src/styles/theme/config';

.progress-indicator {
  display: flex;
  align-items: center;
  flex: 1;

  &-progress {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10px;
    background-color: var(--border-color);
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px;

    &-content {
      min-width: 6px;
      height: 100%;
      border-radius: 5px;
      background-color: var(--text-color-light);
    }
  }

  &-value {
    min-width: 40px;
    width: 40px;
  }
}
