@import 'src/styles/theme/config';

.report-chart-legend {
  padding: 0 22px;
  display: flex;

  &-item {
    min-width: 80px;
    height: 40px;
    background-color: var(--background-color-dark);
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 1;
    margin: 15px 7px 15px 7px;
    border-radius: 10px;
    padding: 0 10px;
    cursor: pointer;
    color: var(--text-color-lighter);
    transition: 0.05s;

    &.active {
      color: var(--text-color);
    }

    &:hover {
      box-shadow: 0 0 0 3px var(--border-color);
    }

    &.with-value {
      font-size: 10px;
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: 7px;
    }
  }

  &-value {
    font-weight: 500;
    font-size: 13px;
    margin-top: 2px;
  }
}

.report-chart-legend-placeholder {
  height: 40px;
}

.theme-dark {
  .report-chart-legend {
    &-item {
      background-color: var(--background-color-darker);
    }
  }
}
