@import 'src/styles/theme/config';

.close-modal {
  position: absolute;
  top: -10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border-radius: 20px;
  z-index: 10000;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: 0.1s;

  &.right {
    right: -10px;
  }

  &.left {
    left: -10px;
  }

  &:hover {
    transform: translateY(1px);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }
}

.theme-dark {
  .close-modal {
    background-color: var(--background-color-darker);
  }
}
