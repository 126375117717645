@import 'src/styles/theme/config';

.settings-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-label {
    margin-right: 30px;
    color: var(--text-color-light);

    &-bold {
      color: var(--text-color);
      font-weight: 700;
    }
  }
}
