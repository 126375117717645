@import 'src/styles/theme/config';

.internal-partner {
  &-section {
    margin-bottom: 30px;

    &-title {
      font-size: 17px;
      font-weight: 700;
      font-family: $serif-font;
      color: var(--text-color);
      margin-bottom: 10px;
    }
  }
}
