@import 'src/styles/theme/config';

.company-list {
  &-item {
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 5px;
    border: 1px solid transparent;

    &:hover,
    &.active {
      background-color: var(--background-color-dark);
      border-color: var(--border-color);

      .company-logo-spacer {
        border-color: var(--border-color) !important;
      }
    }

    .title {
      line-height: 1;
    }
  }
}
