@import 'src/styles/theme/config';

.cogs-progress {
  display: flex;
  flex-direction: column;
  width: 270px;

  &-full {
    width: 100%;
  }

  &-info {
    font-weight: 500;
    line-height: 18.2px;
    color: var(--text-color-lighter);
    margin-bottom: 5px;

    strong {
      color: var(--text-color);
    }
  }
}
