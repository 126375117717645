@import 'src/styles/theme/config';

.insight-graphic {
  &-slider-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--background-color);
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &-left {
      left: -20px;
      top: 45%;
    }

    &-right {
      right: -20px;
      top: 45%;
    }
  }

  &-slider-indicator-wrapper {
    position: absolute;
    padding: 30px;
    width: 100%;
    top: 100%;
  }

  .insight-data-table {
    $dt: &;
    min-height: 340px;
    border-radius: 0 0 20px 20px;
    overflow: hidden;

    &-row {
      display: flex;

      &:nth-child(even) {
        background-color: var(--background-color-dark);
      }

      &:last-child {
        #{$dt}-column {
          border-bottom: 0;
        }

        .insight-data-table-column {
          border-bottom: 0;
        }
      }
    }

    &-column {
      padding: 15px 20px;
      border-bottom: 1px solid var(--border-color);
      display: flex;
      align-items: center;

      &-s {
        width: 120px;
      }

      &-with-icon {
        display: flex;
        align-items: center;

        &-img {
          display: flex;
          align-items: center;
          width: 16px;
          margin-right: 10px;

          img {
            width: 16px;
          }
        }
      }
    }

    &-tooltip {
      width: 300px;
      height: 100px;
      padding: 10px 15px;
      background-color: #fff;
      border: 1px solid #f2f2f4;
      box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }

  .insight-chart {
    &-title {
      text-align: left;
      font-size: 17px;
      font-weight: 700;

      small {
        color: var(--text-color-lighter);
      }
    }

    &-subtitle {
      text-align: left;
      color: var(--text-color-light);
      font-size: 13px;
    }

    &-icon {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-legend {
      display: flex;
      padding: 30px;

      &-item {
        display: flex;
        flex-direction: column;
        margin-right: 45px;

        &-value {
          font-size: 20px;
          font-weight: 600;
        }

        &-title {
          color: var(--text-color-light);
        }
      }
    }

    &-tooltip-title {
      max-width: 250px;
      margin-bottom: -10px;
      white-space: normal;
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 10;
      overflow: hidden;
    }

    &-content {
      padding: 0 30px 30px;
      display: flex;
      align-items: center;
      width: 100%;

      &-item {
        position: relative;
        width: calc(50% - 46px);

        &-info {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;

          &-value {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          &-title {
            color: var(--text-color-light);
          }
        }
      }
    }
  }
}

.theme-dark {
  .insight-graphic {
    .insight-data-table {
      &-row {
        &:nth-child(even) {
          background-color: var(--background-color-darker);
        }
      }
    }
  }
}
