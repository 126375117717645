@import 'src/styles/theme/config';

.email-setup-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  &-inner {
    min-width: 50%;
    padding: 100px 0;
  }

  &-title {
    font-size: 20px;
    font-family: $serif-font;
    font-weight: 700;
  }

  &-description {
    color: var(--text-color-light);
    margin-bottom: 30px;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 1px solid var(--border-color);
    margin-top: 60px;
  }
}
