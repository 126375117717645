@import 'src/styles/theme/config';

.pricing-slider {
  --slider-size-multiplier: 1;
  --slider-size: calc(40px * var(--slider-size-multiplier));

  &-plan-change {
    --slider-size-multiplier: 0.7;
  }

  &-homepage {
    --slider-size-multiplier: 1;
  }

  &-component {
    height: var(--slider-size);
  }

  &-thumb {
    width: var(--slider-size);
    height: var(--slider-size);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: calc(var(--slider-size) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    cursor: grab;
    // created a variable, otherwise scss compiler throws an error about wrong syntax
    --translate-x: calc(var(--slider-size) * #{-0.375});
    transform: translate(0, var(--translate-x));
    position: relative;
    outline: none;
    background-color: var(--primary-color-500);
    border: calc(var(--slider-size) * 0.3) solid #fff;
  }

  &-track {
    background-color: var(--primary-color-500);
    height: calc(var(--slider-size) * 0.25);
    border-radius: calc(var(--slider-size) / 2);
    margin: 0 calc(var(--slider-size) * 0.25) 0 0;

    &-1 {
      background-color: #e7dbd5;
    }
  }

  &-input-wrapper {
    text-align: left;
  }

  &-slider-price {
    font-size: 2.4rem;
    margin-top: 50px;
  }

  &-slider-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0;

    &-price {
      font-size: 70px;
      font-weight: 700;
      line-height: 1.2;

      &-small {
        font-size: 20px;
      }
    }
  }
}
