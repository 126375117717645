@import 'src/styles/theme/config';

.partner-logo-config {
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin: 30px;

  &-setup {
    display: flex;
    flex-direction: column;
    flex: 1 1 66.6666%;
    padding: 45px;

    &-row {
      margin-bottom: 30px;

      &-options {
        display: flex;
      }
    }

    &-radio-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      width: 100%;
      color: var(--text-color-lighter);
      margin-right: 15px;
      padding: 15px;
      cursor: pointer;

      svg {
        color: rgba($brand-primary, 0.2);
        margin-right: 10px;
      }

      &.active {
        border-color: var(--primary-color-500);
        color: var(--text-color);

        svg {
          color: var(--text-color);
        }
      }
    }
  }

  &-embed-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    font-family: Courier;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.65px;
    text-align: left;

    &-html {
      padding: 15px;
      border-right: 1px solid var(--border-color);
      white-space: pre-wrap;
      width: 100%;
    }

    &-copy {
      padding: 0 15px;
      height: 100%;
      cursor: pointer;
    }
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 33.3333%;
    padding: 45px;
    border-left: 1px solid var(--border-color);

    &-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
      width: 256px;
      height: 256px;
      border-radius: 10px;
      background-color: var(--border-color);

      &.dark {
        background-color: var(--primary-color-500);
      }

      img {
        max-width: 100%;
        max-height: 150px;
      }
    }
  }

  &-title {
    font-family: $serif-font;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.14px;
    margin-bottom: 10px;
  }

  &-subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
    color: var(--text-color-light);
    margin-bottom: 30px;
  }

  &-section-title {
    font-weight: 700;
    font-size: 11px;
    color: var(--text-color-light);
    margin-bottom: 10px;
  }
}
