.segment-data-unavailable {
  &-title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &-analytics-checkbox {
    display: flex;
    margin-bottom: 0;
  }
}
