@import 'src/styles/theme/config';

.segment-usage-progress {
  display: flex;
  align-items: flex-end;

  &-title {
    font-size: 11px;
    font-weight: 700;
    display: flex;
    align-items: center;

    .status-indicator-pill {
      width: 10px;
      height: 10px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &-value {
    font-size: 11px;
    font-weight: 700;
    margin-left: 20px;
  }

  &-bar {
    margin-top: 5px;
    background-color: var(--background-color-darker);
    border: 1px solid var(--border-color);
    padding: 2px;
    border-radius: 10px;
    min-width: 250px;

    &-inner {
      background-color: var(--success-color);
      height: 6px;
      min-width: 6px;
      max-width: 100%;
      border-radius: 6px;

      &.full {
        background-color: var(--warning-color);
      }
    }
  }

  &-link {
    color: var(--text-color-light);
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-decoration: underline;
    margin-left: 25px;
  }
}
