//Mixins
@import 'theme/mixins/font-size';
@import 'custom/mixins';

//Config variables
@import 'theme/config';

//Themes
@import 'theme/themes';

//Bootstrap lib
@import 'bootstrap/bootstrap';
@import 'theme/config-after';

//Fonts
@import 'theme/fonts/lato';
@import 'theme/fonts/serif';

@import 'theme/pages/404';

//Bootstrap elements
@import 'theme/bootstrap/mixins/buttons';
@import 'theme/bootstrap/mixins/modals';
@import 'theme/bootstrap/mixins/labels';
@import 'theme/bootstrap/grid';
@import 'theme/bootstrap/code';
@import 'theme/bootstrap/buttons';
@import 'theme/bootstrap/modals';
@import 'theme/bootstrap/labels';
@import 'theme/bootstrap/type';
@import 'theme/bootstrap/wells';
@import 'theme/bootstrap/forms';

//Helper classes
@import 'theme/helpers';
@import 'custom/animations';
@import 'custom/datepicker';
@import 'custom/company-list';
@import 'custom/company-selector';
@import 'custom/company-logo';
@import 'custom/tooltip';
@import 'custom/onboarding';
@import 'custom/swal';
@import 'custom/notifications';
@import 'custom/modals';

.disable-body-scroll {
  overflow: hidden;
}

html,
body {
  min-height: 100%;
}

body {
  min-width: 1200px;
  color: var(--text-color);
  background-color: #f7f7f9;

  &.page-responsive {
    min-width: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

#viewport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.pos-rel {
  position: relative !important;
}
.pos-abs {
  position: absolute !important;
}
.pos-fix {
  position: fixed !important;
}

.dis-f {
  display: flex !important;
}

.dis-if {
  display: inline-flex !important;
}

.dis-b {
  display: block !important;
}

.dis-ib {
  display: inline-block !important;
}

.dis-i {
  display: inline !important;
}

.dis-n {
  display: none !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-dir-col {
  flex-direction: column !important;
}

.flex-dir-row {
  flex-direction: row !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-center {
  align-items: center !important;
}

.items-end {
  align-items: flex-end !important;
}

.self-start {
  align-self: start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: end;
}

.content-start {
  justify-content: flex-start !important;
}

.content-center {
  justify-content: center !important;
}

.content-end {
  justify-content: flex-end !important;
}

.content-between {
  justify-content: space-between !important;
}

.align-self-center {
  align-self: center;
}

.flex0 {
  flex: 0;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-ellipsis {
  @include text-overflow();
}

.text-weight-normal {
  font-weight: 400;
}

.ws-normal {
  white-space: normal;
}

.ws-nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

label {
  color: inherit;
}

.field-error {
  font-size: 11px;
  margin-left: 6px;
  display: inline-block;
}

.field-message {
  font-size: 11px;
  margin-left: 6px;
  margin-top: 3px;
  color: var(--text-color-light);
  word-break: break-all;
}

.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  color: var(--text-color-lighter);

  &:before {
    content: '';
    background-color: var(--background-color);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &-text {
    margin-top: 15px;
    font-size: 11px;
  }

  &-logo {
    width: 200px;
  }

  @keyframes hide {
    0% {
      visibility: visible;
    }
    99% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes fade-out {
    0% {
      visibility: visible;
      opacity: 1;
    }
    70% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  &.fade-out {
    animation: hide 0.9s;
    visibility: hidden;

    &:before {
      animation: fade-out 0.9s ease-out;
      opacity: 0;
      visibility: hidden;
    }

    .loading-screen-logo {
      animation: scale-out 0.9s cubic-bezier(0.76, -0.63, 0.09, 1.09);
      transform: scale(0);
    }
  }
}

.no-company-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  color: #2e294e;
  padding-left: 150px;

  .logo {
    height: 40px;
  }

  h1 {
    font-size: 40px;
    max-width: 500px;
    margin-top: 100px;
    margin-bottom: 40px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
  }
}

.tier-container {
  &-title {
    font-size: 32px;
    font-family: $serif-font;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 30px;
    line-height: 1.2;
  }

  &-subtitle {
    font-size: 15px;
    margin-bottom: 60px;
  }
}

small.title {
  color: var(--text-color-light);
  line-height: 25px;
  font-weight: 700;
  font-size: 11px;
}

.font-serif {
  font-family: $serif-font;
}

.font-size-small {
  font-size: $font-size-small;
}

.font-size-extra-small {
  font-size: $font-size-extra-small;
}

.font-size-0 {
  font-size: 0 !important;
}

.aero {
  line-height: 1.8;
}

.va-top {
  vertical-align: top !important;
}

.va-middle {
  vertical-align: middle !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.f-12 {
  font-size: 12px !important;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

.rotate-90deg {
  transform: rotate(90deg);
}

.btn {
  &.btn-icon {
    font-size: 0 !important;
  }

  &.btn-icon-only {
    // TODO add styles instead of &nbsp;&nbsp;<Icon/>&nbsp;&nbsp;
  }

  &-xs {
    &.btn-with-icon {
      svg,
      img {
        width: 12px;
        height: 12px;
      }
    }

    &.btn-icon {
      svg {
        margin: 0;
      }
    }
  }

  &-xl {
    &.btn-with-icon {
      svg,
      img {
        margin-right: 12px;
      }

      &-right {
        svg,
        img {
          margin-left: 12px;
          margin-right: 0;
        }
      }
    }

    &.btn-icon {
      svg {
        margin: 0;
      }
    }
  }

  &-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &-right {
      svg,
      img {
        margin-left: 8px;
        margin-right: 0;
      }
    }

    &.btn-icon {
      svg {
        margin: 0;
      }
    }
  }

  &.with-loader {
    &:after {
      border-color: #fff !important;
      border-right-color: transparent !important;
      margin-left: 10px !important;
      margin-top: 6px !important;
      box-shadow: none !important;
    }
  }
}

.btn-shopify {
  img {
    height: 55px;
  }
}

.styled-file-input {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

ul.legal {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    padding-left: 1em;
    text-indent: -1em;
    margin-bottom: 0.5em;
  }

  li:before {
    content: '§';
    padding-right: 5px;
  }
}

select.form-control {
  padding-right: 30px;
  appearance: none;
  background-image: url('data:image/svg+xml, <svg height="12" width="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><g fill="%23AAB7C5"><path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" fill="%23AAB7C5"/></g></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;

  option[value=''][disabled] {
    color: #777;
  }
}

.form-control.rounded {
  border-radius: 30px;
}

.content-container {
  padding: 25px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  border-radius: 20px;
  min-height: 80px;
  border: 1px solid var(--border-color);
}

.setup-container {
  padding: 60px;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.05);
  font-size: 15px;
  max-width: 800px;
}

.echart-tooltip {
  padding: 15px 20px 20px;
  color: var(--text-color);
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(25px);
  background-color: rgba($grayscale-100, 0.72);

  &-image {
    height: 150px;
    border-radius: 8px;
    margin: 5px 0;
    margin-left: 30px;
  }

  &-title {
    font-size: 15px;
    margin-bottom: 5px;
    line-height: 1.6;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-divider {
    border-color: var(--primary-color-500);
    margin: 0 0 10px;
  }

  &-bullet {
    display: inline-block;
    min-width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 10px;
    margin-top: -1px;
  }
}

.ribbon {
  width: 140px;
  height: 140px;
  overflow: hidden;
  position: absolute;

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid darken($brand-secondary, 20%);
  }

  & span {
    position: absolute;
    display: block;
    width: 205px;
    padding: 10px 0;
    background-color: $brand-secondary;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
  }

  &-top-left {
    top: -10px;
    left: -10px;

    &:before,
    &:after {
      border-top-color: transparent;
      border-left-color: transparent;
    }
    &:before {
      top: 0;
      right: 0;
    }
    &:after {
      bottom: 0;
      left: 0;
    }
    & span {
      right: -25px;
      top: 30px;
      transform: rotate(-45deg);
    }
  }

  &-top-right {
    top: -10px;
    right: -10px;

    &:before,
    &:after {
      border-top-color: transparent;
      border-right-color: transparent;
    }
    &:before {
      top: 0;
      left: 0;
    }
    &:after {
      bottom: 0;
      right: 0;
    }
    & span {
      left: -20px;
      top: 37px;
      transform: rotate(45deg);
    }
  }

  &-xs {
    width: 90px;
    height: 90px;

    & span {
      font-size: 11px;
      width: 140px;
      padding: 7px 0;
      text-transform: none;
    }

    &.ribbon-top-right {
      & span {
        left: -15px;
        top: 20px;
      }
    }
  }
}

.with-custom-scrollbar {
  &::-webkit-scrollbar {
    width: 17px;
  }

  &-vertical {
    &::-webkit-scrollbar {
      border-left: 1px solid var(--border-color);
    }
  }

  &-horizontal {
    &::-webkit-scrollbar {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
    border-radius: 20px;
    border: 3px solid transparent;
    border-left-width: 4px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-color-lighter);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.content-divider {
  background: var(--border-color);

  &-horizontal {
    height: 1px;
    width: 100%;
  }

  &-vertical {
    height: 100%;
    width: 1px;
  }
}

.button-reset {
  all: unset;
  display: inline-block;
  cursor: pointer;

  &-block {
    width: 100% !important;
  }
}

.theme-dark {
  .echart-tooltip {
    background-color: rgba($grayscale-700, 0.8);
  }
}
