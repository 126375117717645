@import 'src/styles/theme/config';

.general {
  display: flex;
  margin: 0 -15px;

  .main-content-section {
    padding: 0;
    display: block;
    min-height: 70px;
    margin: 0 15px;
    padding: 5px 0;
    flex: 1;
  }

  &-section {
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    padding: 20px 0;
    margin: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
    }

    &-success {
      color: var(--success-color);
    }

    &-warning {
      color: var(--warning-color);
    }

    &-danger {
      color: var(--danger-color);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
