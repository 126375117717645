@import 'src/styles/theme/config';

.page-restricted-with-header {
  $page: &;

  &.width-sm {
    #{$page}-container {
      width: 700px;
    }
  }

  &-content {
    background-color: var(--background-color);
    border-radius: 30px;
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);
    padding: 45px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &-fill {
      min-height: calc(100vh - 160px);
    }
  }

  &-title {
    font-size: 24px;
    font-family: $serif-font;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &-description {
    font-size: 15px;

    &-note {
      font-size: 11px;
    }
  }
}

.theme-dark {
  .page-restricted-with-header {
    &-content {
      background-color: var(--background-color-darker);
    }
  }
}
