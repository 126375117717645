@import 'src/styles/theme/config';

.cohort-analysis-insight-cohort-item {
  border-radius: 15px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
