@import 'src/styles/theme/config';

.topbar-banner {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f5ff;

  &-title {
    font-weight: 700;
    margin-bottom: 5px;
  }

  &-icon {
    height: 30px;
    margin-right: 15px;
    transform: scaleX(-1);
  }

  &-actions {
    display: flex;
    align-items: center;
  }

  &-close {
    display: flex;
    margin: 0 10px 0 30px;
    cursor: pointer;

    svg {
      transition: 0.2s;
    }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.theme-dark {
  .topbar-banner {
    background-color: var(--background-color-darker);
  }
}
