@import 'src/styles/theme/config';

.goal-modal {
  &-title {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
