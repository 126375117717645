@import 'src/styles/theme/config';

.grid-report-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-report {
  padding: 10px 25px 60px;
  min-height: 400px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;

  &-widget {
    padding-bottom: 30px;
  }

  &-compare {
    padding: 10px 24px 60px;
  }

  &-item {
    min-width: 0;
    background-color: var(--background-color);
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    &-widget {
      border: none;
      border-radius: 0;
    }

    &-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.5s ease;

      &-loaded {
        opacity: 1;
      }

      &-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-widget {
        height: 128px;
        object-fit: contain;
      }

      &-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: var(--border-color);
      }
    }

    &-data {
      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        padding: 10px;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.6px;

        &-export {
          overflow-wrap: break-word;
          min-height: 54px;
        }

        &-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-tooltip {
          max-width: 300px;
          padding: 10px 15px;
          background-color: var(--background-color);
          border: 1px solid var(--border-color);
          box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }

      &-metrics {
        padding: 10px;
        border-top: 1px solid var(--border-color);

        &-widget {
          border: none;
          padding: 0;
        }

        &-label {
          font-size: 13px;
          font-weight: 700;
          line-height: 13.2px;
          color: var(--text-color-light);
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        &-value {
          font-size: 15px;
          font-weight: 700;
          line-height: 15.6px;
        }
      }
    }
  }

  &-no-data {
    grid-template-columns: repeat(1, 1fr);

    &-message {
      display: flex;
      justify-content: center;
      color: var(--text-color-light);
    }
  }
}

.theme-dark {
  .grid-report {
    &-item {
      background-color: var(--background-color-darker);

      &-widget-export {
        background-color: var(--background-color);
      }

      &-data {
        &-title {
          &-tooltip {
            background-color: var(--background-color-darker);
            border: 1px solid var(--border-color);
          }
        }
      }
    }
  }
}

@media (max-width: 2200px) {
  .grid-report {
    &-compare {
      &-active {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-no-data {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
