@import 'src/styles/theme/config';

.imports-data-feed-wrapper {
  max-width: 500px;

  &-error-message {
    color: var(--danger-color);
    margin-bottom: 20px;
  }
}
