@import 'src/styles/theme/config';

.page-segment {
  .section-title {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color-light);
    padding: 0 0 15px;
    font-size: 11px;
    letter-spacing: 0.5px;
  }

  .segment-validation-error {
    color: var(--danger-color);
    font-size: 11px;
    margin-top: 4px;
  }

  .analytics-segment {
    background-color: var(--background-color);
    border-radius: 15px;
    border: 1px solid var(--border-color);
    padding: 20px;
    display: flex;
    align-items: center;
    opacity: 0.6;

    &.active {
      opacity: 1;
    }

    &-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-weight: 700;
      margin-left: 10px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &-usage {
      font-weight: 700;
    }
  }
}

.theme-dark {
  .page-segment {
    .analytics-segment {
      background-color: var(--background-color-darker);
    }
  }
}
