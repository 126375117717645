@import 'src/styles/theme/config';

.internal-search {
  display: flex;
  align-items: center;

  &-input {
    min-width: 300px;
    margin-bottom: 0 !important;

    input {
      border: none;
    }
  }
}
