@import 'src/styles/theme/config';

.report-section-list {
  $rsl: &;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color-light);
    padding: 15px 30px;
    font-size: 11px;
    letter-spacing: 0.5px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-item {
    $rsl-item: &;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: var(--background-color-darker);
    }

    &:hover {
      #{$rsl-item}-arrow {
        transform: translateX(10px);
        opacity: 1;
      }
    }

    &.disabled {
      cursor: default;

      #{$rsl-item}-title {
        color: var(--text-color-light);
      }

      #{$rsl-item}-description,
      #{$rsl-item}-contact-owner {
        color: var(--text-color-lighter);
      }

      #{$rsl-item}-image {
        opacity: 0.4;
      }
    }

    &-icon {
      background-color: var(--background-color);
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.16);
      border-radius: 15px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
    }

    &-image {
      width: 36px;
    }

    &-title {
      font-weight: 600;
    }

    &-description {
      color: var(--text-color-light);
    }

    &-arrow {
      margin-left: 10px;
      opacity: 0;
      transition: 0.15s;
      display: flex;
    }
  }

  &-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.theme-dark {
  .report-section-list {
    &-item {
      &-icon {
        background-color: var(--background-color-darkest);
      }
    }
  }
}
