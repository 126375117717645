@import 'src/styles/theme/config';

@mixin remove-btn($is-dark: false) {
  $btn-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: $btn-size * (-0.3);
  right: $btn-size * (-0.3);
  width: $btn-size;
  height: $btn-size;
  border-radius: $btn-size;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);

  // Default colors
  background-color: var(--background-color);
  color: var(--danger-color);

  @if $is-dark {
    background-color: var(--background-color-darkest);
  }

  transition: 0.05s;
  cursor: pointer;

  &:hover {
    background-color: var(--danger-color);
    color: #fff;
  }
}
