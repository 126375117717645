@import 'src/styles/theme/config';

.impersonating-console {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  z-index: 10000;
  background-color: $brand-secondary;
  border-top-left-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}
