@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.report-info {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--text-color);
  z-index: 1;

  &-dropdown {
    @include dropdown(bottom, right);
    top: calc(100% + 15px);
    right: -15px;
    width: 550px;
    border-radius: 20px;
    z-index: 101;

    .dropdown-inner {
      padding: 30px !important;
    }

    &.right {
      @include dropdown(bottom, left);
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-size: 20px;
    font-family: $serif-font;
    font-weight: 700;
  }

  &-close {
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {
      transform: rotate(-90deg);
    }
  }

  &-tabbar {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--background-color);
    padding: 0 15px;
    margin: 15px -30px 30px;

    &-item {
      padding: 10px 15px;
      display: block;
      color: var(--text-color-lighter);
      font-weight: 600;
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        left: 15px;
        right: 15px;
        bottom: 0;
        border-radius: 2px 2px 0 0;
        background-color: var(--text-color-lighter);
      }

      &:hover {
        color: var(--text-color);

        &:after {
          background-color: var(--text-color);
        }
      }

      &.active {
        color: var(--secondary-color-500);

        &:after {
          background-color: var(--secondary-color-500);
        }
      }
    }
  }

  &-content {
    min-height: 100px;
  }

  &-help-center-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
    border-radius: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);

    &:hover {
      background-color: var(--background-color-dark);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-text {
    font-size: 15px;
    margin-top: 10px;
  }

  &-guides {
    display: flex;
    margin: 0 -5px;
  }

  &-guide {
    flex: 0 0 33.3333%;
    padding: 0 5px;

    img {
      max-width: 100%;
      transition: 0.15s;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .tooltip {
      top: 50%;
    }

    &:hover {
      img {
        transform: scale(1.05);
        box-shadow: 0 10px 5px -3px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .tooltip {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.theme-dark {
  .report-info {
    &-dropdown {
      @include dropdown(bottom, right, true);

      &.right {
        @include dropdown(bottom, left, true);
      }
    }

    &-tabbar {
      background-color: var(--background-color-darker);
    }
  }
}
