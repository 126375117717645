@import 'src/styles/theme/config';

.collection-settings {
  display: flex;
  align-items: center;
  flex-direction: column;

  &-item {
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid var(--border-color);
    opacity: 0.6;

    &-active {
      opacity: 1;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;

      &-label {
        font-weight: 700;
        margin-left: 10px;
      }

      .checkbox {
        margin: 0;
      }
    }

    &-field {
      display: flex;
      margin-top: 10px;
      margin-left: 26px;

      &-input {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;

        &-disabled {
          pointer-events: none;
        }
      }
    }
  }

  &-error-message {
    color: var(--danger-color);
    margin-top: 15px;
    margin-left: 26px;
  }
}
