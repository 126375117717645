@import 'src/styles/theme/config';

.dropdown-button {
  $db: &;
  position: relative;

  &.active {
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: -5px;
      right: 0;
      bottom: -5px;
      left: 0;
    }

    #{$db}-dropdown {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &-dropdown {
    transition: 0.1s;
    transform: translate(0, -10px);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    min-width: 150px;
    top: calc(100% + 5px);
    right: 0;
    border-radius: 15px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    z-index: 1;
    padding: 5px 0;
    overflow: hidden;

    & > * {
      padding: 10px 20px;
      transition: 0.1s;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--text-color-light);
      white-space: nowrap;
      font-weight: 500;

      &:not(.disabled):hover {
        background-color: var(--background-color-dark);
        color: var(--text-color);
      }

      &.disabled {
        color: $dropdown-link-disabled-color;
        cursor: $cursor-disabled;
      }

      svg {
        margin-right: 15px;
      }

      img {
        margin-right: 15px;
        width: 20px;
      }
    }

    hr {
      padding: 0;
      margin: 5px 0;
      border-color: var(--border-color);
    }
  }
}

.theme-dark {
  .dropdown-button {
    &-dropdown {
      background-color: var(--background-color-darker);

      & > * {
        &:not(.disabled):hover {
          background-color: var(--background-color-darkest);
        }
      }
    }
  }
}
