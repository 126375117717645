@import 'src/styles/theme/config';

.csv-export-settings {
  &-type {
    display: flex;
    flex-direction: column;

    &-option {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--border-color);
      border-radius: 15px;
      margin-bottom: 15px;
      overflow: hidden;
      cursor: pointer;

      &-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background-color: var(--background-color-dark);

        &-item {
          display: flex;
          align-items: center;
        }

        &-all-toggle {
          display: flex;
          align-items: center;

          button {
            height: 20px;
            padding: 0;
            line-height: inherit;
          }
        }
      }

      &-config {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          font-weight: 700;
          border-top: 1px solid var(--border-color);
          padding: 15px 15px 15px 30px;

          &:nth-child(odd) {
            border-right: 1px solid var(--border-color);
          }
        }
      }

      &-status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        background-color: var(--background-color);

        & > .checked {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: var(--background-color-inverted);
        }
      }

      &-label {
        font-weight: 700;
        margin-left: 15px;
      }
    }
  }
}
