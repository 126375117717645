@import 'src/styles/theme/config';

.segment-list {
  margin-bottom: 70px;

  &-title {
    font-family: $serif-font;
    font-weight: 700;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.segment-table {
  $st: &;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
}
