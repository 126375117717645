@import 'src/styles/theme/config';

.page-app {
  &-container {
    margin-top: $topbar-height;
    margin-left: $sidebar-width;
    min-height: calc(100vh - $topbar-height);
    display: flex;
    flex: 1;
    flex-direction: column;

    &-with-banner {
      margin-top: $topbar-with-banner-height;
      min-height: calc(100vh - $topbar-with-banner-height);

      .page-app-content-nav-inner {
        top: calc(30px + #{$topbar-with-banner-height}) !important;
      }
    }
  }

  &-bg-white {
    background-color: var(--background-color);
  }

  &-content {
    position: relative;
    padding: 30px;

    &-full {
      padding: 0;
      min-height: calc(100vh - $topbar-height);
    }

    &-fill {
      min-height: 100vh;
    }

    &-with-nav {
      display: flex;
    }

    &-nav {
      $nav: &;
      width: 300px;
      min-width: 250px;
      padding: 30px;
      border-right: 1px solid var(--border-color);
      background-color: var(--background-color);

      &-sticky {
        #{$nav}-inner {
          position: sticky;
          top: calc(30px + #{$topbar-height});
        }
      }

      &-item {
        $item: &;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text-color-light);
        font-weight: 600;
        border-radius: 30px;
        margin-bottom: 15px;
        transition: 0.15s;

        &:hover {
          color: var(--text-color);
          background-color: var(--background-color-darker);
        }

        &.active {
          color: var(--text-color-inverted);
          background-color: var(--text-color);

          #{$item}-icon {
            display: none;
          }

          #{$item}-icon-inverted {
            display: inline-block;
          }

          #{$item}-warning-icon {
            color: var(--text-color-inverted);
          }
        }

        &.disabled {
          color: var(--text-color-lighter);

          &:hover {
            background-color: transparent;
          }
        }

        &.danger {
          color: var(--danger-color);
        }

        &.danger.active {
          color: #fff;
          background-color: var(--danger-color);
        }

        &-icons {
          display: flex;
          align-items: center;
        }

        &-warning-icon {
          display: flex;
          color: var(--warning-color);

          svg {
            margin-right: 5px;
          }
        }

        &-icon,
        &-icon-inverted {
          width: 16px;
          max-height: 16px;
          margin-right: 10px;
        }

        &-icon-inverted {
          display: none;
        }
      }
    }

    &-with-tabbar {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-tabbar {
      display: flex;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--background-color);
      padding: 0 30px 0 15px;

      &-item {
        padding: 20px 15px;
        display: block;
        color: var(--text-color-lighter);
        font-weight: 600;
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 3px;
          left: 15px;
          right: 15px;
          bottom: 0;
          border-radius: 2px 2px 0 0;
          background-color: var(--grayscale-400);
        }

        &:hover {
          color: var(--text-color);

          &:after {
            background-color: var(--background-color-inverted);
          }
        }

        &.active {
          color: var(--text-color);

          &:after {
            background-color: var(--background-color-inverted);
          }
        }
      }

      &-action {
        padding: 20px 15px;
        display: block;
        color: var(--text-color);
        font-weight: 600;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: var(--background-color-dark);
        }

        &:after {
          content: none;
        }

        &.danger {
          color: var(--danger-color-500);

          &:hover {
            background-color: var(--danger-color-200);
          }
        }

        &.disabled {
          color: $dropdown-link-disabled-color;
          cursor: $cursor-disabled;

          &:hover {
            background-color: unset;
          }
        }
      }
    }

    &-switch {
      flex: 1;
      padding: 30px;

      &-full {
        padding: 0;
      }

      &-bg-white {
        background-color: var(--background-color);
      }
    }
  }

  &-with-nav-section {
    padding: 45px;

    &-header {
      margin-bottom: 45px;
    }

    &-title {
      font-size: 24px;
      font-family: $serif-font;
      margin-bottom: 15px;
      font-weight: 700;
    }

    &-description {
      color: var(--text-color-light);
    }

    &-divider {
      height: 1px;
      background-color: var(--border-color);
    }
  }

  &-hero {
    padding: 45px;
    border-radius: 20px;
    background-color: var(--background-color);
    margin-bottom: 30px;
    position: relative;
    display: flex;
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);

    &-full {
      border-radius: 0;
      box-shadow: none;
    }

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    &-left {
      padding-right: 30px;
    }

    &-right {
      position: relative;
    }

    &-image {
      position: absolute;
      max-width: 100%;
      max-height: calc(100% + 60px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.bottom {
        top: auto;
        bottom: -45px;
        transform: translate(-50%, 0);
      }
    }

    &-title {
      font-size: 24px;
      font-family: $serif-font;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 15px;
    }

    &-description {
      color: var(--text-color-light);
      line-height: 1.7;
      font-size: 15px;
      margin-bottom: 60px;
    }
  }
}

.theme-dark {
  .page-app {
    &-hero {
      background-color: var(--background-color-darker);
    }

    &-content {
      &-tabbar {
        background-color: var(--background-color-darker);

        &-item {
          &:after {
            background-color: var(--primary-color-400);
          }

          &:hover {
            &:after {
              background-color: var(--background-color-inverted);
            }
          }

          &.active {
            &:after {
              background-color: var(--background-color-inverted);
            }
          }

          &-action {
            &:hover {
              background-color: var(--background-color-darkest);
            }

            &.danger {
              &:hover {
                background-color: var(--danger-color-200);
              }
            }

            &.disabled {
              &:hover {
                background-color: unset;
              }
            }
          }
        }

        &-switch {
          &-bg-white {
            background-color: var(--background-color-dark);
          }
        }
      }

      &-nav {
        &-item {
          &.disabled {
            color: var(--text-color-lightest);

            &:hover {
              background-color: transparent;
            }
          }

          &-icon-inverted.hidden-dark {
            display: none;
          }
        }
      }
    }
  }
}

.theme-light {
  .page-app {
    &-content {
      &-nav {
        &-item {
          &-icon-inverted.hidden-light {
            display: none;
          }
        }
      }
    }
  }
}
