@import 'src/styles/theme/config';

.insight-card {
  $ic: &;
  $reveal-duration: 800ms;

  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 30px;
  box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  position: relative;
  transform: scale(0.9);
  transition: $reveal-duration cubic-bezier(0.34, 1.56, 0.64, 1);

  &.opened {
    transform: scale(1);

    #{$ic}-graphic {
      opacity: 1;
      visibility: visible;
    }
  }

  &-body {
    display: flex;

    &-left {
      width: 33.3333%;
      padding: 30px 0 45px 35px;
    }

    &-right {
      width: 66.6666%;
      padding: 30px 30px 45px 15px;

      &-with-slider {
        padding: 30px 30px 90px 15px;
      }
    }
  }

  &-impact {
    display: flex;
    flex-direction: column;
    font-weight: 700;

    &-value {
      font-size: 40px;
      display: flex;
      line-height: 1.1;
      margin: 5px 0;
    }

    &-label {
      font-size: 12px;
      max-width: 130px;
      margin-top: 5px;
      color: var(--text-color-light);
      line-height: 1.2;
    }
  }

  &-description {
    margin-top: 45px;
    font-size: 15px;
  }

  &-graphic-wrapper {
    position: relative;
  }

  &-graphic {
    opacity: 0;
    visibility: hidden;
    background-color: var(--background-color);
    border-radius: 20px;

    transform: perspective(800px) rotateY(-8deg);
    transition: 0.5s $smooth-easing 0s;
    box-shadow: rgba(20, 20, 20, 0.15) 0px 5px 15px;

    &:hover {
      transform: rotateY(0deg) scale(1.1);
      box-shadow: rgba(20, 20, 20, 0.25) 0px 20px 40px 0px;
    }
  }

  &-graphic-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-footer {
    padding: 0 45px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-categories {
    display: flex;
    align-items: center;
  }

  &-category {
    padding: 10px 15px;
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    color: var(--secondary-color-900);
    background-color: var(--secondary-color-100);
  }

  &-actions {
    display: flex;
  }

  &-progress-bar {
    height: 5px;
    background-color: var(--border-color);
    border-radius: 5px;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    &-content {
      width: 5px;
      height: 100%;
      border-radius: 5px;
      background-color: var(--text-color-light);
    }
  }

  &-overlay {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    background-size: 350px;
    background-position: center;
    transform: translate3d(0, 0, 0);

    &:before {
      content: '';
      position: absolute;
      background-color: rgba(var(--tertiary-color-rgb), 0.05);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 30px;
      z-index: -1;
    }
  }
}

@media (max-width: 1500px) {
  .insight-card {
    width: 1000px;
  }
}

.theme-dark {
  .insight-card {
    background-color: var(--background-color-darker);

    &-graphic {
      background-color: var(--background-color-darkest);
    }

    &-overlay {
      background-color: var(--background-color-darkest);
    }
  }
}
