@import 'src/styles/theme/config';

.intergations-automations-list {
  &-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 1.7;
    letter-spacing: 1.5px;
    color: var(--text-color-light);
  }

  &-subtitle {
    width: 50%;
    margin-top: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.7;
    color: var(--text-color-light);
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -22px;
  }
}
