@import 'src/styles/theme/config';

.header-options {
  $ho: &;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 320px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
  border-radius: 0 15px 15px 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  text-align: left;

  &-right {
    left: auto;
    right: 0;
    border-radius: 10px 0 10px 10px;
  }

  &-action {
    padding: 5px;
    border-radius: 15px;
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: var(--background-color-darkest);
    }

    &.active {
      background-color: var(--background-color-inverted);
      color: var(--text-color-inverted);
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-color);

    &-title {
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--text-color-light);
      font-weight: 700;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.theme-dark {
  .header-options {
    background-color: var(--background-color-darker);
  }
}
