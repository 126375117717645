@import 'src/styles/theme/config';

.timezones-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  padding: 20px 25px;
  color: var(--text-color);
  border-radius: 20px;
  white-space: normal;
  width: 300px;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 10000;
  transition: 0.1s;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &-title {
    font-size: 15px;
    line-height: 1.6;
  }

  &-bullet {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 10px;
  }

  &-content-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &-time {
      font-size: 9px;
      color: var(--text-color-lighter);
    }
  }
}
