@import 'src/styles/theme/config';

.report-list {
  &-group {
    padding: 15px 0;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }

    &-title {
      font-weight: 600;
      text-transform: uppercase;
      color: var(--text-color-light);
      padding: 15px 30px;
      font-size: 11px;
      letter-spacing: 0.5px;

      &-collapsible {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;

    &.active,
    &:hover {
      background-color: var(--background-color-darker);
    }

    &-locked {
      color: #ccc;

      &.active,
      &:hover {
        background-color: transparent;
      }
    }
  }
}
