@import 'src/styles/theme/config';

.partner-whitepapers {
  &-section {
    padding: 45px;

    &-title {
      font-family: $serif-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23.14px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
    }

    &-item {
      margin-right: 30px;
      cursor: pointer;

      img {
        height: 240px;
        width: auto;
      }
    }
  }
}
