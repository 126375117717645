@import 'src/styles/theme/config';

.integration-automation-card {
  $card: &;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  margin: 22px;
  border-radius: 30px;
  cursor: pointer;
  padding: 60px 0 45px;
  transition: 0.15s;
  outline: 0px solid transparent;

  &:hover {
    #{$card}-info-title,
    #{$card}-info-subtitle {
      visibility: hidden;
      opacity: 0;
      transform: translateY(-10px);
    }

    #{$card}-info-action {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-bright:not(#{$card}-connected):not(#{$card}-coming-soon) {
    #{$card}-info-title {
      color: #fff;
    }

    #{$card}-info-subtitle {
      color: rgba(#fff, 0.8);
    }
  }

  &-connected {
    background-color: var(--background-color);
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      border: 0px solid transparent;
      border-radius: 40px;
      transition: 0.15s;
      transform: scale(0.93, 0.93);
    }

    &:hover::before {
      border: 10px solid var(--background-color-darker);
      transform: scale(1, 1);
    }

    #{$card}-info-title {
      color: var(--text-color);
    }

    #{$card}-info-subtitle {
      color: var(--text-color-light);
    }
  }

  &-coming-soon {
    background-color: #e5e5e5;
    cursor: default;

    #{$card}-info-logo {
      opacity: 0.6;
    }

    #{$card}-info-title {
      color: #aaa;
    }

    #{$card}-info-subtitle {
      color: rgba(#aaa, 0.8);
    }

    #{$card}-info-action {
      display: none;
    }

    &:hover {
      outline: none !important;

      #{$card}-info-title,
      #{$card}-info-subtitle {
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }
  }

  &-disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      outline: none !important;

      #{$card}-info-title,
      #{$card}-info-subtitle {
        visibility: visible;
        opacity: 1;
        transform: none;
      }

      #{$card}-info-action {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &-status {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    margin-bottom: 10px;
    top: 20px;
    right: 20px;

    &.success {
      color: #fff;
      background-color: var(--success-color);
    }

    &.danger {
      color: #fff;
      background-color: var(--danger-color);
    }

    &.warning {
      color: #fff;
      background-color: var(--warning-color);
    }

    &.progress {
      background-color: #ddd;
    }

    &.disabled {
      background-color: #fff;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 30px;
      background-color: #fff;

      &-img {
        max-width: 60px;
        max-height: 60px;
      }
    }

    &-title {
      transition: 0.1s;
      margin: 20px 0 0;
      font-weight: 700;
      font-size: 17px;
      color: var(--text-color);
    }

    &-subtitle {
      transition: 0.1s;
      font-size: 15px;
      line-height: 1.7;
      color: var(--text-color-light);
    }

    &-action {
      transition: 0.1s;
      position: absolute;
      bottom: 40px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(10px);
    }
  }
}

.theme-dark {
  .integration-automation-card {
    &-connected {
      background-color: var(--background-color-darker);

      &:hover::before {
        border: 10px solid var(--background-color-darkest);
      }
    }
  }
}
