@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

@mixin column-border($color) {
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    border-right: 1px solid $color;
    border-left: 1px solid $color;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: -1px;
  }
}

@mixin column-header-border($color) {
  position: relative;

  &:after {
    display: none;
  }

  .data-table-header-tab {
    border-color: $color;

    &:after {
      background-color: var(--background-color);
    }
  }
}

.data-table {
  $dt: &;

  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--text-color);

  &-scroll {
    flex: 1;
    overflow: auto;
    display: flex;
    min-height: 400px;
    height: 100%;
  }

  &-container {
    position: relative;
    flex: 1;
    margin: 8px 0 -1px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-timeline {
    &-header {
      position: relative;
      font-weight: 700;

      &-subtitle {
        font-size: 11px;
        color: var(--text-color-light);
      }

      &-with-description {
        cursor: pointer;
      }
    }

    &-row-description {
      width: 300px;
      padding: 10px 15px;
      background-color: var(--background-color);
      border: 1px solid var(--grayscale-400);
      box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      &-title {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    &-row-sum {
      border-top: 3px double;
    }
  }

  &-header {
    font-weight: 700;
    display: flex;

    background: var(--background-color);
    position: sticky;
    top: $topbar-height;
    z-index: 10;
    overflow: auto;

    #{$dt}-column {
      $dtc: &;
      position: relative;
      align-items: flex-end;
      padding: 8px 0px 0px;
      min-height: 0;

      &-hovered {
        #{$dt}-header-tab {
          background: var(--highlight-color-light);
          border-color: transparent;
        }
      }

      &-sorted,
      &-filtered,
      &-active {
        #{$dt}-header-tab {
          background: var(--highlight-color-light);
          border-color: var(--text-color-lighter);
        }
      }

      &-highlighted {
        #{$dt}-header-tab {
          background: var(--highlight-color-light);
          border-color: var(--primary-color-500);
        }
      }

      &-sorted,
      &-filtered,
      &-highlighted,
      &-hovered,
      &-active {
        background: var(--background-color);

        &#{$dt}-column-fixed-gradient {
          background: transparent !important;

          #{$dt}-header-tab {
            background: linear-gradient(
              -90deg,
              transparent,
              var(--highlight-color-light) 5%
            );
          }
        }
      }
    }

    #{$dt}-column-active {
      &#{$dt}-column-fixed {
        z-index: 10;
      }
    }

    #{$dt}-column-sorted {
      #{$dt}-header-tab-indicator-sort {
        display: flex;
      }
    }

    #{$dt}-column-filtered {
      #{$dt}-header-tab-indicator-filter {
        display: flex;
      }
    }

    &-tab {
      position: relative;
      padding: 15px 30px;
      border-top: 4px solid transparent;
      z-index: 1;
      cursor: pointer;

      &:after {
        content: ' ';
        background-color: transparent;
        height: 1px;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
      }

      &-expandable {
        padding: 0;

        .data-table-value-info {
          padding: 15px 0px;
        }
      }

      &-indicators {
        position: absolute;
        right: -8px;
        top: -6px;
        display: flex;
        z-index: 2;
      }

      &-indicator {
        display: none;
        align-items: center;
        justify-content: center;
        margin-left: 4px;
        background-color: var(--background-color-inverted);
        color: var(--text-color-inverted);
        border-radius: 10px;
        width: 18px;
        height: 18px;
        cursor: pointer;

        &-sort-reset,
        &-filter-reset {
          display: none;
        }

        &:hover {
          svg {
            display: none;
          }

          .data-table-header-tab-indicator-sort-reset,
          .data-table-header-tab-indicator-filter-reset {
            display: block;
          }
        }
      }

      &-disabled {
        cursor: auto;

        &:hover {
          border-color: transparent;

          &:after {
            background-color: transparent;
          }
        }
      }

      .data-table-value {
        word-break: break-word;
      }
    }

    &-rounded {
      border-radius: 20px 20px 0 0;
    }
  }

  // hide header scroll bar
  .data-table-header::-webkit-scrollbar {
    display: none;
  }
  .data-table-header {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-row {
    display: flex;

    &:nth-child(odd) {
      background-color: var(--highlight-gray);

      #{$dt}-column {
        background-color: var(--highlight-gray);

        &-fixed {
          background: linear-gradient(
            -90deg,
            rgba(252, 252, 252, 0),
            var(--highlight-gray) 0%
          );
        }

        &-fixed-gradient {
          background: linear-gradient(
            -90deg,
            rgba(252, 252, 252, 0),
            var(--highlight-gray) 5%
          );
        }

        &-hovered,
        &-sorted,
        &-filtered,
        &-highlighted,
        &-active {
          background-color: var(--highlight-color);

          &#{$dt}-column-fixed-gradient {
            background: linear-gradient(
              -90deg,
              transparent,
              var(--highlight-color) 5%
            );
          }
        }
      }
    }

    &-highlighted {
      background-color: var(--highlight-color);

      #{$dt}-column {
        font-weight: 700;

        &-index-0 {
          &:after {
            content: ' ';
            position: absolute;
            width: 4px;
            height: 100%;
            background-color: var(--primary-color-500);
            left: 0;
            top: 0;
          }
        }

        &-fixed {
          background: linear-gradient(
            -90deg,
            transparent,
            var(--highlight-color) 0%
          );
        }

        &-fixed-gradient {
          background: linear-gradient(
            -90deg,
            transparent,
            var(--highlight-color) 5%
          );
        }
      }
    }

    &-highlighted-bold {
      font-weight: 700;
    }

    &-empty {
      flex: 1;

      #{$dt}-column {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        min-height: 0;
      }
    }
  }

  &-column {
    position: relative;
    min-width: 250px;
    min-height: 65px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grayscale-400);
    flex: 1;
    cursor: default;

    &-xs {
      min-width: 160px;
    }

    &-s {
      min-width: 180px;
    }

    &-m {
      min-width: 220px;
    }

    &-l {
      min-width: 300px;
    }

    &-xl {
      min-width: 400px;
    }

    &-image-size {
      min-width: 75px;
      max-width: 120px;
      padding-right: 15px;
    }

    &-status-size {
      min-width: 80px;
      max-width: 80px;
      padding-right: 15px;
    }

    &-position-size {
      min-width: 100px;
      max-width: 100px;
      padding-right: 0px;
      padding-left: 15px;
      display: flex;
      justify-content: flex-end;
    }

    &-actions-size {
      min-width: 120px;
      max-width: 120px;
    }

    &-collapse-size {
      min-width: 75px;
      max-width: 75px;
    }

    &-growth {
      font-size: 10px;
    }

    &-pop-growth {
      font-size: 10px;
      display: flex;
      align-items: center;
    }

    &-fixed {
      position: sticky !important;
      left: 0;
      z-index: 3;
      background: linear-gradient(
        -90deg,
        transparent,
        var(--background-color) 0%
      );

      &-gradient {
        background: linear-gradient(
          -90deg,
          transparent,
          var(--background-color) 5%
        );
      }

      #{$dt}-value-breakdown {
        left: 10px !important;
        right: auto !important;
      }
    }

    &-right {
      text-align: right;
      justify-content: flex-end;

      #{$dt}-value-wrapper {
        align-items: flex-end;
      }

      #{$dt}-value {
        text-align: right;
        justify-content: flex-end;
      }
    }

    &-hovered,
    &-sorted,
    &-filtered,
    &-highlighted,
    &-active {
      background-color: var(--highlight-color-light);

      &#{$dt}-column-fixed-gradient {
        background: linear-gradient(
          -90deg,
          transparent,
          var(--highlight-color-light) 5%
        );
      }
    }

    &-expandable {
      min-width: 225px !important;

      &-expand {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 30px;
      }

      &-row-cell {
        padding: 0;
      }

      &-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        padding: 15px 30px;
        cursor: pointer;
      }

      &-collapse {
        cursor: pointer;
      }

      &-transition {
        &-enter {
          opacity: 0;
        }

        &-enter * {
          opacity: 0;
        }

        &-enter-active {
          opacity: 1;
          transition: opacity 0.5s ease-in;
        }

        &-enter-active * {
          opacity: 1;
          transition: opacity 0.5s ease-in;
        }

        &-exit {
          opacity: 1;
        }

        &-exit * {
          opacity: 1;
        }

        &-exit-active {
          display: none;
        }

        &-exit-active * {
          display: none;
        }
      }
    }

    &-highlighted {
      font-weight: 700;
    }
  }
}

.page-app-container-with-banner {
  .data-table-header {
    top: $topbar-with-banner-height;
  }
}

.scrollbar-synced {
  z-index: 100;
  width: 100%;
  overflow-x: scroll;
  position: sticky;
  bottom: 70px;
  background-color: var(--background-color);

  // We need an element inside
  &-spacer {
    // We need to have a non-zero height to see the scrollbar
    min-height: 0.1px;
  }
}

.theme-dark {
  .data-table {
    $dt: &;

    &-column {
      border-color: var(--grayscale-550);
    }

    &-timeline {
      &-row-description {
        background-color: var(--background-color-darker);
        border: 1px solid var(--grayscale-550);
      }
    }

    &-header {
      .data-table-column {
        &-highlighted {
          .data-table-header-tab {
            border-color: var(--secondary-color-500);
          }
        }
      }
    }

    &-row {
      &:nth-child(odd) {
        background-color: var(--background-color-dark);

        .data-table-column {
          background-color: var(--background-color-dark);

          &-fixed {
            background: linear-gradient(
              -90deg,
              transparent,
              var(--background-color-dark) 0%
            );
          }

          &-fixed-gradient {
            background: linear-gradient(
              -90deg,
              transparent,
              var(--background-color-dark) 5%
            );
          }

          &-hovered,
          &-sorted,
          &-filtered,
          &-highlighted,
          &-active {
            background-color: var(--background-color-darkest);

            &.data-table-column-fixed-gradient {
              background: linear-gradient(
                -90deg,
                transparent,
                var(--background-color-darkest) 5%
              );
            }
          }
        }
      }

      &-highlighted {
        .data-table-column-index-0 {
          &:after {
            background-color: var(--secondary-color-500);
          }
        }
      }
    }
  }
}
