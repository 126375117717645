@import 'src/styles/theme/config';

.ranking-item-progression {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8px;

  &-up {
    color: var(--success-color);
  }

  &-down {
    color: var(--danger-color);
  }

  &-amount {
    font-size: 10px;
    font-weight: 700;
  }

  &-triangle-up {
    margin-bottom: -2px;
  }

  &-triangle-down {
    margin-top: -2px;
  }

  &-bullet {
    margin: 5px 0;
  }
}
