@import 'src/styles/theme/config';

.switcher {
  $s: &;
  padding: 2px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  margin: 0;
  background-color: var(--background-color-dark);
  border: 1px solid var(--border-color);
  width: 52px;
  transition: 0.2s;

  &.xs {
    width: 40px;
  }

  &:hover {
    &.active:not(.loading) {
      #{$s}-inner {
        transform: translate(19px, 0);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

        &.xs {
          transform: translate(16px, 0);
        }
      }
    }

    &:not(.active):not(.loading) {
      #{$s}-inner {
        transform: translate(3px, 0);
      }
    }
  }

  &.active {
    background-color: var(--success-color);

    #{$s}-inner {
      transform: translate(22px, 0);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
      border-color: transparent;

      &.xs {
        transform: translate(18px, 0);
      }

      &-icon {
        &.active {
          opacity: 1;
        }
      }
    }
  }

  &.loading {
    cursor: default;

    &.active {
      background-color: var(--warning-color);
    }

    #{$s}-inner {
      &-icon {
        &.active {
          opacity: 0;
        }

        &.processing {
          opacity: 0;
        }

        &.loading {
          opacity: 1;
        }
      }
    }
  }

  &.processing {
    cursor: default;

    #{$s}-inner {
      &-icon {
        &.active {
          opacity: 0;
        }

        &.loading {
          opacity: 0;
        }

        &.processing {
          opacity: 1;
        }
      }
    }
  }

  input {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -9999px;
  }

  &-inner {
    width: 24px;
    height: 24px;
    position: relative;
    background-color: #fff;
    box-shadow: none;
    border-radius: 15px;
    transition: 0.15s;
    transform: translate(0, 0);
    border: 1px solid var(--border-color);

    &.xs {
      width: 16px;
      height: 16px;
    }

    &-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;

      &.active {
        opacity: 0;
      }

      &.loading {
        opacity: 0;
      }

      &.processing {
        opacity: 0;
      }
    }
  }
}
