@import 'src/styles/theme/config';

.cogs-settings {
  display: flex;
  margin-top: 30px;

  &-right {
    flex: 60%;
    padding-right: 45px;
    max-width: 500px;

    &.disabled {
      pointer-events: none;
    }
  }

  &-left {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 40%;
    max-width: 400px;
    padding-left: 45px;
    border-left: 1px solid var(--border-color);
  }

  &-availability {
    display: flex;
    width: 300px;
    height: 10px;
  }

  &-option {
    padding: 20px 30px 20px 20px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    margin-bottom: 15px;
    background-color: var(--background-color);
    opacity: 0.6;
    cursor: pointer;
    display: flex;

    &.active {
      opacity: 1;
    }

    &-content {
      width: 100%;
    }

    &-radio {
      svg {
        margin: 1px 10px 0 0;
      }
    }

    &-header {
      display: flex;
      margin-bottom: 20px;
    }

    &-title {
      font-weight: 700;
    }

    &-subtitle {
      font-size: 11px;
      color: var(--text-color-lighter);
      margin-top: 5px;
    }
  }

  &-addon {
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    margin-bottom: 15px;
    background-color: var(--background-color);

    &-title {
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      a {
        display: flex;
        margin-left: 8px;
      }
    }

    &-subtitle {
      font-size: 11px;
      color: var(--text-color-lighter);
      margin-top: 5px;
    }

    &-info {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    &-status {
      background-color: var(--success-color);
      width: 8px;
      height: 8px;
      border-radius: 5px;
      margin-right: 10px;

      &.warning {
        background-color: var(--warning-color);
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  &-extra {
    position: absolute;
    left: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
  }

  &-update {
    display: flex;
    align-items: center;
    margin-top: 30px;

    &-info {
      margin-left: 20px;
      font-weight: 500;
      line-height: 18.2px;
      color: var(--text-color-lighter);

      strong {
        color: var(--text-color);
      }
    }
  }
}

.theme-dark {
  .cogs-settings {
    &-option {
      background-color: var(--background-color-darker);
    }

    &-addon {
      background-color: var(--background-color-darker);
    }

    &-extra {
      background-color: var(--background-color-darker);
    }
  }
}
