/*------------------------------------------------------------------
  [Buttons]
*/
.btn {
  box-shadow: none;
  border: 1px solid transparent;
  transition: 0.1s;

  @include button-size(
    3px,
    15px,
    13px,
    $btn-line-height-base,
    $btn-border-radius-base
  );

  &:active,
  &.active {
    box-shadow: none;
  }

  &:active:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.btn-default {
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border-color: $btn-default-border;

  &:focus,
  &.focus {
    color: $btn-default-color;
    background-color: $btn-default-bg;
    border-color: $btn-default-border;
    // box-shadow: $btn-box-shadow-base;
  }

  &:hover,
  &.hover {
    box-shadow: 0 0 0 3px rgba($btn-default-border, 0.03);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    // box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
    color: darken($btn-default-color, 10%);
    background-color: darken($btn-default-bg, 11%);
    border-color: darken($btn-default-border, 13%);

    &:hover,
    &:focus,
    &.focus {
      color: darken($btn-default-color, 10%);
      background-color: darken($btn-default-bg, 11%);
      border-color: darken($btn-default-border, 13%);
      // box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $btn-default-bg;
      border-color: $btn-default-border;
    }
  }

  .badge {
    color: $btn-default-bg;
    background-color: $btn-default-color;
  }
}

//Alternative color styles

.btn-default-fill {
  @include button-variant(
    $btn-default-fill-color,
    $btn-default-fill-bg,
    $btn-default-fill-border
  );
}

.btn-primary {
  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border
  );
}

.btn-primary-fill {
  @include button-variant(
    $btn-primary-fill-color,
    $btn-primary-fill-bg,
    $btn-primary-fill-border
  );
}

.btn-secondary {
  @include button-variant(
    $btn-secondary-color,
    $btn-secondary-bg,
    $btn-secondary-border
  );
}

.btn-secondary-fill {
  @include button-variant(
    $btn-secondary-fill-color,
    $btn-secondary-fill-bg,
    $btn-secondary-fill-border
  );
}

.btn-partners-primary {
  @include button-variant(
    $btn-partners-primary-color,
    $btn-partners-primary-bg,
    $btn-partners-primary-border
  );
}

.btn-partners-primary-fill {
  @include button-variant(
    $btn-partners-primary-fill-color,
    $btn-partners-primary-fill-bg,
    $btn-partners-primary-fill-border
  );
}

.btn-danger {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-bg,
    $btn-danger-border
  );
}

.btn-danger-fill {
  @include button-variant(
    $btn-danger-fill-color,
    $btn-danger-fill-bg,
    $btn-danger-fill-border
  );
}

.btn-warning {
  @include button-variant(
    $btn-warning-color,
    $btn-warning-bg,
    $btn-warning-border
  );
}

.btn-success {
  @include button-variant(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border
  );
}

.btn-success-fill {
  @include button-variant(
    $btn-success-fill-color,
    $btn-success-fill-bg,
    $btn-success-fill-border
  );
}

.btn-transparent {
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

//Button sizes
.btn-xs {
  @include button-size(3px, 10px, $btn-xs-font-size, $btn-xs-line-height, 8px);
}

.btn-sm {
  @include button-size(
    $btn-padding-vertical,
    $btn-sm-padding-horizontal,
    $btn-sm-font-size,
    $btn-sm-line-height,
    $btn-sm-border-radius
  );
}

.btn-lg {
  @include button-size(
    $btn-padding-vertical,
    10px,
    $btn-lg-font-size,
    $btn-lg-line-height,
    10px
  );
  font-weight: 700;
}

.btn-xl {
  @include button-size(
    $btn-padding-vertical,
    20px,
    $btn-xl-font-size,
    48px,
    10px
  );
  text-transform: uppercase;
  letter-spacing: 0.8px;

  svg {
    width: 16px;
    height: 16px;
  }
}

//Button with rounded borders
.btn-rounded {
  border-radius: 40px;
}

//Buton spacer helpers
.btn-hspace {
  margin-right: 5px;
}

.btn-vspace {
  margin-bottom: 5px;
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

//Social buttons colors
.btn-facebook {
  .icon {
    color: $facebook;
  }
  @include button-icon-active-color($facebook);
}
.btn-twitter {
  .icon {
    color: $twitter;
  }
  @include button-icon-active-color($twitter);
}
.btn-google-plus {
  .icon {
    color: $google-plus;
  }
  @include button-icon-active-color($google-plus);
}
.btn-dribbble {
  .icon {
    color: $dribbble;
  }
  @include button-icon-active-color($dribbble);
}
.btn-flickr {
  .icon {
    color: $flickr;
  }
  @include button-icon-active-color($flickr);
}
.btn-linkedin {
  .icon {
    color: $linkedin;
  }
  @include button-icon-active-color($linkedin);
}
.btn-youtube {
  .icon {
    color: $youtube;
  }
  @include button-icon-active-color($youtube);
}
.btn-pinterest {
  .icon {
    color: $pinterest;
  }
  @include button-icon-active-color($pinterest);
}
.btn-github {
  .icon {
    color: $github;
  }
  @include button-icon-active-color($github);
}
.btn-tumblr {
  .icon {
    color: $tumblr;
  }
  @include button-icon-active-color($tumblr);
}
.btn-dropbox {
  .icon {
    color: $dropbox;
  }
  @include button-icon-active-color($dropbox);
}
.btn-evernote {
  .icon {
    color: $evernote;
  }
  @include button-icon-active-color($evernote);
}

//Social buttons
.btn-social {
  min-width: 31px;
  padding: 0 8px;

  .icon {
    @include font-size-large(2px);
  }
}

.btn-color.btn-facebook {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $facebook, $facebook);
}
.btn-color.btn-twitter {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $twitter, $twitter);
}
.btn-color.btn-google-plus {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $google-plus, $google-plus);
}
.btn-color.btn-dribbble {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $dribbble, $dribbble);
}
.btn-color.btn-flickr {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $flickr, $flickr);
}
.btn-color.btn-linkedin {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $linkedin, $linkedin);
}
.btn-color.btn-youtube {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $youtube, $youtube);
}
.btn-color.btn-pinterest {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $pinterest, $pinterest);
}
.btn-color.btn-github {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $github, $github);
}
.btn-color.btn-tumblr {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $tumblr, $tumblr);
}
.btn-color.btn-dropbox {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $dropbox, $dropbox);
}
.btn-color.btn-evernote {
  .icon {
    color: $light-color;
  }
  @include button-variant($light-color, $evernote, $evernote);
}

//Icons inside buttons
.btn {
  .icon {
    @include font-size(3px);
    line-height: 15px;
    vertical-align: middle;

    &.icon-left {
      margin-right: $btn-icon-margin;
    }

    &.icon-right {
      margin-left: $btn-icon-margin;
    }

    &.fa {
      @include font-size(2px);
    }
  }

  &.btn-lg .icon {
    @include font-size-large(6px);
    line-height: 20px;
  }

  &.btn-sm .icon {
    @include font-size(2px);
    line-height: 15px;
  }

  &.btn-xs .icon {
    @include font-size(1px);
    line-height: 14px;
  }
}

//Butons dropdown
.btn.dropdown-toggle {
  .btn-group &.btn-primary,
  .btn-group &.btn-success,
  .btn-group &.btn-warning,
  .btn-group &.btn-danger {
    border-right-color: transparent;

    &:hover {
      border-right-color: transparent;
    }
  }

  .mdi-chevron-down,
  .mdi-chevron-up {
    @include font-size-large(3px);
    vertical-align: middle;
    line-height: 17px;
  }

  .icon-dropdown {
    @include font-size-large(3px);
    line-height: 17px;
    margin-left: 6px;

    &-left {
      margin-left: 0;
      margin-right: 6px;
    }
  }
}
