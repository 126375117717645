@import 'src/styles/theme/config';

.collection-settings-modal {
  &-form-label {
    font-weight: 700;
    color: var(--text-color-light);
    margin-bottom: 10px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}
