@import 'src/styles/theme/config';

.report-settings {
  &-section {
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    margin-bottom: 30px;
    background-color: var(--background-color);

    &-title {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 30px;
      line-height: 1;
    }
  }

  &-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .form-group {
      margin-bottom: 0;
    }

    &-fields {
      width: 70%;
    }
  }

  &-toast-text {
    font-weight: 400;
  }
}

.theme-dark {
  .report-settings {
    &-section {
      background-color: var(--background-color-darker);
    }
  }
}
