@import 'src/styles/theme/config';

.status-indicator {
  $status: &;
  display: flex;
  align-items: center;
  justify-content: center;

  &-pill {
    width: 12px;
    height: 12px;
    border-radius: 12px;

    &.small {
      width: 8px;
      height: 8px;
    }

    @keyframes pulse-success {
      0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 var(--success-color-200);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 7px transparent;
      }

      100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 transparent;
      }
    }

    @keyframes pulse-warning {
      0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 var(--warning-color-200);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 7px transparent;
      }

      100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 transparent;
      }
    }
  }

  &.success {
    #{$status}-pill {
      background-color: var(--success-color-500);
    }
  }

  &.success-pulsating {
    #{$status}-pill {
      background-color: var(--success-color-500);
      animation: pulse-success 2s infinite;
    }
  }

  &.warning {
    #{$status}-pill {
      background-color: var(--warning-color-500);
    }
  }

  &.warning-pulsating {
    #{$status}-pill {
      background-color: var(--warning-color-500);
      animation: pulse-warning 2s infinite;
    }
  }

  &.inactive {
    #{$status}-pill {
      background-color: var(--grayscale-500);
    }
  }
}
