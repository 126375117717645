@import 'src/styles/theme/config';

.select {
  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    & > svg {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 1px;
    }
  }

  &-tooltip {
    position: relative;
    z-index: 100000;
    font-size: 11px;
    color: var(--text-color-inverted);
    background-color: var(--background-color-inverted);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 7px 15px;
    border-radius: 20px;
  }
}

.styled-select {
  $select: &;

  &__control {
    min-height: 40px !important;
    border-radius: 10px !important;
    background-color: var(--background-color) !important;
    border: 1px solid transparent !important;
    font-size: 13px !important;

    &--is-focused {
      box-shadow: none !important;
    }
  }

  &__value-container {
    padding: 0 !important;
    min-height: 38px;
  }

  &__subvalue {
    font-size: 11px;
    margin-left: 2px;
    color: var(--text-color-light);
    font-weight: 700;
    margin-top: -5px;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__clear-indicator {
    padding: 0 6px 0 !important;
  }

  &__input-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: inherit !important;
  }

  &__multi-value {
    border-radius: 8px !important;
    background-color: var(--border-color) !important;
    color: var(--text-color) !important;
  }

  &__multi-value__label {
    color: var(--text-color) !important;
  }

  &__multi-value__remove {
    border-radius: 8px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    cursor: pointer;
  }

  &__single-value {
    color: inherit !important;
  }

  &__menu {
    border-radius: 10px !important;
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    box-shadow: 0px 2px 4px var(--border-color) !important;
    min-width: 150px;
    overflow: hidden;
  }

  &__group-heading {
    font-size: 11px !important;
    padding: 10px 15px 10px !important;
    color: var(--text-color-light) !important;
    letter-spacing: 0.5px !important;
    font-weight: 700 !important;
    border-bottom: 1px solid var(--border-color) !important;
  }

  &__option {
    cursor: pointer !important;
    color: var(--text-color-light) !important;
    padding: 10px 15px !important;
    word-break: break-word;

    &--is-focused {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--text-color-light) !important;
    }

    &--is-selected {
      background-color: var(--background-color-dark) !important;
      color: var(--text-color) !important;
      font-weight: 700;
    }

    &:hover {
      background-color: var(--background-color-dark) !important;
      color: var(--text-color) !important;
    }

    &--is-disabled {
      color: var(--text-color-lightest) !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &:hover {
        color: var(--text-color-lightest) !important;
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
  }

  .fast-option {
    overflow: hidden;
    white-space: nowrap;
    color: var(--text-color) !important;
    border: 1px solid transparent;
    line-height: 38px !important;

    &:hover {
      background-color: var(--background-color-dark) !important;
      color: var(--text-color) !important;
    }
  }

  .fast-option-focused {
    background-color: transparent !important;
    border-color: transparent !important;
    color: var(--text-color) !important;
  }

  .fast-option-selected {
    background-color: var(--background-color-dark) !important;
    color: var(--text-color) !important;
    font-weight: 700;
  }

  &--is-disabled {
    color: var(--text-color-lighter);

    #{$select}__dropdown-indicator {
      display: none !important;
    }
  }

  // Sizes

  &.size-none {
    #{$select}__control {
      min-height: auto !important;
      margin: 0 !important;
    }

    #{$select}__value-container {
      min-height: auto !important;
    }

    #{$select}__input-container {
      margin: 0 !important;
    }
  }

  &.size-xs {
    #{$select}__control {
      min-height: 30px !important;
    }

    #{$select}__value-container {
      min-height: 30px !important;
    }
  }

  &.size-sm {
    #{$select}__control {
      min-height: 34px !important;
    }

    #{$select}__value-container {
      min-height: 34px !important;
    }
  }

  &.size-lg {
    #{$select}__control {
      min-height: 50px !important;
      padding: 4px 20px !important;
    }
  }

  &.width-xs {
    #{$select}__control {
      width: 50px;
    }
  }

  &.width-sm {
    #{$select}__control {
      width: 90px;
    }
  }

  &.width-md {
    #{$select}__control {
      width: 140px;
    }
  }

  &.width-lg {
    #{$select}__control {
      width: 180px;
    }
  }

  &.width-full {
    width: 100%;

    #{$select}__control {
      width: 100%;
    }
  }

  // Variants
  &.with-border {
    #{$select}__control {
      border: 1px solid var(--border-color) !important;
      padding-left: 15px !important;
      padding-right: 10px !important;

      &--is-focused {
        box-shadow: 0 0 0 1px var(--border-color-inverted) !important;
      }
    }

    ~ svg {
      left: 15px;
    }
  }

  &.with-background {
    #{$select}__control {
      background-color: var(--background-color-dark) !important;
    }
  }

  &.with-icon {
    #{$select}__control {
      padding-left: 28px !important;
    }

    &.with-background,
    &.with-border {
      #{$select}__control {
        padding-left: 35px !important;
      }
    }
  }

  &.round {
    #{$select}__control {
      border-radius: 40px !important;
    }
  }

  &.transparent {
    #{$select}__control {
      background-color: transparent !important;
    }
  }

  &.heading {
    #{$select}__value-container {
      font-size: 17px !important;
      font-weight: 700 !important;
    }
  }
}

.theme-dark {
  .styled-select {
    &__control {
      background-color: var(--background-color-darkest) !important;
    }

    &__menu {
      background-color: var(--background-color-darker) !important;
      box-shadow: none !important;
    }

    &__option {
      &--is-selected {
        background-color: var(--background-color-darkest) !important;
      }

      &:hover {
        background-color: var(--background-color-darkest) !important;
      }

      &--is-disabled {
        &:hover {
          background-color: transparent !important;
        }
      }
    }

    .fast-option {
      &:hover {
        background-color: var(--background-color-darkest) !important;
      }
    }

    .fast-option-selected {
      background-color: var(--background-color-darkest) !important;
    }

    &.with-background {
      .styled-select__control {
        background-color: var(--background-color-darkest) !important;
      }
    }
  }
}
