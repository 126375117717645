@import 'src/styles/theme/config';

.goal {
  --flag-fill: var(--text-color);
  --flag-stroke: var(--background-color);
  padding: 25px;
  background-color: var(--background-color);
  border-radius: 20px;
  border: 1px solid var(--border-color);
  margin-bottom: 30px;
  display: flex;

  &-info {
    width: 33.3333%;
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
  }

  &-subtitle {
    color: var(--text-color-light);
    font-weight: 700;
    margin-top: 5px;
  }

  &-current-value {
    font-size: 24px;
    color: var(--text-color-light);
    margin: 5px 0 10px;

    strong {
      color: var(--text-color);
    }

    strong.text-inverted {
      color: var(--text-color-inverted);
    }
  }

  &-conclusion {
    margin-right: 50px;
    color: var(--text-color-light);

    strong {
      color: var(--text-color);
    }
  }

  &-progress {
    display: flex;
    align-items: center;
    width: 66.6666%;
    padding-right: 20px;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-button {
      padding: 0 15px;
    }
  }
}

.theme-dark {
  .goal {
    --flag-fill: var(--text-color);
    --flag-stroke: var(--background-color-darker);

    background-color: var(--background-color-darker);
  }
}
