@import 'src/styles/theme/config';

.topbar {
  $topbar: &;
  display: flex;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-color);
  height: $topbar-height;
  margin: 0;
  position: fixed;
  top: 0;
  left: $sidebar-width;
  right: 0;
  z-index: 1010;

  &-items {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 30px;
  }

  &.with-subtitle {
    #{$topbar}-title {
      font-size: 20px;
    }
  }

  &-title {
    font-size: 26px;
    font-family: $serif-font;
    font-weight: 700;
    max-width: calc(100vw - 680px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &-with-icon {
      display: flex;
      align-items: center;

      &-wrapper {
        margin-left: 15px;
      }
    }
  }

  &-subtitle {
    font-size: 15px;
    color: var(--text-color-light);
  }

  &-divider {
    width: 1px;
    height: calc(100% - 30px);
    background-color: var(--border-color);
    margin: 15px;
  }

  &-trial {
    padding: 0 15px;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: scale(0);
      transition: transform 0.1s;
      background-color: var(--background-color-darker);
      border-radius: 20px;
      z-index: -1;
    }

    &.active,
    &:hover {
      &:after {
        transform: scale(1);
      }

      #{$topbar}-item-notification-icon {
        color: #fff;
        max-width: 50px;
        max-height: 20px;
        padding: 0 7px;
        top: -17px;
        right: -1px;
      }
    }

    &-notification-icon {
      position: absolute;
      border-radius: 20px;
      background-color: #ff0000;
      font-size: 11px;
      padding: 0;
      top: -5px;
      right: 10px;
      line-height: 20px;
      z-index: 1;
      color: #ff0000;
      transition: 0.15s;
      overflow: hidden;
      max-width: 5px;
      max-height: 5px;
    }
  }

  &-dropdown {
    $dropdown: &;
    min-width: 220px;
    max-height: 500px;
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    border-radius: 15px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    overflow: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    z-index: 110;

    &-notifications {
      min-width: 400px;
    }

    &-item {
      padding: 15px 15px 15px 20px;
      display: block;
      transition: 0.1s;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--text-color-light);

      &:hover {
        background-color: var(--background-color-dark);
        color: var(--text-color);
      }

      svg {
        margin-right: 15px;
      }
    }

    .topbar-settings-item-theme-switcher {
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      padding-left: 20px;
      color: var(--text-color-light);

      &:hover {
        color: var(--text-color);
      }
    }
  }

  .active {
    #{$topbar}-item {
      &:after {
        transform: scale(1);
      }

      &-notification-icon {
        color: #fff;
        max-width: 50px;
        max-height: 20px;
        padding: 0 7px;
        top: -17px;
        right: -1px;
      }
    }
  }
}

.page-app-container-with-banner {
  .topbar {
    display: flex;
    flex-direction: column;
    height: $topbar-with-banner-height;
  }
}

.theme-dark {
  .topbar {
    &-dropdown {
      background-color: var(--background-color-darker);

      &-item {
        &:hover {
          background-color: var(--background-color-darkest);
        }
      }
    }
  }
}
