@import 'src/styles/theme/config';

.product-position-modal {
  &-product-preview {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px 20px;
    border-radius: 15px;
    background-color: var(--background-color-dark);
    border: 1px solid var(--border-color);

    &-image {
      width: 60px;
      height: 80px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }

    &-info {
      display: flex;
      flex-direction: column;

      &-title {
        margin-bottom: 5px;
      }

      &-subtitle {
        font-size: 11px;
        font-weight: 700;
        color: var(--text-color-light);
      }
    }
  }

  &-submit-message {
    margin-right: 30px;
    color: var(--text-color-light);

    strong {
      color: var(--text-color);
    }
  }
}
