@import 'src/styles/theme/config';

.best-channels-side-stats {
  display: flex;
  flex-direction: column;
  margin-top: 35px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 17px;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    height: 50px;

    &-icon {
      display: flex;
      color: var(--text-color-lighter);

      img {
        width: 16px;
      }

      &-other {
        width: 16px;
        height: 16px;
        border-radius: 10px;
        background-color: var(--text-color-lighter);
      }
    }

    &-title {
      font-weight: 700;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-value {
      font-weight: 700;
    }

    &-subvalue {
      font-size: 11px;
      font-weight: 700;
      color: var(--text-color-lighter);
    }

    &-left {
      display: flex;
      align-items: center;
      max-width: 60%;
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
