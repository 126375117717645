@import 'src/styles/theme/config';

.gender-ltv {
  &-series-badge {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2px 5px;
    position: absolute;
    top: 178px;
    left: 80px;
    transform: translate(-50%, 0);
    font-size: 11px;
    font-weight: 700;
    color: var(--success-color);
  }
}
