@import 'src/styles/theme/config';

.styled-title {
  font-family: $serif-font;
  margin: 0;
  line-height: 1.1;

  &-bold {
    font-weight: 700;
  }

  &-xs {
    font-size: 17px;
  }

  &-sm {
    font-size: 20px;
  }

  &-md {
    font-size: 25px;
  }

  &-lg {
    font-size: 30px;
    letter-spacing: 0.5px;
  }

  &-xlg {
    font-size: 40px;
    letter-spacing: -1px;
  }

  &-xxlg {
    font-size: 80px;
    letter-spacing: -2px;
  }
}
