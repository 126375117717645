@import 'src/styles/theme/config';

.color-field {
  position: relative;

  &-control {
    cursor: pointer;
    display: flex;
    padding: 10px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px var(--border-color);
    transition: 0.2s;

    background: linear-gradient(
      -75deg,
      var(--grayscale-600) 0%,
      var(--grayscale-600) 50%,
      var(--grayscale-300) 50%,
      var(--grayscale-300) 100%
    );
  }

  &-tile {
    width: 80px;
    height: 30px;
    border-radius: 5px;
  }

  &-picker {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    z-index: 100;
  }
}
