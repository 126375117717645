@import 'src/styles/theme/config';

.swal2-container {
  z-index: 99990;

  .custom-swal-popup {
    border-radius: 20px;
    padding: 45px 30px 60px;
    font-size: 20px;

    .swal2 {
      &-warning {
        color: var(--danger-color);
        border-color: var(--danger-color);
      }

      &-header {
        padding: 0;
      }

      &-title {
        font-size: 20px;
        color: var(--text-color);
      }

      &-html-container,
      &-content {
        font-size: 13px;
        line-height: 1.4;
        margin-top: 20px;
        padding: 0;
        color: var(--text-color-light);
      }

      &-actions {
        margin-top: 30px;
      }
    }
  }
}

.theme-dark {
  .swal2-container {
    .custom-swal-popup {
      background-color: var(--background-color-darker);
    }
  }
}
