@import 'src/styles/theme/config';

.table-list {
  $tl: &;

  &-full {
    #{$tl}-search {
      border-radius: 0;
    }

    #{$tl}-content {
      box-shadow: none;
      border-radius: 0;
    }
  }

  &-control-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &-search {
      max-width: 25%;
    }

    &-filters {
      display: flex;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 3px 30px 0;
    border-radius: 20px 20px 0 0;

    &-input {
      padding: 20px 35px 20px 15px;
      border: none;
      outline: none;
      flex: 1;
      background-color: transparent;
    }
  }

  &-content {
    background-color: var(--background-color);
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
  }

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 25px;

    &:first-child {
      border-bottom: 1px solid var(--border-color);
    }
  }

  &-item {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    color: var(--text-color);

    &:hover {
      background-color: var(--background-color-dark);
    }

    &-title {
      font-weight: 700;
    }

    &-subtitle {
      font-size: 11px;
      color: var(--text-color-light);
    }

    &-column {
      padding: 10px 15px;
    }
  }
}

.theme-dark {
  .table-list {
    &-content {
      background-color: var(--background-color-darker);
    }
  }
}
