@import 'src/styles/theme/config';

.internal-create-partner-form {
  &-logo {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    padding: 30px;

    &-preview {
      width: 100px;
      height: 100px;
      border-radius: 60px;
      margin-right: 20px;
      border: 1px solid var(--border-color);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
