@import 'src/styles/theme/config';

.statistic-item {
  $statistic: &;
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 20px;
  border: 1px solid var(--border-color);
  padding: 25px;
  position: relative;
  cursor: default;

  &-icon {
    margin-right: 15px;
    display: flex;
  }

  &-title {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 5px;

    &-info-icon {
      width: 8px;
      height: 8px;
      position: absolute;
    }
  }

  &-description {
    width: 300px;
    padding: 10px 15px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &-title {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  &-value {
    font-size: 20px;
    font-weight: 700;
  }

  &-compare {
    color: var(--text-color-lighter);
  }
}

.theme-dark {
  .statistic-item {
    background-color: var(--background-color-darker);

    &-description {
      background-color: var(--background-color-darker);
    }
  }
}
