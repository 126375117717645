.zendesk-export-form {
  &-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-label {
      font-weight: 700;
      margin-left: 10px;
    }

    .checkbox {
      margin: 0;
    }
  }
}
