@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.notifications {
  &-header {
    padding: 25px 30px;
    position: absolute;
    background: linear-gradient(var(--background-color) 75%, transparent);
    line-height: 1;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 14px 14px 0 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-size: 20px;
      font-family: $serif-font;
      font-weight: 700;
    }
  }

  &-new-notifications-button {
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 6px 10px;
    box-shadow: 0 4px 5px -5px rgba(0, 0, 0, 0.1);
    font-size: 11px;
    line-height: 1;
    cursor: pointer;
    transition: 0.15s;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;

    &.visible {
      display: flex;
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      box-shadow: none;
      transform: translate(0, 1px);
    }

    svg {
      width: 10px;
      margin-left: 8px;
    }
  }

  &-list {
    list-style: none;
    padding: 80px 25px 15px;
    height: 450px;
    overflow: auto;
    margin: 0;
  }

  &-loader {
    min-height: 100px;
  }

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  &-item {
    background-color: var(--background-color);
    border: 2px solid var(--border-color);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    margin-bottom: 10px;
    position: relative;

    &.notifications-item-unread {
      &.success {
        border-color: var(--success-color);
      }

      &.error {
        border-color: var(--danger-color);
      }
    }
  }
}

.theme-dark {
  .notifications {
    &-header {
      background: linear-gradient(
        var(--background-color-darker) 75%,
        transparent
      );
    }

    &-item {
      background-color: var(--background-color-darkest);

      .notification-content {
        background-color: var(--background-color-darkest);
      }
    }

    &-new-notifications-button {
      background-color: var(--background-color-darkest);
    }
  }
}
