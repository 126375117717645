@import 'src/styles/theme/config';

.cell-breakdown {
  overflow: auto;
  max-height: 315px;
  max-width: 350px;
  min-width: 200px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  cursor: default;

  &-item {
    border-bottom: 1px solid var(--border-color);
    padding: 10px 15px;
    display: flex;
    font-size: 11px;
    display: flex;
    justify-content: space-between;

    &-label {
      margin-right: 20px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.theme-dark {
  .cell-breakdown {
    background-color: var(--background-color-darkest);
  }
}
