@import 'src/styles/theme/config';

.page-importer {
  .import-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      flex: 1;
      min-width: 500px;
    }

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 30px;

      &-title {
        font-size: 24px;
        font-family: $serif-font;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 5px;
        display: flex;

        &-info-icon {
          display: flex;
          margin-left: 5px;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      &-subtitle {
        color: var(--text-color-light);
      }
    }

    .import-options {
      display: flex;

      &-item {
        padding: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-radius: 20px;
        background: var(--background-color);
        box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;
        font-size: 15px;
        transition: border-color 0.15s;
        flex: 1;
        width: 250px;
        min-height: 250px;
        cursor: pointer;

        &:hover {
          border-color: var(--background-color-inverted);
        }

        &-icon {
          margin-bottom: 60px;
        }

        &-title {
          font-weight: 700;
        }

        &-info {
          font-size: 13px;
          color: var(--text-color-light);
          margin-top: 5px;
        }
      }
    }
  }
}

.page-restricted-with-header {
  .import-step {
    .import-options {
      &-item {
        background-color: #f7f7f9;
        box-shadow: none;
      }
    }
  }
}

.custom-field-form {
  h4 {
    font-size: 20px;
  }
}

.theme-dark {
  .page-restricted-with-header {
    .import-step {
      .import-options {
        &-item {
          background-color: var(--background-color-darkest);
          box-shadow: none;
        }
      }
    }
  }
}
