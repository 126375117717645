@import 'src/styles/theme/config';

.cohort-analysis-insight {
  &-slider {
    $slider: &;
    overflow: hidden;
    position: relative;

    &.left {
      #{$slider}-transition {
        width: 100%;

        &-enter {
          transform: translateX(-100%) scale(0.9);
        }

        &-enter-active {
          transform: translateX(0);
          transition: 0.5s;
        }

        &-exit {
          transform: translateX(0);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        &-exit-active {
          transform: translate(100%) scale(0.8);
          transition: 0.5s;
        }
      }
    }

    &.right {
      #{$slider}-transition {
        &-enter {
          transform: translateX(100%) scale(0.9);
        }

        &-enter-active {
          transform: translateX(0);
          transition: 0.5s;
        }

        &-exit {
          transform: translateX(0);
          position: absolute;
          top: 0;
          left: 0;
        }

        &-exit-active {
          transform: translate(-100%) scale(0.8);
          transition: 0.5s;
        }
      }
    }
  }

  &-kpis {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0;
    padding: 0 15px;

    &-row {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 50px;
    }
  }

  &-cohorts {
    display: flex;

    &-title {
      position: relative;
      width: 25px;
      white-space: nowrap;

      &-reference {
        position: absolute;
        transform: rotate(-90deg);
        top: 28%;
        left: -20px;
      }

      &-target {
        position: absolute;
        transform: rotate(-90deg);
        top: 75%;
        left: -20px;
      }
    }

    &-values {
      width: 100%;

      &-row {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-items: center;
        gap: 15px;
        padding: 2px;

        &-pop {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
}
