@import 'src/styles/theme/config';

.audience-settings {
  display: flex;
  align-items: center;

  &-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 90px;
    margin-right: 10px;

    &-item {
      width: 20px;
      height: 20px;
      margin: 2px 5px;
    }

    &-error {
      position: relative;

      svg {
        position: absolute;
        right: 0px;
        top: -2px;
      }
    }
  }
}
