@import 'src/styles/theme/config';

.merchandise-pixel-warning-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--warning-color);
  border-radius: 15px;
  background-color: var(--background-color);
  padding: 20px;
  margin-bottom: 30px;

  &-description {
    display: flex;
    align-items: center;

    &-info {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      &-title {
        font-size: 13px;
        font-weight: 700;
        line-height: 15.6px;
        margin-bottom: 5px;
      }

      &-subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.6px;
        color: var(--text-color-light);
      }
    }
  }
}

.theme-dark {
  .merchandise-pixel-warning-banner {
    background-color: var(--background-color-darker);
  }
}
