@import 'src/styles/theme/config';

.integrations-automations {
  padding: 45px;
}

.integration-automation-discover {
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);

  &-hero {
    padding: 45px;
    margin-bottom: 0;
    box-shadow: none;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }

  &-section {
    padding: 45px;
    border-top: 1px solid var(--border-color);
  }
}

.theme-dark {
  .integration-automation-discover {
    background-color: var(--background-color-darker);
  }
}
