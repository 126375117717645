@import 'src/styles/theme/config';

.Toastify__toast-container {
  width: 400px !important;
  z-index: 99999 !important;
}

.Toastify__toast {
  padding: 20px !important;
  border-radius: 20px !important;
  color: var(--text-color) !important;
  border: 2px solid transparent;
  font-family: 'Lato' !important;
}

.Toastify__toast--success {
  background-color: var(--background-color) !important;
  border: 2px solid var(--success-color-500);
  box-shadow: 0 10px 15px var(--success-color-100);
}

.Toastify__toast--warning {
  background-color: var(--background-color) !important;
  border: 2px solid var(--warning-color-500);
  box-shadow: 0 10px 15px var(----warning-color-100);
}

.Toastify__toast--error {
  background-color: var(--background-color) !important;
  border: 2px solid var(--danger-color-500);
  box-shadow: 0 10px 15px var(--danger-color-100);
}

.theme-dark {
  .Toastify__toast--success {
    background-color: var(--background-color-darker) !important;
    box-shadow: 0 10px 15px var(--success-color-900) !important;
  }
  .Toastify__toast--warning {
    background-color: var(--background-color-darker) !important;
    box-shadow: 0 10px 15px var(--warning-color-900) !important;
  }
  .Toastify__toast--error {
    background-color: var(--background-color-darker) !important;
    box-shadow: 0 10px 15px var(--danger-color-900) !important;
  }
}
