@import 'src/styles/theme/config';
@import '../../mixins.scss';

.segment-rule-group {
  $srg: &;
  position: relative;
  padding: 30px;
  border-radius: 20px;
  background-color: rgba($brand-primary, 0.02);
  border: 1px solid var(--border-color);
  transition: 0.2s;

  &-initial {
    padding: 0;
    background-color: transparent;
    border-color: transparent;
  }

  &-remove {
    @include remove-btn();
  }

  &-actions {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 35px;
    transform: translateY(50%);
  }

  .segment-definition-transition {
    &-enter {
      max-height: 0;
      overflow: hidden;

      .segment-logical-operator {
        opacity: 0;
      }

      .segment-rule-group {
        max-height: 0;
        overflow: hidden;
        padding: 0;
      }

      .segment-rule-wrapper {
        opacity: 0;
        transform: scale(0.8);
      }
    }

    &-enter-active {
      max-height: 1000px;
      overflow: visible;
      transition: 0.5s;

      .segment-logical-operator {
        opacity: 1;
        transition: 0.25s;
        transition-delay: 0.25s;
      }

      .segment-rule-group {
        max-height: 1000px;
        overflow: visible;
        padding: 30px;
        transition: 0.5s;
      }

      .segment-rule-wrapper {
        opacity: 1;
        transform: scale(1);
        transition: 0.5s;
      }
    }

    &-exit {
      max-height: 1000px;
      overflow: visible;

      .segment-logical-operator {
        opacity: 1;
      }

      .segment-rule-group {
        max-height: 1000px;
        overflow: visible;
        padding: 30px;
      }

      .segment-rule-wrapper {
        opacity: 1;
        transform: scale(1);
      }
    }

    &-exit-active {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s;

      .segment-logical-operator {
        opacity: 0;
        transition: 0.5s;
      }

      .segment-rule-group {
        max-height: 0;
        overflow: hidden;
        padding: 0;
        transition: 0.5s;
      }

      .segment-rule-wrapper {
        opacity: 0;
        transform: scale(0.8);
        transition: 0.5s;
      }
    }
  }
}

.theme-dark {
  .segment-rule-group {
    &-remove {
      @include remove-btn(true);
    }
  }
}
