@import 'src/styles/theme/config';

.deviation-progress {
  $ap: &;
  display: flex;
  flex: 1;
  position: relative;

  &.inverted {
    #{$ap}-bar {
      background: linear-gradient(
        90deg,
        var(--success-color) 0%,
        var(--border-color) 50%,
        var(--danger-color) 100%
      );
    }
  }

  &-bar {
    width: 100%;
    height: 12px;
    border-radius: 10px;
    background: linear-gradient(
      90deg,
      var(--danger-color) 0%,
      var(--border-color) 50%,
      var(--success-color) 100%
    );
  }

  &-goal {
    flex: 1;
    position: relative;

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      font-size: 11px;
      color: var(--text-color-light);
      z-index: 1;
    }

    &-value {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -100%;
    }
  }

  &-indicator {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: 2px solid var(--background-color);
    background-color: var(--text-color);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.theme-dark {
  .deviation-progress {
    &-indicator {
      border-color: var(--background-color-darker);
    }
  }
}
