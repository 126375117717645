@import 'src/styles/theme/config';

.addons {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-dark);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin-bottom: -40px;

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 20px;

    &-multi {
      padding: 10px 15px 10px 10px;
      margin-left: 20px;
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom: none;
      }
    }

    &-title {
      font-size: 13px;
      font-weight: 700;

      &-multi {
        font-weight: 400;
        margin-left: 10px;
      }
    }

    &-actions {
      display: flex;

      &-select {
        width: 180px;
      }
    }
  }

  &-options-devider {
    width: auto;
  }
}
