@import 'src/styles/theme/config';

.code-block {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--background-color-darker);
  border: 1px solid var(--border-color);
  margin-top: 15px;

  pre {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: var(--text-color);
  }

  code {
    font-family: Courier;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.6px;
  }
}
