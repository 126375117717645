@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.context-selector-inline {
  display: flex;
  position: relative;

  &-company-container {
    width: 100%;
    margin: -5px 0;

    &-title {
      font-weight: 700;
      font-size: 11px;
      color: var(--text-color-light);
      text-transform: uppercase;
      letter-spacing: 0.4px;
      margin: 15px 10px 10px;
    }
  }

  &-company-wrapper {
    position: relative;
  }

  &-company {
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;

    &:hover,
    &.active {
      background-color: var(--background-color-dark);
      border-color: var(--border-color);
    }

    &.disabled {
      opacity: 0.4;
    }

    &-title-wrapper {
      .title {
        line-height: 1;
      }
    }
  }
}

.theme-dark {
  .context-selector-inline {
    &-company {
      &:hover,
      &.active {
        background-color: var(--background-color-darkest);
      }
    }
  }
}
