@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.page-restricted-dialog {
  &-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include flipCard;

  &-flip-card {
    width: 450px;
    height: 500px;
  }

  &-panel {
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.08);
    color: var(--text-color);
    backface-visibility: hidden;

    display: flex;
    flex-direction: column;
    position: relative;

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto !important;
      padding: 130px 40px 45px;
    }

    &-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 45px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      background: linear-gradient(
        180deg,
        var(--background-color) 80%,
        transparent 100%
      );
    }

    &-logo {
      height: 32px;
    }

    &-title {
      font-family: $serif-font;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 15px;
    }

    &-description {
      margin-bottom: 30px;
      color: var(--text-color-light);
    }
  }
}

.theme-dark {
  .page-restricted-dialog {
    &-panel {
      background: var(--background-color-darker);

      &-header {
        background: linear-gradient(
          180deg,
          var(--background-color-darker) 80%,
          transparent 100%
        );
      }
    }
  }
}
