.items-per-basket-insight {
  &-basket {
    position: relative;
    margin: 0 40px 0 20px;
  }

  &-basket-value {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -3px);
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;

    &-label {
      font-size: 13px;
      font-weight: 700;
    }
  }
}
