@import 'src/styles/theme/config';

.internal-users-list-item {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  color: var(--text-color);
  padding: 0 15px;

  &:hover {
    background-color: var(--background-color-dark);
  }

  &-title {
    font-weight: 700;
  }

  &-subtitle {
    font-size: 11px;
    color: var(--text-color-light);
  }

  &-column {
    display: flex;
    flex: 1 0 calc(100% / 4);
    align-items: center;
    padding: 10px 15px;

    &:first-child {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &-actions {
    display: flex;

    &-item {
      display: flex;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
