/*------------------------------------------------------------------
  [Label]
*/
.label {
  border-radius: 8px;
  padding: 5px 10px;
  font-size: $font-size-small;
  font-weight: 500;
}

.label-default {
  border: 1px solid var(--border-color);
  color: var(--text-color);
}
