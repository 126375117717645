@import 'src/styles/theme/config';

.list-pagination {
  display: flex;
  user-select: none;
  color: var(--text-color);

  &-info {
    height: 32px;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  &-control {
    width: 36px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.1s;

    &:hover {
      background-color: var(--border-color);
    }
  }
}
