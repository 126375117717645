@import 'src/styles/theme/config';

.data-table {
  &-image {
    width: 30px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.3;
    }
  }

  &-value-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;

    &:hover {
      & + .data-table-value-breakdown {
        opacity: 1;
        visibility: visible;
      }

      &.with-breakdown {
        .data-table-label {
          box-shadow: 0 0 0 1px var(--background-color-inverted);
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &-value {
    display: flex;
    align-items: center;
    word-break: break-all;
    position: relative;

    &-status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 16px;
      height: 16px;
      border-radius: 10px;
      margin-left: 8px;

      &-removed {
        background-color: var(--danger-color);
      }

      &-paused {
        background-color: $gray-light;
      }

      &-enabled {
        background-color: var(--success-color);
      }
    }

    &-info-icon {
      width: 8px;
      height: 8px;
      position: relative;
      top: -5px;
      right: -3px;
    }

    &-placeholder-img {
      width: auto !important;
      height: 25px !important;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  &-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 11px;
    background-color: var(--background-color-darker);
    text-decoration: none !important;

    &.success {
      background-color: var(--success-color-100);
      color: var(--success-color-700);
    }

    &.danger {
      background-color: var(--danger-color-100);
      color: var(--danger-color-600);
    }

    &-text {
      margin-left: 6px;
    }

    svg,
    img {
      width: 12px;
      height: 12px;
    }
  }

  &-value-breakdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    text-align: right;
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 10px 15px;
    top: -18px;
    right: 15px;
    z-index: 100;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
    white-space: nowrap;
    cursor: default;
    transition: 0.05s;

    &:hover {
      opacity: 1;
      visibility: visible;
    }

    &.with-raw {
      top: -32px;
    }

    &.first-row {
      top: 24px;
    }

    &.with-raw.first-row {
      top: 38px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        color: var(--text-color-light);
        font-weight: 700;
        font-size: 10px;
        margin-right: 15px;
        margin-top: 3px;
      }

      &-values {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &-raw {
        font-size: 10px;
      }
    }
  }

  &-list-breakdown-wrapper {
    padding: 10px 0;
  }

  &-list-breakdown {
    overflow: auto;
    max-height: 315px;
    max-width: 250px;
    min-width: 200px;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: default;

    &-item {
      border-bottom: 1px solid var(--border-color);
      padding: 10px 15px;
      display: flex;
      font-size: 11px;

      &-index {
        margin-right: 10px;
        color: var(--text-color-light);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.theme-dark {
  .data-table {
    &-list-breakdown {
      background-color: var(--background-color-darker);
    }

    &-value-breakdown {
      background-color: var(--background-color-darker);
    }
  }
}
