@import 'src/styles/theme/config';

.insight-graphic-slider-indicator {
  display: flex;

  &-item {
    height: 5px;
    margin: 0 5px;
    background-color: var(--border-color);
    border-radius: 5px;
    flex: 3;
    transition: 0.5s;

    &.active {
      background-color: var(--text-color-light);
      flex: 4;
    }
  }
}
