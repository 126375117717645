@import 'src/styles/theme/config';

.product-ltv {
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 380px;

  &-main {
    display: flex;

    &-image {
      position: relative;
      width: calc(40% - 5px);
      padding-top: calc(40% - 5px);

      &:hover {
        color: rgba($brand-primary, 0.1);
      }

      &-inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($brand-primary, 0.1);
        font-size: 60px;
        font-weight: 700;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-details {
      flex: 1;
      padding: 15px 0 15px 30px;

      &-name {
        font-size: 20px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &-item {
        margin-top: 15px;

        &-value {
          font-size: 20px;
          font-weight: 600;
        }

        &-title {
          color: var(--text-color-light);
        }
      }
    }
  }

  &-product-list {
    display: flex;
    margin: 30px -5px 0;

    &-item {
      $item: &;
      flex: 0 0 20%;
      padding: 0 5px;

      &.active {
        #{$item}-image {
          transform: scale(1.1) translate(0, -5px);
          box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);
          border-color: var(--background-color-inverted);
        }
      }

      &-image {
        display: block;
        position: relative;
        padding-top: calc(100% - 4px);
        border-radius: 15px;
        border: 2px solid transparent;
        overflow: hidden;
        transition: 0.2s;

        &-inner {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba($brand-primary, 0.1);
          font-size: 40px;
          font-weight: 700;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
