@import 'src/styles/theme/config';

.audience-settings-item {
  display: flex;
  min-width: 220px;

  &-indicator {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    background-color: #ddd;

    &.active {
      background-color: var(--success-color);
    }
  }
}
