@import 'src/styles/theme/config';

.segment-suggestions {
  $s: &;
  position: relative;

  &-title {
    font-family: $serif-font;
    font-weight: 700;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-arrow {
    position: absolute;
    top: calc(50% - 15px);
    transform: translate(0, -50%);
    z-index: 100;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    transition: 0.1s;

    &:hover {
      transform: translate(0, -47%);
      box-shadow: none;
    }

    &-prev {
      left: 5px;
    }

    &-next {
      right: 5px;
    }
  }

  &-slider {
    margin: 0 -15px;
    position: relative;

    &.center-mode {
      margin: 0 -30px;

      .slick-track {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .slick-track {
      display: flex !important;
      margin-left: 0;
      margin-right: 0;
    }

    .slick-slide {
      height: inherit !important;
      display: flex !important;

      & > div {
        display: flex;
        flex: 1;
      }
    }
  }
}
