@import 'src/styles/theme/config';

.email-report-list-item {
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);

  &:hover {
    background-color: rgba($brand-primary, 0.02);
  }

  &-cell {
    display: flex;
    align-items: center;
    padding: 15px 10px;
  }

  &-title {
    font-weight: 600;
  }
}
