@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.kpi-item {
  padding: 30px;
  background-color: #f7f7f9;
  border-radius: 20px;
  position: relative;
  transition: 0.2s;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include flipCard;

  &-flip-card {
    width: 100%;
    height: 100%;
  }

  &-flip-card-front:hover {
    .kpi-item-hover-overlay,
    .kpi-item-edit-button,
    .kpi-item-remove-button {
      opacity: 1;
      visibility: visible;
    }
  }

  &-title {
    font-family: $serif-font;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &-growth {
    font-size: 12px;
    margin-left: 5px;
  }

  &-value-title {
    font-weight: 700;
  }

  &-value {
    font-size: 20px;
  }

  &-hover-overlay {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
  }

  &-edit-button,
  &-remove-button {
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    position: absolute;
    left: -5px;
    top: -5px;
    background-color: var(--background-color);
    border-radius: 15px;
    border: 1px solid var(--background-color-inverted);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translate(0, -1px);
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.08);

    &:hover {
      box-shadow: none;
      transform: translate(0, 0);
    }
  }

  &-edit-button {
    left: 30px;
  }
}

.theme-dark {
  .kpi-item {
    background-color: var(--background-color-darker);

    &-edit-button,
    &-remove-button {
      background-color: var(--background-color-darker);
    }
  }
}
