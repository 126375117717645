@import 'src/styles/theme/config';

.page-insights {
  .insight-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &-item {
      display: flex;
      flex: 0 0 calc(50% - 60px);
      margin: 30px;

      &:nth-child(odd) {
        justify-content: flex-end;
      }

      &:nth-child(even) {
        justify-content: flex-start;
      }
    }

    &-no-results {
      text-align: center;
      margin: 100px 0;

      &-title {
        font-size: 20px;
        margin-bottom: 10px;
        font-family: $serif-font;
      }

      &-description {
        color: var(--text-color-light);
      }
    }
  }
}

@media (max-width: 1920px) {
  .page-insights {
    .insight-list {
      &-item {
        flex: 0 0 calc(100% - 60px);
        justify-content: center !important;
      }
    }
  }
}
