@import 'src/styles/theme/config';

.type-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    min-width: 500px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &-title {
      font-size: 24px;
      font-family: $serif-font;
      font-weight: 700;
      line-height: 1.2;
    }
  }

  .selector-options {
    display: flex;

    &-item {
      padding: 30px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 20px;
      background: var(--background-color);
      box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      font-size: 15px;
      transition: var(--border-color) 0.15s;
      flex: 1;
      width: 260px;
      min-height: 250px;
      cursor: pointer;

      &:hover {
        border-color: var(--background-color-inverted);
      }

      &-icon {
        margin-bottom: 60px;

        img {
          width: 60px;
        }
      }

      &-info {
        font-size: 13px;
        color: var(--text-color-light);
        margin-top: 5px;
      }
    }
  }
}

.theme-dark {
  .type-selector {
    .selector-options {
      &-item {
        background: var(--background-color-darker);
      }
    }
  }
}
