@import 'src/styles/theme/config';
@import 'src/styles/custom/mixins';

.restricted-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;

  .logo {
    width: 180px;
  }

  .restricted-company-selector {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px 4px 6px;
    cursor: pointer;
    min-width: 200px;
    margin-right: 15px;

    &-title {
      line-height: 1;
      color: var(--text-color);
    }

    &-subtitle {
      line-height: 1 !important;
    }
  }

  .restricted-company-selector-dropdown {
    @include dropdown(bottom, right);
    min-width: 350px;
    right: 5px;
  }
}

.theme-dark {
  .restricted-navbar {
    .restricted-company-selector {
      background-color: var(--background-color-darker);
    }

    .restricted-company-selector-dropdown {
      @include dropdown(bottom, right, true);
      right: 5px;
    }
  }
}
