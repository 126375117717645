@import 'src/styles/theme/config';

.access-management-list-item {
  $st: &;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);

  &-row {
    transition: 0.15s;
    display: flex;
    background-color: var(--background-color);

    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      #{$st}-column {
        border-bottom: none;
      }
    }
  }

  &-column {
    padding: 15px 30px;
    min-width: 150px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    flex: 1;
  }
}
