@import 'src/styles/theme/config';

.onboarding {
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    width: 1300px;
    min-height: 100vh;
    display: flex;
    padding-top: 100px;
    align-items: flex-start;
    justify-content: center;
  }

  &-panel {
    width: 100%;
    background-color: #fff;
    max-width: 450px;
    padding: 45px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.05);
    color: var(--text-color);

    &-header {
      margin-top: 10px;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: 24px;
      font-family: $serif-font;
      margin-bottom: 15px;
      font-weight: 700;
    }

    &-description {
      font-size: 15px;
      color: var(--text-color-light);
    }

    &-actions {
      text-align: center;
      padding: 20px 0;
    }

    .logo {
      width: 200px;
    }
  }

  &-progress-step {
    color: #ccc;

    &.active,
    &.pending {
      color: var(--text-color);
    }
  }

  $circle-length: 151px;
  $check-length: 36px;

  @keyframes scaleAnimation {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes drawCircle {
    0% {
      stroke-dashoffset: $circle-length;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawCheck {
    0% {
      stroke-dashoffset: $check-length;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #successAnimationCircle {
    stroke-dasharray: $circle-length $circle-length;
    stroke: var(--success-color);
  }

  #successAnimationCheck {
    stroke-dasharray: $check-length $check-length;
    stroke: var(--success-color);
  }

  #successAnimationResult {
    fill: var(--success-color);
    opacity: 0;
  }

  #successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;

    #successAnimationCircle {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
        0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationCheck {
      animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
        0.3s linear 0.9s 1 both fadeOut;
    }

    #successAnimationResult {
      animation: 0.3s linear 0.9s both fadeIn;
    }
  }
}
