@import 'src/styles/theme/config';

.notification-content {
  display: flex;
  background-color: var(--background-color);

  &-company {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &-name {
      margin: 0 10px;
      line-height: 1.2;
    }
  }

  &-message {
    font-weight: 700;
    font-size: 15px;
    padding-right: 30px;
    color: var(--text-color);

    a:hover {
      text-decoration: underline;
      color: var(--text-color);
    }
  }

  &-action {
    margin: 10px 0 0;
  }

  &-time {
    font-size: 11px;
    color: var(--text-color-light);
  }

  &-close-wrapper {
    width: 28px;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--background-color-dark);

    .halfclip {
      width: 50%;
      height: 100%;
      right: 0px;
      position: absolute;
      overflow: hidden;
      transform-origin: left center;
      animation: cliprotate 7s 1;
      transform: rotate(0);
    }

    .halfcircle {
      box-sizing: border-box;
      height: 100%;
      right: 0px;
      position: absolute;
      border: solid 2px transparent;
      border-top-color: var(--text-color);
      border-left-color: var(--text-color);
      border-radius: 50%;

      &.clipped {
        width: 200%;
        transform: rotate(-45deg);
        animation: mainrotate 3.5s linear 2;
      }

      &.fixed {
        width: 100%;
        transform: rotate(135deg);
        opacity: 0;
        animation: showfixed 7s 1;
      }
    }

    @keyframes cliprotate {
      0% {
        transform: rotate(180deg);
      }
      50% {
        transform: rotate(180deg);
      }
      50.0001% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    @keyframes mainrotate {
      0% {
        transform: rotate(135deg);
      }
      100% {
        transform: rotate(-45deg);
      }
    }

    @keyframes showfixed {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 1;
      }
      50.0001% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.theme-dark {
  .notification-content {
    background-color: var(--background-color-darker);

    &-close-wrapper {
      background-color: var(--background-color-darkest);
    }
  }
}
