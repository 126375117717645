@import 'src/styles/theme/config';

.merchandise-setup {
  &-item {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 30px;
    }

    &-step {
      font-weight: 700;
      letter-spacing: 0.05em;
      color: var(--text-color-lighter);
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      font-family: $serif-font;
      margin-bottom: 20px;
    }

    &-description {
      color: var(--text-color-light);
      line-height: 20px;
    }

    &-status {
      display: flex;
      align-items: center;
      margin-top: 20px;

      span {
        color: var(--text-color-light);
        margin-left: 10px;
        font-weight: 700;
        letter-spacing: 0.05em;
      }
    }

    &-action {
      display: flex;
      align-items: center;
      margin-top: 40px;

      &-select {
        display: flex;
        align-items: center;
        position: relative;

        & > svg {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 3px;
        }

        & > img {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 14px;
        }
      }

      &-items-start {
        align-items: start;
      }

      &-form {
        display: flex;
        flex: 1;

        .form-group {
          margin: 0;
          flex: 1;
        }
      }
    }
  }
}
