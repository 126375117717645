@import 'src/styles/theme/config';

.badge {
  padding: 5px 10px;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.7px;
  text-decoration: none !important;
  display: inline-block;
  min-width: 10px;
  white-space: nowrap;
  text-align: center;
  background-color: var(--grayscale-100);
  border-radius: 20px;
  border: 1px solid transparent;
  max-width: 100%;

  &-xs {
    font-size: 9px;
    padding: 3px 7px;
    letter-spacing: 0.4px;
  }

  &-lg {
    font-size: 13px;
    padding: 7px 13px;
  }

  &-white {
    color: var(--text-color);
    border-color: var(--border-color);
  }

  &-default {
    background-color: var(--background-color-dark);
    color: var(--grayscale-600);
    border-color: var(--border-color);
  }

  &-primary {
    background-color: var(--primary-color-500);
    color: var(--grayscale-100);
  }

  &-secondary {
    background-color: var(--secondary-color-500);
    color: var(--grayscale-100);
  }

  &-success {
    background-color: var(--success-color);
    color: var(--grayscale-100);
  }

  &-warning {
    background-color: var(--warning-color);
    color: var(--grayscale-100);
  }

  &-danger {
    background-color: var(--danger-color);
    color: var(--grayscale-100);
  }

  &-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  img {
    max-width: 14px;
  }
}

.theme-dark {
  .badge {
    &-default {
      background-color: var(--background-color-dark);
      color: var(--grayscale-100);
    }

    &-white {
      background-color: var(--background-color-darker);
    }

    &-primary {
      background-color: var(--grayscale-100);
      color: var(--text-color-inverted);
    }
  }
}
