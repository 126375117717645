@import 'src/styles/theme/config';

.restricted-tooltip {
  display: flex;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.2);
  padding: 20px 25px;
  color: var(--text-color);
  border-radius: 20px;
  white-space: normal;
  width: 400px;
  z-index: 10000;
  transition: 0.1s;

  &-title {
    font-family: $serif-font;
    font-size: 20px;
    font-weight: 700;
  }
}

.theme-dark {
  .restricted-tooltip {
    background-color: var(--background-color-darker);
  }
}
