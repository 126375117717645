@import 'src/styles/theme/config';

.PhoneInput {
  display: flex;

  &Input {
    display: flex;
    border: none;
    outline: none;
  }
}

.code-field {
  &-container {
    display: flex;
    margin: 0 -5px;
    max-width: 380px;
  }

  &-input {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    text-align: center;
    font-size: 35px;
    margin: 0 5px;
    width: 100%;
    flex: 1;
  }
}

.pill-input {
  &-info-icon {
    display: flex;
    margin-left: 5px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.column-selector {
  flex: 0 0 50%;

  &-item {
    background: var(--background-color);
    padding: 15px 20px;
    display: flex;
    border-radius: 20px;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid var(--border-color);
    color: var(--text-color);

    &-icon {
      width: 28px;
      height: 28px;
      background-color: var(--background-color-dark);
      border-radius: 28px;
      text-align: center;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--border-color);
      color: transparent;

      &-success {
        background-color: var(--success-color-500);
        color: var(--grayscale-100);
      }
    }

    &-title {
      font-size: 15px;
    }

    &-subtitle {
      font-size: 11px;
      font-weight: 700;
      margin-top: 3px;
      color: var(--text-color-light);
    }

    &-match-icon {
      position: absolute;
      right: -15px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 20px;
    }
  }
}

.custom-field-action {
  &-edit {
    color: var(--text-color);
    margin: 0 5px 0 10px;
  }

  &-delete {
    color: var(--danger-color);
  }
}

.page-restricted-with-header-container {
  .column-selector {
    &-item {
      background: var(--background-color-dark);

      &-icon {
        background-color: var(--background-color-darker);

        &-success {
          background-color: var(--success-color-500);
        }
      }
    }
  }
}

.theme-dark {
  .code-field {
    &-input {
      background-color: var(--background-color-darkest);
    }
  }

  .column-selector {
    &-item {
      background: var(--background-color-darker);

      &-icon {
        background-color: var(--background-color-darkest);

        &-success {
          background-color: var(--success-color-500);
        }
      }
    }
  }

  .page-restricted-with-header-container {
    .column-selector {
      &-item {
        background: var(--background-color-darkest);

        &-icon {
          background-color: var(--background-color-darkest);

          &-success {
            background-color: var(--success-color-500);
          }
        }
      }
    }
  }
}
