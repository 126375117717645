.widget-product-tooltip {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
