@import 'src/styles/theme/config';

.segment-list-item {
  $item: &;

  transition: 0.15s;
  display: flex;
  background-color: var(--background-color);

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    #{$item}-column {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: var(--background-color-darker);
  }

  &-column {
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;

    &:first-child {
      padding: 20px 15px 20px 30px;
    }

    &:last-child {
      padding: 20px 30px 20px 15px;
    }

    &-single-row {
      border-bottom: none;
    }

    &-audience {
      padding-right: 48px;
    }

    &-customers-collaborator {
      margin-left: 48px;
    }
  }

  &-title {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color-light);
    font-size: 9px;
    letter-spacing: 0.5px;
    margin-top: 5px;
  }

  &-value {
    font-weight: 600;
    line-height: 1.5;
  }

  &-chart {
    canvas {
      cursor: pointer;
    }
  }

  &-meta {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  &-user {
    display: flex;
    align-items: center;
    font-size: 11px;
    color: var(--text-color-light);
  }

  &-type {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &-integration-btn {
    $b: &;
    height: 28px;
    width: 28px;
    border-radius: 20px;
    display: flex;
    position: relative;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      #{$b}-content {
        opacity: 1;
        visibility: visible;
        transform: translate(0, -50%) scale(1);
      }
    }

    svg {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    &-content {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translate(0, -50%) scale(0.9);
      background-color: #f2f2f4;
      border: 1px solid var(--border-color);
      padding: 8px 35px 8px 10px;
      opacity: 0;
      visibility: hidden;
      transition: 0.1s;
      border-radius: 10px;
    }

    &-text {
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 11px;
    }
  }
}

.theme-dark {
  .segment-list-item {
    background-color: var(--background-color-darker);

    &:hover {
      background-color: var(--background-color-darkest);
    }
  }
}
