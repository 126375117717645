@import 'src/styles/theme/config';

.tool-card-wrapper {
  flex: 1 1;
  margin-bottom: 30px;
  padding: 0 15px;

  .tool-card {
    background-color: var(--background-color);
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    height: 100%;
    transition: 0.15s;
    box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.08);

    &-title {
      font-size: 20px;
      font-family: $serif-font;
      font-weight: 700;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-subtitle {
      position: relative;
      margin-top: 5px;
      color: var(--text-color-light);
      line-height: 1.7;
    }

    &-icon {
      position: absolute;
      bottom: -5px;
      right: -10px;
      color: var(--background-color-dark);

      img {
        max-width: 100%;
      }
    }

    &-action {
      display: flex;
      align-items: center;
      margin-right: 5px;
      padding: 5px 10px;
      border-radius: 10px;
      transition: background-color 0.1s;
      cursor: pointer;

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }
}

.theme-dark {
  .tool-card {
    background-color: var(--background-color-darker);

    &-icon {
      color: var(--background-color-darkest);
    }
  }
}
