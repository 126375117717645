@import 'src/styles/theme/config';

.cell-progress-bar {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  &-progress {
    display: flex;
    align-items: center;
    max-width: 75px;
    width: 100%;
    height: 10px;
    background-color: var(--border-color);
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px;

    &-content {
      min-width: 6px;
      height: 100%;
      border-radius: 5px;
      background-color: var(--text-color-light);
    }
  }

  &-value {
    width: 100%;
  }

  &-breakdown {
    overflow: auto;
    max-height: 315px;
    max-width: 250px;
    min-width: 200px;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: default;

    &-item {
      border-bottom: 1px solid var(--border-color);
      padding: 10px 15px;
      display: flex;
      font-size: 11px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.theme-dark {
  .cell-progress-bar {
    &-progress {
      &-breakdown {
        background-color: var(--background-color-darker);
      }
    }
  }
}
