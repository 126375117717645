@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.2s both linear;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.fade-in-top {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInTop;
  animation-timing-function: cubic-bezier(0.03, 1.07, 0.82, 1.01);
}

.fade-in-bottom {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-name: fadeInBottom;
  animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 4s infinite linear;
}

.rotate-reverse {
  animation: rotate 4s infinite linear reverse;
}

@keyframes levitate {
  0% {
    transform: translate(0, 0);
  }
  40%,
  50% {
    transform: translate(0, -4%);
    box-shadow: 0 40px 30px -35px rgba(0, 0, 0, 0.08);
  }
  100% {
    transform: translate(0, 0);
  }
}

.tada {
  animation: tada 4s infinite linear;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }

  2%,
  4% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -5deg);
  }

  6%,
  10%,
  14%,
  18% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 5deg);
  }

  8%,
  12%,
  16% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -5deg);
  }

  20%,
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jump {
  0% {
    transform: translate(0, 0) scale(1.05, 0.95);
  }
  50% {
    transform: translate(0, -50%) scale(1, 1);
  }
  100% {
    transform: translate(0, 0) scale(1.05, 0.95);
  }
}

@keyframes value-change {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
