@import 'src/styles/theme/config';

.company-logo {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  background-color: var(--background-color);
  overflow: hidden;
  padding: 3px;
  width: 40px;
  height: 40px;
  line-height: 32px;
  font-size: 15px;

  &-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--grayscale-100);
  }

  &-letters {
    width: 100%;
    height: 100%;
    color: var(--text-color);
    text-align: center;
    font-weight: 700;
  }

  &.size-xs {
    width: 30px;
    height: 30px;
    line-height: 22px;
    font-size: 11px;
  }

  &.size-xlg {
    padding: 5px;
    border-width: 3px;
    width: 100px;
    height: 100px;
    line-height: 84px;
    font-size: 35px;
  }
}

.theme-dark {
  .company-logo {
    background-color: var(--background-color-darker);
  }
}
