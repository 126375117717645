/*------------------------------------------------------------------
[Fonts - Lato]
*/

@mixin lato-font($type, $weight, $style) {
  @font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/Lato-#{$type}.eot');
    src: local('Lato #{$type}'), local('Lato-#{$type}'),
      url('#{$lato-font-path}/Lato-#{$type}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$lato-font-path}/Lato-#{$type}.woff2') format('woff2'),
      url('#{$lato-font-path}/Lato-#{$type}.woff') format('woff'),
      url('#{$lato-font-path}/Lato-#{$type}.ttf') format('truetype'),
      url('#{$lato-font-path}/Lato-#{$type}.svg#Lato') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin lato-font-pair($type, $weight) {
  @include lato-font('#{$type}', $weight, normal);
  // @include lato-font('#{$type}Italic', $weight, italic);
}

@include lato-font-pair('Regular', 400);
@include lato-font-pair('Regular', 500);
@include lato-font-pair('Bold', 700);
