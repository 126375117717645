@import 'src/styles/theme/config';

.wizard-progress {
  padding: 45px;

  &-item {
    $item: &;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 45px;

    &:after {
      content: '';
      position: absolute;
      top: 30px;
      left: 8px;
      height: 24px;
      width: 1px;
      background-color: var(--border-color);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &-inactive {
      color: var(--text-color-lighter);

      #{$item}-status {
        color: var(--border-color);
      }
    }

    &-active {
      color: var(--text-color);

      #{$item}-status {
        color: var(--text-color);
      }
    }

    &-ready {
      color: var(--text-color);

      #{$item}-status {
        color: var(--success-color);
      }
    }

    &-status {
      display: flex;
      margin-right: 10px;
    }
  }
}
