@import 'src/styles/theme/config';

.time-frame-picker {
  position: relative;

  &-input {
    display: flex;
    align-items: center;

    &-start,
    &-end {
      flex: 1;
      position: relative;

      &.active {
        .time-frame-picker-placeholder {
          background-color: var(--background-color);
          border: 1px solid var(--border-color);
        }
      }
    }
  }

  &-placeholder {
    border: 1px solid transparent;
    padding: 10px 12px;
    flex: 1;
    cursor: pointer;
    border-radius: 10px;
    white-space: nowrap;
  }

  &-dropdown {
    position: absolute;
    top: calc(100% + 15px);
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 15px;
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    min-width: 230px;
    display: flex;

    &:before,
    &:after {
      content: '\0020';
      display: block;
      position: absolute;
      top: -7px;
      z-index: 2;
      width: 0;
      height: 0;
      overflow: hidden;
      border: solid 7px transparent;
      border-top: 0;
      border-bottom-color: var(--background-color);
    }

    &:before {
      top: -8px;
      z-index: 1;
      border-bottom-color: var(--border-color);
    }

    &.left-edge {
      left: 0;

      &:before,
      &:after {
        left: 15px;
      }
    }

    &.right-edge {
      right: 0;

      &:before,
      &:after {
        right: 15px;
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      margin: 10px;

      &-arrow {
        width: 36px;
        line-height: 30px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-title {
        flex: 1;
        font-weight: 700;
        text-align: center;
        padding: 0 10px;
        line-height: 35px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
      }

      &-back {
        display: flex;
        align-items: center;
        line-height: 36px;
        padding: 0 6px;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &-arrow,
      &-back,
      &-title {
        border: 1px solid transparent;

        &:hover {
          background-color: var(--background-color-dark);
          border: 1px solid var(--border-color);
        }
      }
    }

    &-selector {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      border-radius: 8px;
      max-height: 175px;
      max-width: 230px;
      overflow: hidden;

      &-inner {
        transform: translate(-230px, 0);
        width: 690px;

        &.slide-left-start {
          transform: translate(0, 0);
        }

        &.slide-left-end {
          transition: transform 0.25s;
          transform: translate(0, 0);
        }

        &.slide-right-end {
          transition: transform 0.25s;
          transform: translate(-460px, 0);
        }

        & > * {
          display: inline-block;
          min-width: 230px;
          padding: 5px;
          padding-top: 0;
        }
      }
    }

    &.week-view {
      min-width: 350px;

      .time-frame-picker-dropdown-selector {
        max-width: 350px;

        &-inner {
          width: 1050px;
          transform: translate(-350px, 0);

          &.slide-left-end {
            transform: translate(0, 0);
          }

          &.slide-right-end {
            transform: translate(-700px, 0);
          }

          & > * {
            min-width: 350px;
          }
        }
      }
    }

    &.year-view {
      .time-frame-picker-dropdown-header {
        &-title {
          &:hover {
            background-color: transparent;
            cursor: default;
          }
        }
      }
    }
  }

  &-pill-row {
    display: flex;
  }

  &-pill {
    margin: 5px;
    padding: 5px 10px;
    min-width: 50px;
    text-align: center;
    flex: 1 0 auto;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    color: var(--text-color-light);

    &.active {
      color: var(--grayscale-100);
    }

    .tag {
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      background-color: var(--secondary-color-500);
      color: var(--grayscale-100);
      border-radius: 8px;
      height: 30px;
      line-height: 30px;
      display: inline-block;

      &:after {
        content: ' ';
        height: 30px;
        position: absolute;
        background-color: var(--secondary-color-100);
        z-index: -2;
        top: 0;
        bottom: 0;
      }

      &-start {
        &:before {
          right: -6px;
        }

        &:after {
          right: -15px;
          left: 50%;
        }
      }

      &-end {
        &:before {
          left: -6px;
        }

        &:after {
          left: -15px;
          right: 50%;
        }
      }

      &-other {
        color: var(--secondary-color-500);
        background-color: var(--secondary-color-100);

        &:before {
          background-color: var(--grayscale-100);
        }
      }

      &.tag-same {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    &.interval {
      color: var(--secondary-color-500);

      &:before {
        content: ' ';
        height: 30px;
        position: absolute;
        background-color: var(--secondary-color-100);
        z-index: -1;
        top: 0;
        right: -10px;
        bottom: 0;
        left: -10px;
        z-index: -2;
      }
    }

    &.disabled {
      color: var(--text-color-lightest);
    }
  }
}

.theme-dark {
  .time-frame-picker {
    &-input {
      &-start,
      &-end {
        &.active {
          .time-frame-picker-placeholder {
            background-color: var(--background-color-darkest);
          }
        }
      }
    }

    &-dropdown {
      background-color: var(--background-color-darker);

      &:after {
        border-bottom-color: var(--background-color-darker);
      }

      &-header {
        &-arrow,
        &-back,
        &-title {
          &:hover {
            background-color: var(--background-color-darkest);
          }
        }
      }
    }

    &-pill {
      .tag {
        &:after {
          background-color: var(--background-color);
        }

        &-other {
          background-color: var(--background-color);
        }
      }

      &.interval {
        &:before {
          background-color: var(--background-color);
        }
      }
    }
  }
}
